import React, { FunctionComponent, ReactElement, useState } from 'react';
import cx from 'classnames';
import InfiniteScroll from 'react-infinite-scroller';
import styles from './table.module.css';

type Field = any[];
type Header = string | ReactElement;

type Props = {
  headers: Header[];
  data?: Field[];
  className?: string;
  clear?: boolean;
  clearWithHeader?: boolean;
  name?: string;
  infinite?: {
    pageStart: number;
    hasMore: boolean;
    loadMore: (page: number) => void;
    initialLoad: boolean;
  };
  onClickRow?: (id?: number) => void;
  reset?: (scroller: InfiniteScroll | null) => void;
  disableClickActive?: boolean;
  activeRowClassName?: string;
  rowClassName?: string;
};

const Table: FunctionComponent<Props> = ({
  headers,
  clearWithHeader,
  name,
  className,
  clear,
  infinite,
  onClickRow,
  data,
  reset,
  disableClickActive,
  activeRowClassName,
  rowClassName
}) => {
  const [selectedRow, onChangeSelectedRow] = useState<number>();

  const onSelectRow = (id: number) => {
    if (onClickRow) {
      const newSelectedRow = selectedRow === id ? undefined : id;
      if (!disableClickActive) onChangeSelectedRow(newSelectedRow);
      onClickRow(newSelectedRow);
    }
  };

  const list = data?.map((field: Field, idx: number) => (
    <tr
      key={idx}
      onClick={() => onSelectRow(idx)}
      className={cx(rowClassName, { [activeRowClassName || styles.active]: idx === selectedRow })}
      cypress-id={name ? `${name}-row-${idx}` : null}
    >
      {field.map((value: any, index: number) => (
        <td key={index}>{value}</td>
      ))}
    </tr>
  ));

  return (
    <table
      className={cx(styles.wrapper, className, { [styles.clear]: clear, [styles.clearWithHeader]: clearWithHeader })}
    >
      <thead>
        <tr>
          {headers.map((label: Header, idx: number) => (
            <td key={idx}>{label}</td>
          ))}
        </tr>
      </thead>
      {infinite ? (
        <InfiniteScroll element="tbody" {...infinite} ref={(scroller) => (reset ? reset(scroller) : null)}>
          {list}
        </InfiniteScroll>
      ) : (
        <tbody>{list}</tbody>
      )}
    </table>
  );
};

export default Table;
