import { createAction } from '../../utils/actions';
import { coursesStoreAdapter } from './adapters';
import { ActionType } from '../../utils/type';
import { Store } from '../types';
import { CoursesStoreEnum } from './types';
import { createSelector } from 'reselect';

export const mainSelector = (store: Store) => store.courses;
export const coursesSelector = <T>(type: CoursesStoreEnum) =>
  createSelector(mainSelector, (store): T => store[type].asMutable() as unknown as T);

export const setCoursesField = createAction('courses/set_field');

const reducer = (state = coursesStoreAdapter(), action: ActionType) => {
  switch (action.type) {
    case setCoursesField.type: {
      return state.set(action.path, action.payload);
    }

    default:
      return state;
  }
};

export default reducer;
