import React, { FunctionComponent, useEffect, useState } from 'react';
import styles from './mediaFiles.module.css';
import { WhiteAutocomplete } from '../../index';
import { MediaFile } from '../../../../utils/type';
import useFileSelect from './useFileSelect';
import Typo from '../../../typo';
import cx from 'classnames';
import WhiteButton from '../../whiteButton';
import { iMediaFiles } from '../../../../domain/mediaFiles/useMediaFiles';
import Icon from '../../../icon';
import { MediaFileTypesEnum } from '../../../../utils/enums';
import cloud from '../cloud.svg';
import Spinner from '../../../spinner';
import useCategories from './useCategories';
import Modal from '../../../modal';
import CategoriesModal from './categoriesModal';
import { useSelector } from 'react-redux';
import PaginationPages from '../../../paginationPages';
import { userSelector } from '../../../../store/auth/selectors';
import { userIsAdmin } from '../../../../utils/helpers/isAdmin';
import { take } from 'lodash';
import MediaFileImage from './image';
import MediaFilesPreview from './filesPreview';

type Props = {
  iMediaFiles: iMediaFiles;
  type: string;
  categories?: string[];
  selectFiles: (files: File[]) => void;
  accept?: string;
  phaser?: boolean;
  urls?: string[];
  chooseFiles: (url: string[]) => void;
  onEditFile: (url: string) => void;
  multiUpload?: boolean;
};

export type SelectedFile = MediaFile & { recent?: boolean };

const MediaFilesList: FunctionComponent<Props> = (props) => {
  const {
    type,
    categories = [],
    selectFiles,
    accept = '.svg, .png, .jpg, .jpe, .gif, .jpeg, .webp, .avif, .jfif',
    iMediaFiles,
    phaser,
    urls,
    chooseFiles,
    multiUpload,
    onEditFile
  } = props;

  const user = useSelector(userSelector);
  const { mediaFiles, recentMediaFiles, deleteMediaFile, getMediaFilesList, busy, updateMediaFiles, newMediaFiles } =
    iMediaFiles;
  const { categoryValues, subCategoryMapping, subCategoryValues, gameCategoryValues, categoryOptions } =
    useCategories();
  const acceptableFormats = phaser ? '.svg, .png, .jpg, .jpe, .jpeg, .webp, .avif, .jfif' : accept;

  const [topCategory, setTopCategory] = useState<string>(
    categories.find((item) => categoryValues.includes(item)) || ''
  );
  const [subCategory, setSubCategory] = useState<string>(
    categories.find((item) => [...subCategoryValues, ...gameCategoryValues].includes(item)) || ''
  );
  const [selectedFiles, setSelectedFiles] = useState<SelectedFile[]>([]);
  const [selectedRecentFiles, setSelectedRecentFiles] = useState<SelectedFile[]>([]);
  const [dirty, setDirty] = useState<boolean>(false);
  const [categoryEditMediaFiles, setCategoryEditMediaFiles] = useState<SelectedFile[] | null>(null);

  useEffect(() => {
    if (newMediaFiles.length) {
      setSelectedFiles(multiUpload ? newMediaFiles : [newMediaFiles[0]]);
    }
  }, [newMediaFiles]);

  const getMediaFiles = (page: number, newCategories?: string[]) => {
    if (!newCategories && (!page || page > mediaFiles.meta.totalPages)) {
      return;
    }

    const categories = [];
    if (topCategory) categories.push(topCategory);
    if (subCategoryMapping[topCategory]) {
      categories.push(subCategory);
    }

    getMediaFilesList(page, {
      file_type: MediaFileTypesEnum.IMAGE,
      categories: newCategories || categories,
      adminCreated: true
    });
  };

  useEffect(() => {
    setDirty(true);
    if (dirty) {
      const categories = [];
      if (topCategory) categories.push(topCategory);
      const correctSubCategory = subCategoryMapping[topCategory]?.find((item: any) => item.value === subCategory);
      if (correctSubCategory) {
        categories.push(subCategory);
      }

      getMediaFiles(1, categories);
    }
  }, [topCategory, subCategory]);

  useEffect(() => {
    const item = mediaFiles.items.find((item) => urls?.includes(item.file_key));
    if (item) {
      // setSelectedFiles({ ...item, recent: true });
    }
  }, [mediaFiles, urls]);

  const { canDrop, drop, onChangeInput } = useFileSelect({
    type,
    setFiles: (files: File[]) => selectFiles(files),
    accept: acceptableFormats,
    resizable: true
  });

  const changeCategories = (category: string, sub: boolean = false) => {
    const method = sub ? setSubCategory : setTopCategory;
    method(category);
  };

  const selectMediaFile = (file: MediaFile, recent: boolean) => {
    const item = (recent ? selectedRecentFiles : selectedFiles).map((item) => item.id).includes(file.id);
    const method = recent ? setSelectedRecentFiles : setSelectedFiles;
    if (item) {
      method((prev) => prev.filter((item) => item.id !== file.id));
      return;
    }

    method(multiUpload ? (prev) => [...prev, { ...file, recent }] : [{ ...file, recent }]);
    if (!multiUpload) {
      (!recent ? setSelectedRecentFiles : setSelectedFiles)([]);
    }
  };

  const selectedFileIds = selectedFiles.filter((item) => !item.recent).map((item) => item.id);
  const selectedRecentFileIds = selectedRecentFiles.filter((item) => item.recent).map((item) => item.id);

  const allSelectedFiles = [...selectedFiles, ...selectedRecentFiles];
  const filesListIds = mediaFiles.items.map((item) => item.id);
  const disableCategoryEdit =
    !Boolean(allSelectedFiles.length) ||
    (allSelectedFiles.length && !allSelectedFiles.find((item) => item.brandId !== null));

  const onDeleteFile = async () => {
    if (disableCategoryEdit) return;

    let mediaFilesToDelete = allSelectedFiles;

    if (!userIsAdmin(user.role)) {
      mediaFilesToDelete = mediaFilesToDelete?.filter((item: SelectedFile) => item.brandId) || null;
    }

    if (mediaFilesToDelete.length) {
      const idsToDelete = mediaFilesToDelete.map((item) => item.id);
      const success = await deleteMediaFile(idsToDelete);
      if (success) {
        setSelectedRecentFiles((prevState) => prevState.filter((item) => !idsToDelete.includes(item.id)));
        setSelectedFiles((prevState) => prevState.filter((item) => !idsToDelete.includes(item.id)));
      }
    }
  };

  const modifyFilesCategories = () => {
    if (disableCategoryEdit) {
      return;
    }

    setCategoryEditMediaFiles([...selectedFiles, ...selectedRecentFiles]);
  };

  const submitCategoriesEdit = async (categories: string[]) => {
    let mediaFilestoUpdate: SelectedFile[] | null = categoryEditMediaFiles;

    if (!userIsAdmin(user.role)) {
      mediaFilestoUpdate = mediaFilestoUpdate?.filter((item: SelectedFile) => item.brandId) || null;
    }

    if (!mediaFilestoUpdate?.length) {
      return;
    }

    const success = await updateMediaFiles({ categories, mediaFileIds: mediaFilestoUpdate.map((item) => item.id) });
    if (success) {
      setCategoryEditMediaFiles(null);
    }
  };

  return (
    <div className={styles.sidesWrapper} ref={drop}>
      <>
        <div className={styles.leftSide}>
          <Typo type="p" className={styles.sectionTitle}>
            Uploaded files
          </Typo>
          <div className={cx(styles.dndArea, { [styles.canDrop]: canDrop })}>
            <div className={styles.iconWrapper}>
              <img src={cloud} alt="cloud" draggable={false} />
            </div>
            <Typo white type="p" className={styles.uploadHint}>
              Drag file to upload
            </Typo>
            <Typo white type="p" className={styles.uploadHint}>
              or
            </Typo>
            <WhiteButton className={styles.inputFileBtn} name="browse-files">
              <span>Browse files</span>
              <input className={styles.inputFile} type="file" multiple accept={accept} onChange={onChangeInput} />
            </WhiteButton>
          </div>
          <div className={styles.filtersWrapper}>
            <WhiteAutocomplete
              inline
              name="category"
              clearable
              label="Category"
              focusOnClear={false}
              value={topCategory}
              className={styles.topCategory}
              options={categoryOptions}
              onChange={changeCategories as any}
            />
            {subCategoryMapping[topCategory] && (
              <WhiteAutocomplete
                inline
                clearable
                label="Subcategory"
                focusOnClear={false}
                className={styles.subCategory}
                name="sub-category"
                value={subCategory}
                options={subCategoryMapping[topCategory]}
                onChange={(value: any) => changeCategories(value, true)}
              />
            )}
          </div>
          <div className={styles.mediaList}>
            {busy.list ? (
              <div className={styles.spinnerWrapper}>
                <Spinner white className={styles.spinner} />
              </div>
            ) : (
              <>
                {mediaFiles.items.map((item) => (
                  <MediaFileImage
                    key={item.id}
                    item={item}
                    selectMediaFile={() => selectMediaFile(item, false)}
                    selected={selectedFileIds.includes(item.id)}
                    deleting={busy.delete?.includes(item.id)}
                  />
                ))}
              </>
            )}
          </div>
          <PaginationPages getPage={(page) => getMediaFiles(page)} meta={mediaFiles.meta} />
          <Typo type="p" className={styles.recentsTitle}>
            Other files
          </Typo>
          <div className={cx(styles.mediaList, styles.oneRow)}>
            {take(
              recentMediaFiles.items.filter((item) => !filesListIds.includes(item.id)),
              8
            ).map((item) => (
              <MediaFileImage
                key={item.id}
                item={item}
                selectMediaFile={() => selectMediaFile(item, true)}
                selected={selectedRecentFileIds.includes(item.id)}
              />
            ))}
          </div>
        </div>
        <div className={styles.rightSide}>
          <MediaFilesPreview selectedFiles={allSelectedFiles} />
          <div className={styles.footerWrapper}>
            <div
              className={cx(styles.footerIcon, { [styles.active]: !disableCategoryEdit })}
              onClick={modifyFilesCategories}
            >
              <Icon icon="edit_category" active={!disableCategoryEdit} />
            </div>
            <div
              className={cx(styles.footerIcon, { [styles.active]: allSelectedFiles.length === 1 })}
              onClick={allSelectedFiles.length === 1 ? () => onEditFile(allSelectedFiles[0].file_key) : undefined}
            >
              <Icon icon="pencil_grey" active={allSelectedFiles.length === 1} />
            </div>
            <div
              className={cx(styles.footerIcon, { [styles.active]: !disableCategoryEdit })}
              onClick={() => onDeleteFile()}
            >
              <Icon icon="trash_gray_2" active={!disableCategoryEdit} />
            </div>
            <WhiteButton
              className={cx(styles.footerBtn, { [styles.inActive]: !Boolean(allSelectedFiles.length) })}
              onClick={() => chooseFiles(allSelectedFiles.map((item) => item.file_key))}
              type={Boolean(allSelectedFiles.length) ? 'blue' : 'ghost'}
            >
              {allSelectedFiles.length > 1 ? 'Use these images' : 'Use this image'}
            </WhiteButton>
          </div>
        </div>
      </>
      <Modal
        visible={Boolean(categoryEditMediaFiles)}
        onCancel={() => setCategoryEditMediaFiles(null)}
        className={styles.xsModal}
        title="Edit category"
      >
        {Boolean(categoryEditMediaFiles) && (
          <CategoriesModal
            onSubmit={submitCategoriesEdit}
            categories={categoryEditMediaFiles?.[0]?.categories}
            onCancel={() => setCategoryEditMediaFiles(null)}
          />
        )}
      </Modal>
    </div>
  );
};

export default MediaFilesList;
