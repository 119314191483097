import {
  campaignAdapter,
  campaignStoreAdapter,
  emailTemplateAdapter,
  landingPageElementsAdapter,
  landingScreenElementAdapter,
  prizeAdapter,
  prizeCodeAdapter,
  prizesAdapter,
  relatedCampaignsAdapter,
  storeAdapterMapping,
  updatedNowByAdapter,
  winnerAdapter,
  winnersAdapter
} from './adapters';
import { dropRight } from 'lodash';
import {
  addCampaignToHistory,
  addOnePagination,
  addPrize,
  addWinners,
  changeUpdatedNowBy,
  clearCampaign,
  clearCampaignHistory,
  clearPrizeCodes,
  deleteCampaignConnection,
  deleteOneInPagination,
  removeLastFromHistory,
  removePrize,
  removeWinner,
  removeWinners,
  setActiveElement,
  setCampaign,
  setCampaignInputList,
  setCampaignWithS3,
  setEditingTemplate,
  setEmailTemplates,
  setField,
  setLandingPageElements,
  setPagination,
  setPrizeCodes,
  setPrizes,
  setRelatedCampaigns,
  setWinners,
  updateEmailTemplate,
  updateLandingPageElementsParams,
  updateOneInPagination,
  updatePagination,
  updatePrize,
  updatePrizeCount
} from './actions';
import { Action, CampaignStoreEnum } from './types';
import { campaignsMetaAdapter } from '../campaigns/adapters';
import { clearList } from '../admin/adapters';
import { EmailTemplate, LandingPageElement, Prize, PrizeWinner } from '../../utils/type';

const initialState = campaignStoreAdapter();

const reducer = (state = initialState, action: Action) => {
  switch (action.type) {
    case setCampaignWithS3.type: {
      return state.set('active', campaignAdapter(action.payload)).set('activeS3', campaignAdapter(action.payload));
    }

    case setCampaign.type: {
      return state.set('active', campaignAdapter(action.payload));
    }

    case changeUpdatedNowBy.type: {
      return state.setIn(['active', 'updatedNowBy'], updatedNowByAdapter(action.payload));
    }

    case deleteCampaignConnection.type: {
      return state.setIn(['active', 'connectedCampaignId'], null).set('prizes', []);
    }

    case addCampaignToHistory.type: {
      return state //@ts-ignore
        .update('history', (history) => history.concat([action.payload]));
    }

    case setActiveElement.type: {
      return state.set('activeElement', landingScreenElementAdapter(action.payload));
    }

    case setLandingPageElements.type: {
      return state.set('landingPageElements', landingPageElementsAdapter(action.payload));
    }

    case removeLastFromHistory.type: {
      return state.update('history', (history) => dropRight(history));
    }

    case setRelatedCampaigns.type: {
      return state.set('relatedCampaigns', relatedCampaignsAdapter(action.payload));
    }

    case setPrizes.type: {
      return state.set('prizes', prizesAdapter(action.payload));
    }

    case setPrizeCodes.type: {
      return state
        .setIn(['prizeCodes', 'meta'], campaignsMetaAdapter(action.payload.meta))
        .updateIn(['prizeCodes', 'items'], (items) => items.concat(action.payload.items.map(prizeCodeAdapter)));
    }

    case clearPrizeCodes.type: {
      return state.set('prizeCodes', clearList());
    }
    case setWinners.type: {
      return state.set('winners', winnersAdapter(action.payload));
    }

    case addWinners.type: {
      return state.update('winners', (winners) =>
        winners
          .set('meta', action.payload.meta)
          .update('items', (items) => items.concat(action.payload.items.map(winnerAdapter)))
      );
    }

    case removeWinner.type: {
      return state.update('winners', (winners) =>
        winners.update('items', (items) => items.filter((winner: PrizeWinner) => winner._id !== action.payload))
      );
    }

    case updatePrizeCount.type: {
      return state.update('prizes', (prizes) =>
        prizes.map((prize: Prize) =>
          prize._id === action.payload.id ? { ...prize, remaining: prize.remaining - action.payload.count } : prize
        )
      );
    }

    case removeWinners.type: {
      return state.update('winners', (winners) =>
        winners.update('items', (items) => items.filter((winner: PrizeWinner) => !action.payload.includes(winner._id)))
      );
    }

    case addPrize.type: {
      return (
        state
          //@ts-ignore
          .update('prizes', (prizes) => prizes.concat([prizeAdapter(action.payload)]))
      );
    }

    case updatePrize.type: {
      return state.update('prizes', (prizes) =>
        prizes.map((item: Prize) => {
          if (item._id === action.payload._id) {
            return prizeAdapter(action.payload);
          }
          return item;
        })
      );
    }

    case removePrize.type: {
      return state.update('prizes', (prizes) => prizes.filter((item: Prize) => item._id !== action.payload));
    }

    case setEmailTemplates.type: {
      return state.set('emailTemplates', action.payload.map(emailTemplateAdapter));
    }

    case updateEmailTemplate.type: {
      return (
        state
          //@ts-ignore
          .update('emailTemplates', (templates: EmailTemplate[]) =>
            templates.map((email: EmailTemplate) => {
              if (email._id === action.payload._id) return emailTemplateAdapter(action.payload);
              return email;
            })
          )
      );
    }

    case setCampaignInputList.type:
      return state.set('inputList', action.payload);

    case clearCampaign.type:
      return initialState;

    case clearCampaignHistory.type:
      return state.set('history', []);

    case setEditingTemplate.type: {
      return state.set('editingTemplate', action.payload);
    }

    case updateLandingPageElementsParams.type:
      return state.update('landingPageElements', (landingPageElements) =>
        landingPageElements.map((item: LandingPageElement) => {
          const newParams = action.payload[item.title];
          if (newParams) {
            return {
              ...item,
              params: JSON.stringify(newParams)
            };
          }
          return item;
        })
      );

    case setPagination.type: {
      if (!action.path) return state;

      return state
        .setIn([action.path, 'meta'], campaignsMetaAdapter(action.payload.meta))
        .setIn([action.path, 'items'], action.payload.items.map(storeAdapterMapping[action.path as CampaignStoreEnum]));
    }

    case updatePagination.type: {
      if (!action.path) return state;

      return state
        .setIn([action.path, 'meta'], campaignsMetaAdapter(action.payload.meta))
        .updateIn([action.path, 'items'], (items) =>
          items.concat(action.payload.items.map(storeAdapterMapping[action.path as CampaignStoreEnum](action.payload)))
        );
    }

    case addOnePagination.type: {
      if (!action.path) return state;

      return (
        state
          //@ts-ignore
          .updateIn([action.path, 'items'], (items) =>
            items.concat([storeAdapterMapping[action.path as CampaignStoreEnum](action.payload)])
          )
      );
    }

    case updateOneInPagination.type: {
      if (!action.path || !action.compareField) return state;
      const field = action.compareField as string;
      return state.updateIn([action.path, 'items'], (items) =>
        items.map((item: any) =>
          item[field] === action.payload[field]
            ? storeAdapterMapping[action.path as CampaignStoreEnum](action.payload)
            : item
        )
      );
    }

    case deleteOneInPagination.type: {
      if (!action.path || !action.compareField) return state;
      const field = action.compareField as string;

      return state.updateIn([action.path, 'items'], (items) =>
        items.filter((item: any) => action.payload !== item[field])
      );
    }

    case setField.type: {
      if (!action.path) return state;
      return state.set(action.path, action.payload);
    }

    default:
      return state;
  }
};

export default reducer;
