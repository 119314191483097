import React, { FunctionComponent, useEffect, useState } from 'react';
import styles from './upload.module.css';
import { WhiteButton, WhiteInput } from '../index';
import { MediaFile } from '../../../utils/type';

type Props = {
  mediaFile: Pick<MediaFile, 'file_name'> | null;
  onSubmit: (data: MultiMediaFormData) => Promise<void>;
  onCancel: () => void;
  busy: boolean;
};

export type MultiMediaFormData = {
  file_name: string;
};

const MultiMediaFileForm: FunctionComponent<Props> = ({ mediaFile, onSubmit, onCancel, busy }) => {
  const [formData, setFormData] = useState<MultiMediaFormData>({
    file_name: mediaFile?.file_name || ''
  });

  useEffect(() => {
    setFormData({ file_name: mediaFile?.file_name || '' });
  }, [mediaFile?.file_name]);

  const disabled = !formData.file_name;

  return (
    <>
      <WhiteInput
        name="file_name"
        label="File name"
        value={formData.file_name}
        onChange={(event) => setFormData({ ...formData, file_name: event.target.value })}
      />
      <div className={styles.buttonsWrapper}>
        <WhiteButton className={styles.btn} onClick={onCancel} type="ghost">
          Cancel
        </WhiteButton>
        <WhiteButton className={styles.btn} onClick={() => onSubmit(formData)} disabled={disabled} loading={busy}>
          Upload
        </WhiteButton>
      </div>
    </>
  );
};

export default MultiMediaFileForm;
