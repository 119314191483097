import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import { debounce } from 'lodash';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import Typo from '../../components/typo';
import { LimitTypeEnum, MessageConfig } from '../../domain/useIO';
import red from './red.svg';
import yellow from './yellow.svg';
import close from './close.svg';
import styles from './message.module.css';
import useUI from '../../domain/useUi';
import moment from 'moment-timezone';
import useCurrentUser from '../../utils/hooks/useCurrentUser';

type MessageProps = {
  item: MessageConfig;
  onClose: (id: string, cb: () => void) => void;
};

const SystemMessage: FunctionComponent<MessageProps> = ({ item, onClose }) => {
  const { setShowTicket } = useUI();
  const { user } = useCurrentUser();
  const [closing, onChangeClosing] = useState<boolean>(false);
  const debouncingClose = useMemo(
    () => debounce((id: string) => onClose(id, () => onChangeClosing(false)), 200),
    [onClose]
  );

  const handleClose = useCallback(
    (id: string) => {
      onChangeClosing(true);
      debouncingClose(id);
    },
    [debouncingClose]
  );

  useEffect(() => {
    if (!user.id) {
      handleClose(item.messageId);
    }
  }, [user.id]);

  const daysLeft = moment(user?.account.payment_expires_at).diff(moment(), 'days');

  const texts = useMemo(
    () => ({
      [LimitTypeEnum.Date]: `This is a friendly reminder that your subscription to Adact will expire in ${daysLeft + 1} day${daysLeft === 0 ? '' : 's'}, on ${moment().add(daysLeft, 'days').format('DD MMMM YYYY')}`,
      [LimitTypeEnum.UniqueVisitors]: !item.campaignId
        ? `You’ve gathered ${item.percent}% of the unique visitors allowed for your campaigns. After the limit is reached your campaigns will be Paused.`
        : `You’ve gathered ${item.percent}% of the unique visitors allowed for campaign “${item.campaignTitle}”. After the limit is reached, the campaign will be Paused.`
    }),
    [daysLeft, item.campaignId, item.campaignTitle, item.percent]
  );

  const fullTexts = useMemo(
    () => ({
      [LimitTypeEnum.Date]: `This is a friendly reminder that your subscription to Adact will expire in ${daysLeft + 1} day${daysLeft === 0 ? '' : 's'}, on ${moment().add(daysLeft, 'days').format('DD MMMM YYYY')}`,
      [LimitTypeEnum.UniqueVisitors]: !item.campaignId
        ? `You have exceeded your account limits for the number of unique visitors. Your campaigns have been paused.`
        : `You have exceeded your unique visitors limit for campaign “${item.campaignTitle}” and the campaign was set to Paused.`
    }),
    [daysLeft, item.campaignId, item.campaignTitle]
  );

  const text = item.percent >= 110 ? fullTexts[item.messageType] : texts[item.messageType];

  const exceededLimitsLink = useMemo(
    () => (
      <Typo type="p" white className={styles.grayText}>
        Increase your limits
        <Link
          onClick={() => handleClose(item.messageId)}
          className={styles.link}
          to={item.campaignId ? `/campaigns/${item.campaignId}/settings?upgrade=true` : '/settings/subscription'}
        >
          here
        </Link>
      </Typo>
    ),
    [handleClose, item.campaignId, item.messageId]
  );

  const link = useMemo(
    () => (
      <Typo type="p" white className={styles.grayText}>
        Consider upgrading your limits{' '}
        <Link onClick={() => handleClose(item.messageId)} className={styles.link} to="/settings/subscription">
          here
        </Link>
        .
      </Typo>
    ),
    [handleClose, item.messageId]
  );

  const customLink = useMemo(
    () => (
      <Typo type="p" white className={styles.grayText}>
        Please get in touch with{' '}
        <div onClick={() => setShowTicket({ show: true, type: 'payment' })} className={styles.link}>
          support
        </div>{' '}
        to continue using all the features you love.
      </Typo>
    ),
    [setShowTicket]
  );

  const links = useMemo(
    () => (item.percent < 99 && item.messageId !== 'custom_date' ? link : exceededLimitsLink),
    [item, link, exceededLimitsLink]
  );

  return (
    <div className={cx(styles.wrapper, styles[item.messageColor], { [styles.closing]: closing })}>
      <img src={item.messageColor === 'red' ? red : yellow} className={styles.addy} alt="info" draggable={false} />
      <div className={styles.textWrapper}>
        <Typo type="p" white className={styles.text}>
          {text}
        </Typo>
        {item.messageId === 'custom_date' ? customLink : links}
      </div>
      <button onClick={() => handleClose(item.messageId)} className={styles.close}>
        <img src={close} alt="close" draggable={false} />
      </button>
    </div>
  );
};

export default SystemMessage;
