import React, { FunctionComponent } from 'react';
import AntCheckbox, { CheckboxProps } from 'antd/lib/checkbox';
import cx from 'classnames';
import styles from './checkbox.module.css';

interface Props extends CheckboxProps {
  className?: string;
  label?: string;
  name: string;
  onBlurCallback?: () => void;
  refProp?: any;
  required?: boolean;
}

const Checkbox: FunctionComponent<Props> = ({ className, refProp, name, label, onBlurCallback, ...other }) => {
  return (
    <div className={styles.wrapper}>
      <AntCheckbox
        className={cx(className, styles.input)}
        ref={refProp}
        name={name}
        cypress-id={`${name}-component`}
        //@ts-ignore
        onBlur={onBlurCallback}
        {...other}
      >
        {label}
      </AntCheckbox>
    </div>
  );
};

export default Checkbox;
