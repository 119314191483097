import I, { ImmutableObject } from 'seamless-immutable';
import { TokensStoreEnum } from './types';
import { DEFAULT_PAGINATION } from '../../utils/const';

export function tokensStoreAdapter(): ImmutableObject<Record<string, any>> {
  return I({
    [TokensStoreEnum.CAMPAIGN_UNLOCK_BY_TOKEN]: [],
    [TokensStoreEnum.TOKEN_PURCHASE_HISTORY]: DEFAULT_PAGINATION,
    [TokensStoreEnum.TOKEN_PURCHASE]: [],
    [TokensStoreEnum.TOKEN_USAGE_HISTORY]: DEFAULT_PAGINATION
  });
}
