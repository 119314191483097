import React, { FunctionComponent } from 'react';
import cx from 'classnames';
import Link from '../../components/link';
import logo from './logo.svg';
import styles from './logo.module.css';

type Props = {
  className?: string;
  to?: string;
};

const Logo: FunctionComponent<Props> = ({ className, to }) => {
  return (
    <div className={cx(styles.wrapper, className)}>
      {to ? (
        <Link to={to} name="adact-logo">
          <img src={logo} alt="Adact" />
        </Link>
      ) : (
        <img src={logo} alt="Adact" />
      )}
    </div>
  );
};

export default Logo;
