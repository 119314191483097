import React, {
  ChangeEvent,
  CSSProperties,
  FunctionComponent,
  ReactElement,
  ReactText,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import cx from 'classnames';
import WhiteField from '../field/white';
import Icon from '../../icon';
import { debounce } from 'lodash';
import styles from './autocomplete.module.css';
import InputType from '../../../utils/type/input.type';

export type Option = {
  value: string | number | any;
  label: any | ReactElement;
  activeLabel?: any | ReactElement;
};

type Props = InputType & {
  className?: string;
  inputClassName?: string;
  label?: string;
  labelWidth?: number;
  name: string;
  value: string | number | ReactText | any;
  placeholder?: string;
  disabled?: boolean;
  required?: boolean;
  withoutIcon?: boolean;
  onLoad?: (search: string) => void;
  errors?: string[];
  excluded?: any[];
  onChange?: (value: string | number | ReactText) => void;
  onSelect?: (option: string | number) => void;
  onClear?: () => void;
  clearable?: boolean;
  options: Option[];
  inline?: boolean;
  overflow?: boolean;
  tiny?: boolean;
  focusOnClear?: boolean;
  clearOnSelect?: boolean;
  activeValueDisplay?: string;
};

const WhiteAutocomplete: FunctionComponent<Props> = (props) => {
  const {
    name,
    options,
    value,
    onChange,
    placeholder,
    inputClassName,
    disabled,
    errors,
    onLoad,
    labelWidth,
    withoutIcon,
    inline,
    tiny,
    excluded,
    onSelect,
    overflow,
    clearable,
    onClear,
    focusOnClear = true,
    changeConfigField,
    clearOnSelect,
    activeValueDisplay
  } = props;
  const [show, onChangeShow] = useState<boolean>(false);
  const currentOption = useMemo(() => options.find((item: Option) => item.value === value), [options, value]);
  const [search, onChangeSearch] = useState<string>(currentOption?.label || '');
  const uniqName = useMemo(() => `adact-white-select-${name.replaceAll(/[^a-zA-Z0-9]/g, '')}`, [name]);
  const inputWrapper = useRef<HTMLDivElement | null>(null);
  const input = useRef<HTMLInputElement | null>(null);

  const makeDebouncedRequest = useCallback(
    debounce(
      (search: string) => {
        if (onLoad) {
          onLoad(search);
        }
      },
      500,
      { trailing: true }
    ),
    [onLoad]
  );

  useEffect(
    () => {
      if (search !== '' && onLoad) makeDebouncedRequest(search);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [search]
  );

  useEffect(
    () => {
      document.addEventListener('click', handleClick, true);
      return () => {
        document.removeEventListener('click', handleClick, true);
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [show]
  );

  useEffect(() => {
    if (input.current !== document.activeElement) {
      const currentOption = options.find((item: Option) => item.value === value);
      onChangeSearch(currentOption?.label || '');
    }
  }, [value, options]);

  const handleClick = useCallback(
    (event) => {
      const inSelect = event.target?.closest(`.${uniqName}`);
      if (show && !inSelect) {
        onChangeShow(false);
        if (currentOption && currentOption?.value === value) onChangeSearch(currentOption?.label);
      }
      return;
    },
    [show, value, currentOption, uniqName]
  );

  const handleShow = useCallback(() => {
    onChangeShow(true);
    onChangeSearch('');
  }, []);

  const onClick = useCallback(
    (value: string | number) => {
      if (onChange) onChange(value);
      if (changeConfigField) changeConfigField({ [name]: value });
      if (onSelect) onSelect(value);
      if (clearOnSelect) {
        onChangeSearch('');
      } else {
        const currentOption = options.find((item: Option) => item.value === value);
        onChangeSearch(currentOption?.label || '');
      }
      onChangeShow(false);
    },
    [changeConfigField, name, onChange, onSelect, clearOnSelect, options]
  );

  const correctOptions = useMemo(() => {
    if (excluded) return options.filter((item: Option) => !excluded.includes(item.value));
    return options;
  }, [options, excluded]);

  const suggestedOptions = useMemo(
    () =>
      correctOptions.filter((item: Option) => {
        const transformedSearch = search.replace(/ /g, '').toLowerCase();
        const transformedLabel = item.label.replace(/ /g, '').toLowerCase();
        return transformedLabel.includes(transformedSearch);
      }),
    [search, correctOptions]
  );

  const overflowStyles: CSSProperties = useMemo(() => {
    if (inputWrapper.current && overflow) {
      const rect = inputWrapper.current?.getBoundingClientRect();
      return {
        left: rect.x,
        position: 'fixed',
        top: rect.y + rect.height,
        width: rect.width,
        minWidth: rect.width
      };
    }
    return {};
  }, [
    overflow,
    inputWrapper.current,
    inputWrapper.current ? inputWrapper.current?.getBoundingClientRect() : undefined
  ]);

  const onReset = useCallback(() => {
    onChangeSearch('');
    if (clearable && onChange) onChange('');
    if (clearable && changeConfigField) changeConfigField({ [name]: '' });
    if (onClear) onClear();
    if (input.current && focusOnClear) input.current?.focus();
  }, [clearable, onChange, changeConfigField, name, onClear, focusOnClear]);

  return (
    <WhiteField width={labelWidth} {...props}>
      <div ref={inputWrapper} className={cx(styles.select, inputClassName, uniqName, { [styles.active]: show })}>
        <div className={styles.searchWrapper}>
          {withoutIcon ? null : <Icon className={styles.icon} active={show} icon="search" />}
          <input
            cypress-id={`${name}-component`}
            ref={input}
            placeholder={placeholder}
            onFocus={handleShow}
            disabled={disabled}
            onChange={(event: ChangeEvent<HTMLInputElement>) => onChangeSearch(event.target.value)}
            value={show ? search : activeValueDisplay || search}
            className={cx(styles.search, 'adact-autocomplete-search', {
              [styles.hasError]: errors,
              [styles.withoutIcon]: withoutIcon,
              [styles.tiny]: inline || tiny
            })}
          />
          {search ? <Icon icon="close_black" className={styles.clear} onClick={onReset} /> : null}
        </div>
        {show ? (
          <div className={styles.listWrapper} style={overflowStyles}>
            <ul>
              {suggestedOptions.length > 0 ? (
                suggestedOptions.map((item: Option, idx: number) => (
                  <li role="button" key={idx} onClick={() => onClick(item.value)}>
                    {item.label}
                  </li>
                ))
              ) : (
                <li role="button" className={styles.empty} key={`${name}-empty-line`}>
                  No suggestions...
                </li>
              )}
            </ul>
          </div>
        ) : null}
      </div>
    </WhiteField>
  );
};

export default WhiteAutocomplete;
