import { createAction } from '../../utils/actions';
import { ActionType } from '../../utils/type';
import { Store } from '../types';
import { PurchaseNotificationsStoreEnum } from './types';
import { createSelector } from 'reselect';
import { purchaseNotificationsStoreAdapter } from './adapters';

export const mainSelector = (store: Store) => store.purchaseNotifications;
export const purchaseNotificationsSelector = <T>(type: PurchaseNotificationsStoreEnum) =>
  createSelector(mainSelector, (store): T => store[type] as unknown as T);

export const setPurchaseNotificationsField = createAction('purchase_notifications/set_field');

const reducer = (state = purchaseNotificationsStoreAdapter(), action: ActionType) => {
  switch (action.type) {
    case setPurchaseNotificationsField.type: {
      return state.set(action.path, action.payload);
    }

    default:
      return state;
  }
};

export default reducer;
