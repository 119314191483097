import { usePageQuery } from '../usePageQuery';
import { CommonStoreEnum } from '../../../store/common/types';
import { useDispatch, useSelector } from 'react-redux';
import { commonSelector } from '../../../store/common/selectors';
import { useEffect, useState } from 'react';
import { setCommonField } from '../../../store/common';

const useTriviaQuestionDebug = (questions?: Record<string, any>[]) => {
  const { query } = usePageQuery();
  const dispatch = useDispatch();

  const debugQuestionId = useSelector(commonSelector(CommonStoreEnum.TRIVIA_DEBUG_QUESTION));
  const debugResultId = useSelector(commonSelector(CommonStoreEnum.TRIVIA_DEBUG_RESULT));
  const triviaStartingQuestionId = useSelector(commonSelector(CommonStoreEnum.TRIVIA_STARTING_QUESTION));

  const [gameFirstQuestion, setGameFirstQuestion] = useState<number | string | undefined>(undefined);

  useEffect(() => {
    if (questions?.length) {
      setGameFirstQuestion(questions[0].id);
    }
  }, [questions]);

  const debugSpecificQuestion = !query.tab || ['questions', 'visuals'].includes(query.tab as string);

  const setNewDebugQuestion = (id: number | undefined | string) => {
    if (debugSpecificQuestion) {
      dispatch(setCommonField(id, CommonStoreEnum.TRIVIA_STARTING_QUESTION));
      dispatch(setCommonField(id, CommonStoreEnum.TRIVIA_DEBUG_QUESTION));
      dispatch(setCommonField(undefined, CommonStoreEnum.TRIVIA_DEBUG_RESULT));
    }
  };

  const setTriviaGameDebugQuestion = (id: number | undefined | string, forceRestartGame?: boolean) => {
    if (debugSpecificQuestion) {
      if (forceRestartGame) {
        dispatch(setCommonField(id, CommonStoreEnum.TRIVIA_STARTING_QUESTION));
      }
      dispatch(setCommonField(id, CommonStoreEnum.TRIVIA_DEBUG_QUESTION));
    }
  };

  const setDebugResult = (id: number | undefined) => {
    if (debugSpecificQuestion) {
      dispatch(setCommonField(id, CommonStoreEnum.TRIVIA_DEBUG_RESULT));

      if (id === undefined) {
        dispatch(setCommonField(gameFirstQuestion, CommonStoreEnum.TRIVIA_STARTING_QUESTION));
        dispatch(setCommonField(gameFirstQuestion, CommonStoreEnum.TRIVIA_DEBUG_QUESTION));
      } else {
        dispatch(setCommonField(undefined, CommonStoreEnum.TRIVIA_DEBUG_QUESTION));
      }
    }
  };

  return {
    debugQuestionId,
    debugSpecificQuestion,
    setNewDebugQuestion,
    setDebugResult,
    debugResultId,
    triviaStartingQuestionId,
    setTriviaGameDebugQuestion
  };
};

export default useTriviaQuestionDebug;
