export enum ScreenTypes {
  START = 'start',
  GAME = 'game',
  BEFORE_GAME_EXTRA = 'before_game_extra',
  AFTER_GAME_EXTRA = 'after_game_extra',
  END = 'end',
  EXPIRED = 'expired',
  MODAL = 'modal',
  VOTING_MODAL = 'voting_modal',
  UPLOAD_MODAL = 'upload_modal',
  SIDE_BAR = 'side_bar'
}
