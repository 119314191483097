import { createSelector } from 'reselect';
import { CampaignState, CampaignStoreEnum } from './types';
import { Store } from '../types';

export const campaignMainSelector = (store: Store) => store.campaign;
export const campaignSelector = createSelector(campaignMainSelector, (campaign: CampaignState) =>
  campaign.active.asMutable({ deep: true })
);
export const campaignGameConfigSelector = createSelector(campaignMainSelector, (campaign: CampaignState) =>
  JSON.parse(campaign.active.game_config)
);
export const campaignHistorySelector = createSelector(campaignMainSelector, (campaign: CampaignState) =>
  campaign.history.asMutable({ deep: true })
);
export const campaignBackgroundSelector = createSelector(campaignMainSelector, (campaign: CampaignState) =>
  JSON.parse(campaign.active.background_config)
);
export const campaignGameAreaSelector = createSelector(campaignMainSelector, (campaign: CampaignState) =>
  JSON.parse(campaign.active.game_area_config)
);
export const campaignNotificationsConfigSelector = createSelector(campaignMainSelector, (campaign: CampaignState) =>
  JSON.parse(campaign.active.notifications_config)
);
export const campaignContentSelector = createSelector(campaignMainSelector, (campaign: CampaignState) =>
  JSON.parse(campaign.active.content_config)
);
export const landingPageScreenElementsSelector = createSelector(campaignMainSelector, (campaign: CampaignState) =>
  campaign.active.landingPageScreenElements.asMutable({ deep: true })
);
export const activeElementsSelector = createSelector(campaignMainSelector, (campaign: CampaignState) =>
  campaign.activeElement ? campaign.activeElement.asMutable({ deep: true }) : null
);
export const landingPageElementsSelector = createSelector(campaignMainSelector, (campaign: CampaignState) =>
  campaign.landingPageElements.asMutable({ deep: true })
);
export const leaderBoardConfigsSelector = createSelector(campaignMainSelector, (campaign: CampaignState) =>
  JSON.parse(campaign.active.leaderboard_config)
);
export const tacConfigsSelector = createSelector(campaignMainSelector, (campaign: CampaignState) =>
  JSON.parse(campaign.active.terms_and_conditions_config)
);
export const embedOptionsConfigsSelector = createSelector(campaignMainSelector, (campaign: CampaignState) =>
  JSON.parse(campaign.active.embed_options_config)
);
export const sideBarConfigsSelector = createSelector(campaignMainSelector, (campaign: CampaignState) =>
  JSON.parse(campaign.active.side_bar_config)
);
export const privacyPolicyConfigsSelector = createSelector(campaignMainSelector, (campaign: CampaignState) =>
  JSON.parse(campaign.active.privacy_policy_config)
);
export const errorsConfigSelector = createSelector(campaignMainSelector, (campaign: CampaignState) =>
  JSON.parse(campaign.active.errors_config)
);
export const prizesSelector = createSelector(campaignMainSelector, (campaign: CampaignState) =>
  campaign.prizes.asMutable({ deep: true })
);
export const prizeCodesSelector = createSelector(campaignMainSelector, (campaign: CampaignState) =>
  campaign.prizeCodes.asMutable({ deep: true })
);
export const winnersSelector = createSelector(campaignMainSelector, (campaign: CampaignState) =>
  campaign.winners.asMutable({ deep: true })
);
export const relatedCampaignsListSelector = createSelector(campaignMainSelector, (campaign: CampaignState) =>
  campaign.relatedCampaigns.asMutable({ deep: true })
);
export const updatedNowBySelector = createSelector(campaignMainSelector, (campaign: CampaignState) =>
  campaign.active.updatedNowBy.asMutable({ deep: true })
);
export const campaignInputListSelector = createSelector(campaignMainSelector, (campaign: CampaignState) =>
  campaign.inputList.asMutable({ deep: true })
);
export const editingTemplateSelector = createSelector(
  campaignMainSelector,
  (campaign: CampaignState) => campaign.editingTemplate
);
export const emailTemplatesSelector = createSelector(campaignMainSelector, (campaign: CampaignState) =>
  campaign.emailTemplates.asMutable({ deep: true })
);
export const integrationsConfigsSelector = createSelector(campaignMainSelector, (campaign: CampaignState) =>
  JSON.parse(campaign.active.integrations_config)
);
export const defaultUploadedContentSelector = createSelector(campaignMainSelector, (campaign: CampaignState) =>
  campaign[CampaignStoreEnum.DEFAULT_UPLOADED_CONTENT].asMutable({ deep: true })
);
export const playerUploadedContentSelector = createSelector(campaignMainSelector, (campaign: CampaignState) =>
  campaign[CampaignStoreEnum.PLAYER_UPLOADED_CONTENT].asMutable({ deep: true })
);
export const leaderboardConnectionSelector = createSelector(campaignMainSelector, (campaign: CampaignState) =>
  campaign[CampaignStoreEnum.LEADERBOARD_CONNECTION_SETTINGS].asMutable({ deep: true })
);
export const cachedCampaignSelector = createSelector(campaignMainSelector, (campaign: CampaignState) =>
  campaign.activeS3.asMutable({ deep: true })
);
