import React, { FunctionComponent, useContext, useMemo, useState } from 'react';
import { take } from 'lodash';
import IOContext, { MessageConfig } from '../../domain/useIO';
import styles from './message.module.css';
import SystemMessage from './systemMessage';
import SystemNotificationMessageElement from './systemNotificationMessage';
import useUserSystemNotifications from '../../domain/notifications/useUserSystemNotifications';
import { SystemNotificationMessage } from '../../utils/type';
import { SystemNotificationStatusTypeEnum, SystemNotificationTypeEnum, UserRoles } from '../../utils/enums';
import SystemNotificationPopup from './systemNotificationPopup';
import cx from 'classnames';
import useBrand from '../../domain/useBrand';
import useCurrentUser from '../../utils/hooks/useCurrentUser';
import useQuery from '../../utils/query';
import Link from '../../components/link';
import { UIStoreEnum } from '../../store/ui/types';
import { uiSelector } from '../../store/ui/selectors';
import { useSelector } from 'react-redux';

const LeadsMessages: FunctionComponent = () => {
  const [showBrandMessage, setShowBrandMessage] = useState<boolean>(true);
  const { closeMessage, messageConfigs } = useContext(IOContext);
  const query = useQuery();
  const showBrandEditModalPopup: boolean = useSelector(uiSelector<boolean>(UIStoreEnum.SHOW_DEFAULT_BRAND_POPUP));
  const showBrandEditModal: boolean = useSelector(uiSelector<boolean>(UIStoreEnum.SHOW_ACTIVE_BRAND_EDIT_MODAL));
  const { notificationsToShow, onCloseNotification } = useUserSystemNotifications();
  const { brand } = useBrand();
  const { user, account } = useCurrentUser();

  const messageSystemNotifications = useMemo(() => {
    return notificationsToShow.filter((item) => item.type === SystemNotificationTypeEnum.MESSAGE);
  }, [notificationsToShow]);

  const popupSystemNotifications = useMemo(
    () => notificationsToShow.filter((item) => item.type === SystemNotificationTypeEnum.POPUP),
    [notificationsToShow]
  );

  const messages: (MessageConfig | SystemNotificationMessage)[] = useMemo(() => {
    let allMessages = [...messageConfigs, ...messageSystemNotifications];

    if (
      showBrandMessage &&
      user.id &&
      user.role === UserRoles.ACCOUNT_OWNER &&
      brand.default_brand &&
      account.paid &&
      !query.edit_brand &&
      !showBrandEditModalPopup &&
      !showBrandEditModal
    ) {
      allMessages = [
        {
          id: 0,
          type: SystemNotificationTypeEnum.MESSAGE,
          status: SystemNotificationStatusTypeEnum.WARN,
          link: '',
          buttonText: '',
          text: '',
          element: (
            <p className={styles.brandSlugPopup}>
              You are currently using automatically generated brand name of "{brand.title}".
              <Link className={styles.link} to={`/settings/brand?id=${brand.id}&edit_brand=true`}>
                Click here
              </Link>
              to claim your brand name before publishing your campaign
            </p>
          )
        },
        ...allMessages
      ];
    }

    return take(allMessages, 3);
  }, [
    showBrandEditModalPopup,
    brand.default_brand,
    brand.id,
    brand.title,
    messageConfigs,
    messageSystemNotifications,
    query.edit_brand,
    showBrandMessage,
    user
  ]);

  const modalMessages = useMemo(() => take(popupSystemNotifications, 1), [popupSystemNotifications]);

  const closeNotification = (id: number) => {
    if (id) {
      onCloseNotification(id);
    } else {
      setShowBrandMessage(false);
    }
  };

  return (
    <div className={styles.container}>
      {messages.map((item: MessageConfig | SystemNotificationMessage) =>
        item.hasOwnProperty('messageId') ? (
          <SystemMessage item={item as MessageConfig} key={(item as MessageConfig).messageId} onClose={closeMessage} />
        ) : (
          <SystemNotificationMessageElement
            item={item as SystemNotificationMessage}
            key={(item as SystemNotificationMessage).id}
            onClose={closeNotification}
          />
        )
      )}
      <div className={cx(styles.overlay, { [styles.show]: modalMessages.length })} />
      {modalMessages.map((item: SystemNotificationMessage) => (
        <SystemNotificationPopup item={item} key={item.id} onClose={onCloseNotification} />
      ))}
    </div>
  );
};

export default LeadsMessages;
