import React, { FunctionComponent, useEffect, useMemo } from 'react';
import cx from 'classnames';
import styles from './icon.module.css';

import analytics from './icons/analytics.svg';
import arrow_left from './icons/arrow_left.svg';
import arrow_right from './icons/arrow_right.svg';
import brand from './icons/brand.svg';
import campaigns from './icons/campaigns.svg';
import check from './icons/check.svg';
import cog from './icons/cog.svg';
import filter from './icons/filter.svg';
import integration from './icons/integration.svg';
import logout from './icons/logout.svg';
import members from './icons/members.svg';
import plan from './icons/plan.svg';
import plus from './icons/plus.svg';
import plus_alt from './icons/plus_alt.svg';
import search from './icons/search.svg';
import search_white from './icons/search.svg';
import sort from './icons/sort.svg';
import trash from './icons/trash.svg';
import info from './icons/info.svg';
import arrow_down from './icons/arrow_down.svg';
import spinner from './icons/spinner.svg';
import blue_spinner from './icons/blue_spinner.svg';
import white_check from './icons/white_check.svg';
import caret from './icons/caret.svg';
import pencil from './icons/pencil.svg';
import trash_gray from './icons/trash_gray.svg';
import trash_gray_2 from './icons/trash_gray.svg';
import gamepad from './icons/gamepad.svg';
import user from './icons/user.svg';
import users from './icons/users.svg';
import collapse from './icons/collapse.svg';
import uncollapse from './icons/uncollapse.svg';
import timer from './icons/timer.svg';
import blue_logout from './icons/blue_logout.svg';
import trophy from './icons/trophy.svg';
import download from './icons/download.svg';
import calendar from './icons/calendar.svg';
import arrow_right_blue from './icons/arrow_right_blue.svg';
import arrow_left_blue from './icons/arrow_left_blue.svg';
import help from './icons/help.svg';
import mail from './icons/mail.svg';
import eye from './icons/eye.svg';
import eye_black from './icons/eye_black.svg';
import lock from './icons/lock.svg';
import trophy_black from './icons/trophy_black.svg';
import gamepad_black from './icons/gamepad_black.svg';
import tour from './icons/tour.svg';
import copy from './icons/copy.svg';
import copy2 from './icons/copy-2.svg';
import blue_plus from './icons/blue_plus.svg';
import white_mail from './icons/white_mail.svg';
import close from './icons/close.svg';
import close_black from './icons/close_black.svg';
import close_blue from './icons/close_blue.svg';
import cog_blue_gradient from './icons/cog_blue_gradient.svg';
import campaigns_blue_gradient from './icons/campaigns_blue_gradient.svg';
import trophy_blue_gradient from './icons/trophy_blue_gradient.svg';
import gamepad_blue_gradient from './icons/gamepad_blue_gradient.svg';
import check_ring from './icons/check_ring.svg';
import arrow_right_circle from './icons/arrow_right_circle.svg';
import graph from './icons/graph.svg';
import plus_circle_blue from './icons/plus_circle_blue.svg';
import share from './icons/share.svg';
import images from './icons/images.svg';
import image from './icons/image.svg';
import image_uploader from './icons/image_uploader.svg';
import link from './icons/link.svg';
import screen from './icons/screen.svg';
import screen_link from './icons/screen_link.svg';
import text from './icons/text.svg';
import leaderboard from './icons/leaderboard.svg';
import form from './icons/form.svg';
import social_media from './icons/social_media.svg';
import devider from './icons/devider.svg';
import help_triangle from './icons/help_triangle.svg';
import img from './icons/img.svg';
import crop from './icons/crop.svg';
import bg from './icons/bg.svg';
import upload from './icons/upload.svg';
import video from './icons/video.svg';
import columns from './icons/columns.svg';
import squared_minus from './icons/squared_minus.svg';
import squared_plus from './icons/squared_plus.svg';
import big_check from './icons/big_check.svg';
import speedometer from './icons/speedometer.svg';
import mail_open from './icons/mail_open.svg';
import cursor_click from './icons/cursor_click.svg';
import cursor_button from './icons/cursor_button.svg';
import link_external from './icons/link_external.svg';
import page_layout from './icons/page_layout.svg';
import close_square from './icons/close_square.svg';
import rotate from './icons/rotate.svg';
import active_rotate from './icons/rotate.svg';
import chat from './icons/chat.svg';
import chat_correct from './icons/chat_correct.svg';
import chat_question from './icons/chat_question.svg';
import mail_black from './icons/mail_black.svg';

import active_analytics from './active/analytics.svg';
import active_arrow_left from './active/arrow_left.svg';
import active_arrow_right from './active/arrow_right.svg';
import active_brand from './active/brand.svg';
import active_campaigns from './active/campaigns.svg';
import active_check from './active/check.svg';
import active_cog from './active/cog.svg';
import active_eye_black from './active/eye_black.svg';
import active_filter from './active/filter.svg';
import active_integration from './active/integration.svg';
import active_logout from './active/logout.svg';
import active_members from './active/members.svg';
import active_plan from './active/plan.svg';
import active_plus from './active/plus.svg';
import active_plus_alt from './active/plus_alt.svg';
import active_search from './active/search.svg';
import active_sort from './active/sort.svg';
import active_trash from './active/trash.svg';
import active_info from './active/info.svg';
import active_arrow_down from './active/arrow_down.svg';
import active_spinner from './active/spinner.svg';
import active_blue_spinner from './active/blue_spinner.svg';
import active_white_check from './active/white_check.svg';
import active_caret from './active/caret.svg';
import active_pencil from './active/pencil.svg';
import active_trash_gray from './active/trash_gray.svg';
import active_gamepad from './active/gamepad.svg';
import active_user from './active/user.svg';
import active_users from './active/users.svg';
import active_collapse from './active/collapse.svg';
import active_uncollapse from './active/uncollapse.svg';
import active_timer from './active/timer.svg';
import active_blue_logout from './active/blue_logout.svg';
import active_trophy from './active/trophy.svg';
import active_download from './active/download.svg';
import active_calendar from './active/calendar.svg';
import active_arrow_right_blue from './active/arrow_right_blue.svg';
import active_arrow_left_blue from './active/arrow_left_blue.svg';
import active_help from './active/help.svg';
import active_mail from './active/mail.svg';
import active_eye from './active/eye.svg';
import active_lock from './active/lock.svg';
import active_trophy_black from './active/trophy_black.svg';
import active_gamepad_black from './active/gamepad_black.svg';
import active_tour from './active/tour.svg';
import active_copy from './active/copy.svg';
import active_copy2 from './active/copy-2.svg';
import active_blue_plus from './active/blue_plus.svg';
import active_white_mail from './active/white_mail.svg';
import active_close from './active/close.svg';
import active_cog_blue_gradient from './active/cog_blue_gradient.svg';
import active_campaigns_blue_gradient from './active/campaigns_blue_gradient.svg';
import active_trophy_blue_gradient from './active/trophy_blue_gradient.svg';
import active_gamepad_blue_gradient from './active/gamepad_blue_gradient.svg';
import active_check_ring from './active/check_ring.svg';
import active_arrow_right_circle from './active/arrow_right_circle.svg';
import active_graph from './active/graph.svg';
import active_close_black from './active/close_black.svg';
import active_close_blue from './active/close_blue.svg';
import active_share from './active/share.svg';
import active_images from './active/images.svg';
import active_image from './active/image.svg';
import active_link from './active/link.svg';
import active_screen from './active/screen.svg';
import active_screen_link from './active/screen_link.svg';
import active_text from './active/text.svg';
import active_plus_circle_blue from './active/plus_circle_blue.svg';
import active_leaderboard from './active/leaderboard.svg';
import active_form from './active/form.svg';
import active_social_media from './active/social_media.svg';
import active_devider from './active/devider.svg';
import active_image_uploader from './active/image_uploader.svg';
import active_img from './active/img.svg';
import active_crop from './active/crop.svg';
import active_bg from './active/bg.svg';
import active_upload from './active/upload.svg';
import active_video from './active/video.svg';
import active_columns from './active/columns.svg';
import active_squared_minus from './active/squared_minus.svg';
import active_squared_plus from './active/squared_plus.svg';
import active_help_triangle from './active/help_triangle.svg';
import active_big_check from './active/big_check.svg';
import active_speedometer from './active/speedometer.svg';
import active_mail_open from './active/mail_open.svg';
import active_cursor_click from './active/cursor_click.svg';
import active_cursor_button from './active/cursor_button.svg';
import active_link_external from './active/link_external.svg';
import active_page_layout from './active/page_layout.svg';
import active_close_square from './active/close_square.svg';
import active_search_white from './icons/search_white.svg';
import active_mail_black from './active/mail_black.svg';

import folders from './icons/folders.svg';
import active_folders from './active/folders.svg';
import pie_chart from './active/pie_chart.svg';
import folder from './icons/folder.svg';
import active_folder from './active/folder.svg';
import dots from './icons/dots.svg';
import active_dots from './active/dots.svg';
import arrow_down_black from './icons/arrow_down_black.svg';
import active_arrow_down_black from './active/arrow_down_black.svg';
import info_yellow from './icons/info_yellow.svg';
import reload from './icons/reload.svg';
import close_square_black from './icons/close_square_black.svg';
import check_blue from './icons/check_blue.svg';
import edit_white from './icons/edit_white.svg';
import block from './icons/block.svg';
import active_block from './active/block.svg';

import pause from './icons/pause.svg';
import play from './icons/play.svg';
import chevron_r from './icons/chevron-r.svg';
import chevron_l from './icons/chevron-l.svg';
import edit_category from './icons/edit_category.svg';
import active_edit_category from './active/edit_category.svg';
import edit_image from './icons/edit_image.svg';
import active_edit_image from './active/edit_image.svg';
import active_trash_gray_2 from './active/trash_black.svg';
import pencil_grey from './icons/pencil_grey.svg';
import knowledge from './icons/knowledge.svg';
import play_white from './icons/play_white.svg';
import yellow_users from './icons/yellow_users.svg';
import yellow_calendar from './icons/yellow_calendar.svg';
import black_lock from './icons/black_lock.svg';
import white_upload from './icons/white_upload.svg';
import coins from './icons/coins.svg';
import coins_black from './icons/coins_black.svg';
import coins_stack from './icons/coins_stack.svg';
import yellow_unlocked_lock from './active/yellow_unlocked_lock.svg';
import active_blue_calendar from './active/blue_calendar.svg';
import blue_calendar from './icons/blue_calendar.svg';
import active_blue_users from './active/blue_users.svg';
import blue_users from './icons/blue_users.svg';
import arrow_right_circle_blue from './icons/arrow_right_circle_blue.svg';
import link_black from './icons/link_black.svg';
import check_blue_new from './icons/check_blue_new.svg';
import white_lock from './icons/white_lock.svg';
import white_unlocked_lock from './active/white_unlocked_lock.svg';
import plain_arrow from './icons/plain_arrow.svg';
import pc from './icons/pc.svg';
import phone from './icons/phone.svg';
import book_yellow from './icons/book_yellow.svg';
import play_black from './active/play_black.svg';

import palette from './icons/palette.svg';
import field from './icons/field.svg';
import active_palette from './active/palette.svg';
import active_field from './active/field.svg';
import book from './icons/book.svg';
import active_book from './active/book.svg';

export type IconType =
  | 'analytics'
  | 'arrow_left'
  | 'arrow_right'
  | 'brand'
  | 'campaigns'
  | 'check'
  | 'cog'
  | 'filter'
  | 'integration'
  | 'logout'
  | 'members'
  | 'plan'
  | 'plus'
  | 'pencil'
  | 'plus_alt'
  | 'search'
  | 'sort'
  | 'trash'
  | 'info'
  | 'arrow_down'
  | 'caret'
  | 'white_check'
  | 'spinner'
  | 'trash_gray'
  | 'gamepad'
  | 'user'
  | 'users'
  | 'calendar'
  | 'collapse'
  | 'uncollapse'
  | 'timer'
  | 'blue_logout'
  | 'trophy'
  | 'download'
  | 'arrow_right_blue'
  | 'help'
  | 'mail'
  | 'eye'
  | 'lock'
  | 'close'
  | 'trophy_black'
  | 'gamepad_black'
  | 'tour'
  | 'copy'
  | 'copy2'
  | 'blue_plus'
  | 'white_mail'
  | 'campaigns_blue_gradient'
  | 'trophy_blue_gradient'
  | 'gamepad_blue_gradient'
  | 'cog_blue_gradient'
  | 'check_ring'
  | 'arrow_right_circle'
  | 'graph'
  | 'close_black'
  | 'close_blue'
  | 'image'
  | 'plus_circle_blue'
  | 'share'
  | 'image_uploader'
  | 'images'
  | 'link'
  | 'screen'
  | 'screen_link'
  | 'text'
  | 'form'
  | 'social_media'
  | 'leaderboard'
  | 'devider'
  | 'help_triangle'
  | 'arrow_left_blue'
  | 'img'
  | 'crop'
  | 'bg'
  | 'upload'
  | 'eye_black'
  | 'video'
  | 'columns'
  | 'squared_plus'
  | 'squared_minus'
  | 'big_check'
  | 'speedometer'
  | 'mail_open'
  | 'cursor_click'
  | 'link_external'
  | 'page_layout'
  | 'cursor_button'
  | 'close_square'
  | 'blue_spinner'
  | 'rotate'
  | 'search_white'
  | 'chat'
  | 'chat_correct'
  | 'chat_question'
  | 'folder'
  | 'dots'
  | 'arrow_down_black'
  | 'folders'
  | 'mail_black'
  | 'pie_chart'
  | 'reload'
  | 'close_square_black'
  | 'check_blue'
  | 'edit_white'
  | 'info_yellow'
  | 'block'
  | 'play'
  | 'pause'
  | 'chevron_r'
  | 'chevron_l'
  | 'edit_category'
  | 'edit_image'
  | 'trash_gray_2'
  | 'pencil_grey'
  | 'yellow_users'
  | 'yellow_calendar'
  | 'black_lock'
  | 'white_upload'
  | 'coins'
  | 'coins_black'
  | 'coins_stack'
  | 'blue_users'
  | 'blue_calendar'
  | 'arrow_right_circle_blue'
  | 'link_black'
  | 'check_blue_new'
  | 'knowledge'
  | 'play_white'
  | 'white_lock'
  | 'plain_arrow'
  | 'pc'
  | 'phone'
  | 'palette'
  | 'field'
  | 'book_yellow'
  | 'book'
  | 'play_black';

type Props = {
  icon: IconType;
  className?: string;
  active?: boolean;
  name?: string;
  onClick?: (event: any) => void;
  onMouseOver?: () => void;
  onMouseLeave?: () => void;
};

type Icons = {
  [key in IconType]: string;
};

const inactiveIcons: Icons = {
  analytics,
  arrow_left,
  close,
  eye_black,
  caret,
  arrow_right,
  arrow_left_blue,
  pencil,
  collapse,
  uncollapse,
  blue_plus,
  brand,
  close_black,
  close_blue,
  calendar,
  gamepad,
  user,
  download,
  campaigns,
  check,
  check_ring,
  cog,
  filter,
  integration,
  logout,
  members,
  plan,
  plus,
  plus_alt,
  spinner,
  arrow_down,
  search,
  white_check,
  sort,
  users,
  info,
  trash_gray,
  trash,
  timer,
  blue_logout,
  trophy,
  arrow_right_blue,
  help,
  mail,
  eye,
  trophy_black,
  gamepad_black,
  image,
  tour,
  copy,
  copy2,
  lock,
  white_mail,
  campaigns_blue_gradient,
  trophy_blue_gradient,
  gamepad_blue_gradient,
  cog_blue_gradient,
  arrow_right_circle,
  graph,
  plus_circle_blue,
  form,
  social_media,
  leaderboard,
  devider,
  share,
  images,
  link,
  screen,
  screen_link,
  text,
  image_uploader,
  help_triangle,
  img,
  crop,
  bg,
  upload,
  video,
  columns,
  squared_minus,
  squared_plus,
  big_check,
  cursor_click,
  cursor_button,
  speedometer,
  mail_open,
  link_external,
  page_layout,
  close_square,
  blue_spinner,
  rotate,
  search_white,
  chat,
  chat_correct,
  chat_question,
  folder,
  dots,
  arrow_down_black,
  folders,
  mail_black,
  pie_chart,
  info_yellow,
  reload,
  close_square_black,
  check_blue,
  edit_white,
  block,
  play,
  pause,
  chevron_r,
  chevron_l,
  edit_image,
  edit_category,
  trash_gray_2,
  pencil_grey,
  yellow_calendar,
  yellow_users,
  black_lock,
  white_upload,
  coins,
  coins_stack,
  blue_users,
  blue_calendar,
  arrow_right_circle_blue,
  link_black,
  check_blue_new,
  knowledge,
  play_white,
  white_lock,
  coins_black,
  plain_arrow,
  pc,
  phone,
  palette,
  field,
  book_yellow,
  book,
  play_black
};

const activeIcons: Icons = {
  ...inactiveIcons,
  analytics: active_analytics,
  eye_black: active_eye_black,
  arrow_left: active_arrow_left,
  arrow_left_blue: active_arrow_left_blue,
  close: active_close,
  arrow_right: active_arrow_right,
  brand: active_brand,
  campaigns: active_campaigns,
  check: active_check,
  check_ring: active_check_ring,
  pencil: active_pencil, //todo change to correct svg
  cog: active_cog,
  filter: active_filter,
  trash_gray: active_trash_gray,
  integration: active_integration,
  logout: active_logout,
  members: active_members,
  plan: active_plan,
  image: active_image,
  copy: active_copy,
  copy2: active_copy2,
  plus: active_plus,
  caret: active_caret,
  plus_alt: active_plus_alt,
  search: active_search,
  sort: active_sort,
  trash: active_trash,
  info: active_info,
  arrow_down: active_arrow_down,
  spinner: active_spinner,
  white_check: active_white_check,
  gamepad: active_gamepad,
  user: active_user,
  users: active_users,
  collapse: active_collapse,
  uncollapse: active_uncollapse,
  timer: active_timer,
  blue_logout: active_blue_logout,
  trophy: active_trophy,
  download: active_download,
  calendar: active_calendar,
  arrow_right_blue: active_arrow_right_blue,
  help: active_help,
  mail: active_mail,
  eye: active_eye,
  lock: active_lock,
  trophy_black: active_trophy_black,
  gamepad_black: active_gamepad_black,
  tour: active_tour,
  blue_plus: active_blue_plus,
  white_mail: active_white_mail,
  campaigns_blue_gradient: active_campaigns_blue_gradient,
  trophy_blue_gradient: active_trophy_blue_gradient,
  gamepad_blue_gradient: active_gamepad_blue_gradient,
  cog_blue_gradient: active_cog_blue_gradient,
  arrow_right_circle: active_arrow_right_circle,
  graph: active_graph,
  close_black: active_close_black,
  close_blue: active_close_blue,
  plus_circle_blue: active_plus_circle_blue,
  share: active_share,
  images: active_images,
  link: active_link,
  screen: active_screen,
  screen_link: active_screen_link,
  text: active_text,
  form: active_form,
  social_media: active_social_media,
  leaderboard: active_leaderboard,
  devider: active_devider,
  image_uploader: active_image_uploader,
  help_triangle: active_help_triangle,
  img: active_img,
  crop: active_crop,
  bg: active_bg,
  upload: active_upload,
  video: active_video,
  columns: active_columns,
  squared_minus: active_squared_minus,
  squared_plus: active_squared_plus,
  big_check: active_big_check,
  cursor_click: active_cursor_click,
  cursor_button: active_cursor_button,
  speedometer: active_speedometer,
  mail_open: active_mail_open,
  link_external: active_link_external,
  page_layout: active_page_layout,
  close_square: active_close_square,
  blue_spinner: active_blue_spinner,
  rotate: active_rotate,
  search_white: active_search_white,
  chat,
  chat_correct,
  chat_question,
  folder: active_folder,
  dots: active_dots,
  arrow_down_black: active_arrow_down_black,
  folders: active_folders,
  mail_black: active_mail_black,
  pie_chart,
  info_yellow,
  reload,
  close_square_black,
  check_blue,
  edit_white,
  block: active_block,
  play,
  pause,
  chevron_r,
  chevron_l,
  edit_image: active_edit_image,
  edit_category: active_edit_category,
  trash_gray_2: active_trash_gray_2,
  pencil_grey: pencil,
  yellow_calendar,
  yellow_users,
  black_lock: yellow_unlocked_lock,
  white_upload,
  coins,
  coins_stack,
  blue_users: active_blue_users,
  blue_calendar: active_blue_calendar,
  arrow_right_circle_blue,
  link_black,
  check_blue_new,
  white_lock: white_unlocked_lock,
  palette: active_palette,
  field: active_field,
  book: active_book
};

const Icon: FunctionComponent<Props> = ({ icon, name, onMouseOver, onMouseLeave, onClick, className, active }) => {
  useEffect(() => {
    const img = new Image();
    img.src = inactiveIcons[icon];
    const activeImg = new Image();
    activeImg.src = activeIcons[icon];
  }, [icon]);

  const icons: Icons = useMemo(() => (active ? activeIcons : inactiveIcons), [active]);

  return (
    <span
      onMouseOver={onMouseOver}
      cypress-id={`${name || icon}-icon`}
      onMouseLeave={onMouseLeave}
      role={onClick ? 'button' : undefined}
      onClick={onClick}
      className={cx(styles.icon, className, { [styles.clickable]: Boolean(onClick) })}
    >
      <img src={icons[icon]} alt={icon} draggable={false} />
    </span>
  );
};

export const preloadIcon = (icon: IconType) => {
  const img = new Image();
  img.src = inactiveIcons[icon];
  const activeImg = new Image();
  activeImg.src = activeIcons[icon];
};

export const preloadIcons = (icons: IconType[]) => {
  icons.map(preloadIcon);
};

export default Icon;
