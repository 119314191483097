import React, { FunctionComponent } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { BrandFont } from '../../../store/ui/types';
import useFonts from '../../../domain/useFonts';
import styles from './rte.module.css';

type Props = {
  initialValue: string;
  value: string;
  className?: string;
  onBlur?: () => void;
  onChange: (value: string) => void;
  defaultStyles?: DefaultFontStyle[];
  disabled?: boolean;
  name?: string;
  height?: number;
};

type DefaultFontStyle = {
  type: string;
  color: string;
  font: string;
  fontSize: number;
};

const RTE: FunctionComponent<Props> = ({
  initialValue,
  className,
  defaultStyles,
  onChange,
  onBlur,
  value,
  disabled,
  name,
  height = 500
}) => {
  const { rteFonts, brandFonts, rteFontSizes } = useFonts();

  const brandFontsCss = brandFonts
    .map(
      (font: BrandFont) => `
    @font-face {
       font-family: "${font.title}";
       src: url("${font.url}");
      }
    `
    )
    .join('\n');

  const defaultFontStyles = defaultStyles
    ?.map(
      (text: DefaultFontStyle) => `
    ${text.type} {
      font-family: ${text.font};
      font-size: ${text.fontSize}px;
      color: ${text.color};
    }
  `
    )
    .join('\n');

  const css = [brandFontsCss, defaultFontStyles].join('\n');

  return (
    <div cypress-id={`${name}-component`} className={className}>
      <Editor
        initialValue={initialValue}
        value={value}
        onBlur={onBlur}
        apiKey="0j3udvdhmlzkj9ivr9179crpq03s3i2t75ea06dxobe7ingn"
        onEditorChange={onChange}
        disabled={disabled}
        init={{
          fontsize_formats: rteFontSizes,
          menubar: false,
          font_formats: rteFonts,
          body_class: styles.rte,
          height,
          resize: false,
          content_style: css,
          plugins: 'textcolor link code',
          toolbar_mode: 'wrap',
          toolbar:
            'fontselect | bold italic | forecolor | backcolor | formatselect | fontsizeselect | lineheight | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | link | code | short-codes',
          link_title: false,
          default_link_target: '_blank',
          link_assume_external_targets: 'https',
          setup: (editor) => {
            editor.ui.registry.addToggleButton('short-codes', {
              text: 'Short codes',
              onAction: (api) => {
                api.setActive(!api.isActive());
              }
            });
          }
        }}
      />
    </div>
  );
};

export default RTE;
