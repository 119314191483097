import I, { ImmutableObject } from 'seamless-immutable';
import { CampaignsStoreEnum } from './types';
import { Pagination, ResourceMeta } from '../types';
import { GameTypes } from '../../utils/enums/games/game-types.enum';
import {
  AdventCalendarAction,
  ButtonsAndTraffics,
  CampaignActivity,
  CampaignBrand,
  CampaignWithAnalytics,
  DailyHourStatistic,
  DailyStatistic,
  EmailDailyStatistic,
  EmailStatistic,
  EmailTemplateStatistic,
  Gameplay,
  GameplayPlayer,
  LinkStatistic,
  ListCampaign,
  ListCampaignGame,
  ListCampaignStatus,
  NewsPost,
  PlayerActivity,
  TopData,
  UpdatedNowBy
} from '../../utils/type';

export const campaignsStoreAdapterMapping: Partial<Record<CampaignsStoreEnum, (data: any) => ImmutableObject<any>>> = {
  [CampaignsStoreEnum.EMAIL_STATISTICS]: emailStatisticsAdapter,
  [CampaignsStoreEnum.DAILY_EMAIL_STATISTICS]: dailyEmailStatisticsAdapter
};

export function templateStatistic(data: EmailTemplateStatistic): EmailTemplateStatistic {
  return {
    templateId: data.templateId || '',
    sent: Number(data.sent),
    delivered: Number(data.delivered),
    failed: Number(data.failed),
    opened: Number(data.opened),
    clicked: Number(data.clicked),
    uniqueClicked: Number(data.uniqueClicked),
    uniqueOpened: Number(data.uniqueOpened)
  };
}

export function linkStatistic(data: LinkStatistic): LinkStatistic {
  return {
    _id: data._id,
    url: data.url || '',
    clicked: Number(data.clicked),
    uniqueClicked: Number(data.uniqueClicked),
    templateId: data.templateId
  };
}

export function emailStatisticsAdapter(data?: EmailStatistic): ImmutableObject<EmailStatistic> {
  return I({
    _id: data?._id || null,
    campaignId: data?.campaignId || null,
    accountId: data?.accountId || null,
    sent: data?.sent || 0,
    delivered: data?.delivered || 0,
    failed: data?.failed || 0,
    opened: data?.opened || 0,
    clicked: data?.clicked || 0,
    linksStatistic: data?.linksStatistic.map(linkStatistic) || []
  });
}

export function dailyEmailStatisticsAdapter(data?: EmailDailyStatistic): ImmutableObject<EmailDailyStatistic> {
  return I({
    _id: data?._id || null,
    campaignId: data?.campaignId || null,
    accountId: data?.accountId || null,
    sent: data?.sent || 0,
    delivered: data?.delivered || 0,
    failed: data?.failed || 0,
    opened: data?.opened || 0,
    clicked: data?.clicked || 0,
    date: data?.date || '',
    templateStatistic: (data?.templateStatistic || []).map(templateStatistic)
  });
}

export function updatedNowByAdapter(data?: UpdatedNowBy) {
  return I({
    email: data?.email || '',
    fullName: data?.fullName || '',
    role: data?.role || ''
  });
}

function campaignStatusAdapter(data: ListCampaignStatus) {
  return I({
    title: data.title
  });
}

function campaignGameAdapter(data: ListCampaignGame) {
  return I({
    id: data.id,
    image: data.image,
    title: data.title,
    gameType: {
      type: data.gameType?.type ?? GameTypes.RNG
    },
    settings_type: data.settings_type
  });
}

function campaignBrandAdapter(data: CampaignBrand) {
  return I({
    slug: data.slug,
    id: data.id
  });
}

export function campaignAdapter(data: ListCampaign) {
  return I({
    ...data,
    brand: campaignBrandAdapter(data.brand),
    game: campaignGameAdapter(data.game),
    updatedNowBy: updatedNowByAdapter(data.updatedNowBy),
    status: campaignStatusAdapter(data.status),
    connectedCampaignId: data.connectedCampaignId
  });
}

export function campaignsMetaAdapter(data?: ResourceMeta) {
  return I({
    currentPage: data?.currentPage || 0,
    itemCount: data?.itemCount || 0,
    itemsPerPage: data?.itemsPerPage || 10,
    totalItems: data?.totalItems || 0,
    totalPages: data?.totalPages || 0
  });
}

export function campaignOneAnalyticAdapter(data: CampaignWithAnalytics) {
  return I({
    ...data,
    brand: campaignBrandAdapter(data.brand),
    game: campaignGameAdapter(data.game),
    status: campaignStatusAdapter(data.status),
    updatedNowBy: updatedNowByAdapter(data.updatedNowBy),
    game_config: JSON.parse(data.game_config as unknown as string),
    connectedCampaignId: data.originalCampaignId
  });
}

export function playerActivityAdapter(data: PlayerActivity) {
  return I({
    count: data.count,
    date: data.date
  });
}

export function playersActivityAdapter(data: PlayerActivity[]) {
  return I(data.map(playerActivityAdapter));
}

export function gameplayPlayerAdapter(data?: GameplayPlayer) {
  if (data) {
    return I(data);
  }
  return I({
    campaignId: '',
    createdAt: '',
    email: '',
    extra: '',
    fullName: '',
    phoneNumber: '',
    termsAccepted: false,
    updatedAt: '',
    _id: ''
  });
}

export const newsPostAdapter = (data?: NewsPost) => {
  if (data) {
    return I(data);
  }
  return I({
    id: '',
    created_at: '',
    title: '',
    description: '',
    image: '',
    link: ''
  });
};

export function gameplayAdventCalendarActionAdapter(data?: AdventCalendarAction) {
  return I({
    value: data?.value || '',
    date: data?.date || '',
    dayNumber: data?.dayNumber || 1,
    dayType: data?.dayType || '',
    info: data?.info || ''
  });
}

export function gameplayAdapter(data: Gameplay) {
  return I({
    ...data,
    player: gameplayPlayerAdapter(data.player || data.playerInfo),
    adventCalendarAction: gameplayAdventCalendarActionAdapter(data.adventCalendarAction),
    cheatingReason: data.cheatingReason || ''
  });
}

export function buttonAndTrafficsAdapter(data?: ButtonsAndTraffics) {
  if (data) {
    return I(data);
  }
  return I({
    trafficIn: [],
    trafficOut: [],
    clickedButtons: []
  });
}

export function gameplaysAdapter(data: Gameplay[]) {
  return I(data.map(gameplayAdapter));
}

export function activitiesAdapter(data?: Pagination<CampaignActivity>) {
  if (data) {
    return I({
      meta: campaignsMetaAdapter(data.meta),
      items: data.items
    });
  }
  return I({
    meta: {
      currentPage: 0,
      itemCount: 0,
      itemsPerPage: 10,
      totalItems: 0,
      totalPages: 0
    },
    items: []
  });
}

export function topAdapter(data?: TopData) {
  if (data) {
    return I({
      score: data.score.players,
      gameplay: data.gameplay.players,
      avgScore: data.score.avg,
      avgGameplay: data.gameplay.avg
    });
  }
  return I({
    score: [],
    gameplay: []
  });
}

export function dailyStatisticAdapter(data: DailyStatistic) {
  return I({
    date: data.date,
    gameDurationTotal: data.gameDurationTotal || 0,
    gameEndedCount: data.gameEndedCount || 0,
    gameStartedCount: data.gameStartedCount || 0,
    gameplayCount: data.gameplayCount || 0,
    gameplayFinishedCount: data.gameplayFinishedCount || 0,
    uniqueLeadCount: data.uniqueLeadCount || 0,
    uniqueVisitorCount: data.uniqueVisitorCount || 0,
    visitorCount: data.visitorCount || 0,
    _id: data._id
  });
}

export function dailyStatisticsAdapter(data: DailyStatistic[]) {
  return I(data.map(dailyStatisticAdapter));
}

export function hoursStatisticAdapter(data: DailyHourStatistic[]) {
  return I(data.map(hourStatisticAdapter));
}
export function hourStatisticAdapter(data: DailyHourStatistic) {
  return {
    hour: data.hour,
    data: {
      visitorCount: data.data.visitorCount || 0,
      uniqueVisitorCount: data.data.uniqueVisitorCount || 0,
      gameplayCount: data.data.gameplayCount || 0,
      gameDurationTotal: data.data.gameDurationTotal || 0,
      gameStartedCount: data.data.gameStartedCount || 0,
      gameEndedCount: data.data.gameEndedCount || 0,
      gameplayFinishedCount: data.data.gameplayFinishedCount || 0,
      uniqueLeadCount: data.data.uniqueLeadCount || 0
    }
  };
}

export function initialStoreAdapter() {
  return I({
    list: I([]),
    loaded: false,
    oneTop: topAdapter(),
    news: I({
      items: I([]),
      meta: {}
    }),
    statuses: [],
    oneDailyStatistics: [],
    oneDayStatistics: [],
    activities: activitiesAdapter(),
    playersActivity: I([]),
    meta: campaignsMetaAdapter(),
    gameplays: I({
      items: I([]),
      meta: {}
    }),
    buttonsAndTraffics: buttonAndTrafficsAdapter(),
    one: I({}),
    [CampaignsStoreEnum.DAILY_EMAIL_STATISTICS]: [],
    [CampaignsStoreEnum.EMAIL_STATISTICS]: emailStatisticsAdapter(),
    [CampaignsStoreEnum.LEADERBOARD_DATA]: I({
      items: I([]),
      meta: {}
    }),
    [CampaignsStoreEnum.RESULTS_DATA]: I({
      images: I([]),
      gameplayCount: 0
    })
  });
}
