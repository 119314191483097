import React, { CSSProperties, FunctionComponent } from 'react';
import cx from 'classnames';
import styles from './typo.module.css';

interface Props {
  type: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'small';
  children: any | any[];
  size?: number;
  semi?: boolean;
  gray?: boolean;
  white?: boolean;
  bold?: boolean;
  inter?: boolean;
  className?: string;
  ref?: any;
  style?: CSSProperties;
}

const Typo: FunctionComponent<Props> = (props) => {
  const { type, children, className, size, bold, gray, inter, white, semi, ref, style } = props;
  return React.createElement(type, {
    children: children,
    style: { fontSize: size, ...style },
    className: cx(styles.typo, styles[type], className, {
      [styles.semi]: semi,
      [styles.white]: white,
      [styles.bold]: bold,
      [styles.gray]: gray,
      [styles.inter]: inter
    }),
    ref
  });
};

export default Typo;
