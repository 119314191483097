import React, {
  ChangeEvent,
  CSSProperties,
  FunctionComponent,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import cx from 'classnames';
import WhiteField from '../field/white';
import Icon from '../../icon';
import styles from './autocomplete.module.css';
import { debounce } from 'lodash';

export type Option = {
  value: any;
  label: any | ReactElement;
  activeLabel?: any | ReactElement;
};

type Props = {
  className?: string;
  inputClassName?: string;
  label?: string;
  name: string;
  position?: 'bottom' | 'top';
  value: any[];
  placeholder?: string;
  onLoad?: (search: string) => void;
  disabled?: boolean;
  inline?: boolean;
  tagSize?: 'md' | 'xs';
  required?: boolean;
  errors?: string[];
  onChange: (value: any[]) => void;
  onSelect?: (option: Option) => void;
  onClear?: () => void;
  options: Option[];
  overflow?: boolean;
};

const WhiteTags: FunctionComponent<Props> = (props) => {
  const {
    label,
    name,
    options,
    className,
    value,
    onChange,
    placeholder,
    inputClassName,
    disabled,
    errors,
    position = 'top',
    onLoad,
    tagSize = 'xs',
    inline,
    overflow
  } = props;
  const [show, onChangeShow] = useState<boolean>(false);
  const currentOption = useMemo(() => options.find((item: Option) => item.value === value), [options, value]);
  const [search, onChangeSearch] = useState<string>(currentOption?.label || '');
  const uniqName = useMemo(() => `adact-white-select-${name.replaceAll(/[^a-zA-Z0-9]/g, '')}`, [name]);
  const input = useRef<HTMLDivElement>(null);
  const [overflowStyles, setOverflowStyles] = useState<CSSProperties>();

  useEffect(() => {
    document.addEventListener('click', handleClick, true);
    return () => {
      document.removeEventListener('click', handleClick, true);
    };
  }, [show]);

  const makeDebouncedRequest = useCallback(
    debounce(
      (search: string) => {
        if (onLoad) onLoad(search);
      },
      500,
      { trailing: true }
    ),
    [onLoad]
  );

  useEffect(() => {
    if (!onLoad) return;
    if (search !== '') makeDebouncedRequest(search);
  }, [search]);

  useEffect(() => {
    const currentOption = options.find((item: Option) => item.value === value);
    onChangeSearch(currentOption?.label || '');
  }, [value]);

  const handleClick = useCallback(
    (event) => {
      const inSelect = event.target?.closest(`.${uniqName}`);
      if (show && !inSelect) {
        onChangeShow(false);
      }
      return;
    },
    [show, uniqName]
  );

  const handleShow = useCallback(() => {
    onChangeShow(true);
  }, [onChangeShow]);

  const onClick = useCallback(
    (tag: any) => {
      onChange([...value, tag]);
      onChangeShow(false);
    },
    [onChange, onChangeShow]
  );

  const suggestedOptions = useMemo(
    () =>
      options
        .filter((item: Option) => !value.includes(item.value))
        .filter((item: Option) => {
          const transformedSearch = search.replace(/ /g, '').toLowerCase();
          const transformedLabel = item.label.replace(/ /g, '').toLowerCase();
          return transformedLabel.includes(transformedSearch);
        }),
    [search, value, options]
  );

  const onRemove = useCallback(
    (tag: any) => {
      onChange(value.filter((item: any) => item !== tag));
    },
    [value, onChange]
  );

  const calcOffset = () => {
    const rect = input.current?.getBoundingClientRect();
    if (rect) {
      setOverflowStyles({
        left: rect.x,
        position: 'fixed',
        top: rect.y + rect.height,
        width: rect.width,
        minWidth: rect.width
      });
    }
  };

  useEffect(() => {
    if (overflow) {
      calcOffset();
    }
  }, [overflow, show, value]);

  const tags = useMemo(() => {
    const filteredOptions = options.filter((item: Option) => value.includes(item.value));
    return filteredOptions.map((item: Option, idx: number) => (
      <span className={cx(styles.tag, styles[tagSize])} role="button" key={idx}>
        {item.label} <Icon className={styles.closeIcon} onClick={() => onRemove(item.value)} icon="close" />
      </span>
    ));
  }, [options, value, tagSize]);

  const tagsBody = useMemo(
    () =>
      tags.length > 0 ? (
        <div className={cx(styles.tagsWrapper, { [styles.bottom]: position === 'bottom' })}>{tags}</div>
      ) : null,
    [tags, position]
  );

  return (
    <WhiteField name={name} label={label} errors={errors} inline={inline} className={className}>
      <div ref={input} className={cx(styles.select, inputClassName, uniqName, { [styles.active]: show })}>
        {position === 'top' ? tagsBody : null}
        <div className={styles.searchWrapper}>
          <Icon className={styles.icon} active={show} icon="search" />
          <input
            cypress-id={`${name}-component`}
            placeholder={placeholder}
            onFocus={handleShow}
            disabled={disabled}
            onChange={(event: ChangeEvent<HTMLInputElement>) => onChangeSearch(event.target.value)}
            value={search}
            className={cx(styles.search, 'adact-autocomplete-tags', { [styles.hasError]: errors })}
          />
        </div>
        {show ? (
          <div className={styles.listWrapper} style={overflowStyles}>
            <ul>
              {suggestedOptions.length > 0 ? (
                suggestedOptions.map((item: Option, idx: number) => (
                  <li role="button" key={idx} onClick={() => onClick(item.value)}>
                    {item.label}
                  </li>
                ))
              ) : (
                <li role="button" className={styles.empty} key={`${name}-empty-line`}>
                  No suggestions...
                </li>
              )}
            </ul>
          </div>
        ) : null}
        {position === 'bottom' ? tagsBody : null}
      </div>
    </WhiteField>
  );
};

export default WhiteTags;
