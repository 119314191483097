import React, { FunctionComponent, ReactElement } from 'react';
import AntInput, { InputProps } from 'antd/lib/input';
import Field from '../field';
import cx from 'classnames';
import styles from './input.module.css';

interface Props extends InputProps {
  className?: string;
  label?: string;
  name: string;
  onBlurCallback?: () => void;
  onChange?: (event: any) => void;
  disabled?: boolean;
  refProp?: any;
  icon?: ReactElement;
  errors?: string[];
  newForm?: boolean;
}

const Input: FunctionComponent<Props> = (props) => {
  const {
    className,
    label,
    refProp,
    errors,
    icon,
    name,
    onBlurCallback,
    disabled,
    onBlur,
    newForm,
    onChange,
    ...other
  } = props;
  return (
    <Field className={className} label={label} errors={errors} disabled={disabled}>
      <div className={styles.wrapper}>
        {icon ? <span className={cx(styles.icon, { [styles.newIcon]: newForm })}>{icon}</span> : null}
        <AntInput
          {...other}
          name={name}
          onChange={onChange}
          className={cx({ [styles.withIcon]: icon, [styles.newForm]: newForm })}
          ref={refProp}
          disabled={disabled}
          onBlur={onBlurCallback ? onBlurCallback : onBlur}
          cypress-id={`${name}-component`}
        />
      </div>
    </Field>
  );
};

export default Input;
