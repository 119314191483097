import React, { Fragment, FunctionComponent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styles from '../../upload.module.css';
import cx from 'classnames';
import useUpload from '../../../../../domain/useUpload';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Spinner from '../../../../spinner';
import { safari } from '../../../../../utils/device';
import { message, Popover } from 'antd';
import WhiteButton from '../../../whiteButton';
import Typo from '../../../../typo';
import useCurrentUser from '../../../../../utils/hooks/useCurrentUser';

type Props = {
  fileUrl: string | ArrayBuffer | null;
  originalFile?: string | ArrayBuffer | null;
  onSubmit: (values: string, file: File, sizes: any) => void;
  maxSize: number;
  onCloseModal: () => void;
  isMultiAndEditing?: boolean;
};

const QUALITY = 0.8;

const BackgroundEditor: FunctionComponent<Props> = ({
  fileUrl,
  originalFile,
  onSubmit,
  maxSize,
  isMultiAndEditing = false,
  onCloseModal
}) => {
  const { removeBackground, removedBgImage, removedBgReady } = useUpload();
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const [spinner, onChangeSpinner] = useState<boolean>(false);
  const [image, onChangeImage] = useState<HTMLImageElement | null>(null);
  const [originalImage, onChangeOriginalImage] = useState<HTMLImageElement | null>(null);
  const [isDrawing, onChangeIsDrawing] = useState<boolean>(false);
  const [lastPoint, onChangeLastpoint] = useState<any>();
  const [action, onChangeAction] = useState<'erase' | 'restore'>('erase');
  const [editableFileUrl, onChangeEditableFileUrl] = useState<string | ArrayBuffer | null>(fileUrl);
  const { user, isAdmin } = useCurrentUser();

  useEffect(() => {
    onChangeEditableFileUrl(removedBgImage);
  }, [removedBgImage]);

  useEffect(() => {
    const imageElement = new Image();
    //@ts-ignore
    imageElement.src = editableFileUrl;
    imageElement.onload = () => {
      onChangeImage(imageElement);
    };
  }, [editableFileUrl]);

  useEffect(() => {
    const original = new Image();
    //@ts-ignore
    original.src = originalFile;
    original.onload = () => {
      onChangeOriginalImage(original);
    };
  }, [originalFile]);

  useEffect(
    () => {
      if (image && canvasRef.current) {
        image.width = canvasRef.current.width;
        image.height = canvasRef.current.height;
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [canvasRef.current, image]
  );

  const imageSizes = useCallback(
    (canvas: any) => {
      if (!image) return;
      const scaleX = image.naturalWidth / image.width;
      const scaleY = image.naturalHeight / image.height;
      const x = canvas.width * scaleX;
      const y = canvas.height * scaleY;
      return { scaleX, x, y, scaleY };
    },
    [image]
  );

  const renderCanvas = useCallback(
    () => {
      const canvas = canvasRef.current;
      if (canvas && image) {
        let ctx = canvas.getContext('2d');
        if (!ctx) return;

        ctx.globalCompositeOperation = 'destination-over';
        ctx.save();
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        ctx.restore();

        const sizes = imageSizes(canvas);
        if (!sizes) return;

        ctx.drawImage(
          image,
          0,
          0,
          canvas.width * sizes.scaleX,
          canvas.height * sizes.scaleY,
          0,
          0,
          canvas.width * sizes.scaleX,
          canvas.height * sizes.scaleY
        );
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [image, canvasRef.current, imageSizes]
  );

  useEffect(
    () => {
      renderCanvas();
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [canvasRef.current, image]
  );

  const getMouse = useCallback((event: any) => {
    const rect = event.target.getBoundingClientRect();
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;
    return { x, y };
  }, []);

  const handleMouseDown = useCallback(
    (event: any) => {
      onChangeIsDrawing(true);
      onChangeLastpoint(getMouse(event));
      const ctx = event.target.getContext('2d');
      if (action === 'erase') {
        ctx.globalCompositeOperation = 'destination-out';
      } else {
        ctx.globalCompositeOperation = 'destination-over';
      }
    },
    [action, getMouse]
  );

  const distanceBetween = useCallback((point1: any, point2: any) => {
    return Math.sqrt(Math.pow(point2.x - point1.x, 2) + Math.pow(point2.y - point1.y, 2));
  }, []);

  const handleMouseUp = useCallback(() => {
    onChangeIsDrawing(false);
  }, []);

  const angleBetween = useCallback((point1: any, point2: any) => {
    return Math.atan2(point2.x - point1.x, point2.y - point1.y);
  }, []);

  const handleMouseMove = useCallback(
    (event: any) => {
      if (!isDrawing || !image) return;
      event.preventDefault();

      const currentPoint = getMouse(event);
      const dist = distanceBetween(lastPoint, currentPoint);
      const angle = angleBetween(lastPoint, currentPoint);

      const ctx = event.target.getContext('2d');
      ctx.save();
      ctx.beginPath();
      ctx.translate(0, 0);

      const sizes = imageSizes(event.target);
      if (!sizes) return;

      for (let i = 0; i < dist; i++) {
        const x = lastPoint.x + Math.sin(angle) * i;
        const y = lastPoint.y + Math.cos(angle) * i;
        const arcScaleX = event.target.width / event.target.offsetWidth;
        const arcScaleY = event.target.height / event.target.offsetHeight;
        ctx.arc(
          x * arcScaleX,
          y * arcScaleY,
          Math.max(event.target.width, event.target.height) / 50,
          0,
          Math.PI * 2,
          false
        );
      }
      ctx.closePath();
      ctx.clip();
      if (action === 'restore') {
        const imageWidth = event.target.width * sizes.scaleX;
        const imageHeight = event.target.height * sizes.scaleY;
        ctx.drawImage(image, 0, 0, imageWidth, imageHeight, 0, 0, imageWidth, imageHeight);
      } else if (action === 'erase') {
        ctx.clearRect(0, 0, event.target.width, event.target.height);
      }
      ctx.restore();

      onChangeLastpoint(currentPoint);
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [isDrawing, getMouse, distanceBetween, angleBetween, lastPoint, imageSizes, originalImage, image]
  );

  useEffect(
    () => {
      const canvas = canvasRef.current;
      if (!canvas) return;
      canvas.addEventListener('mousedown', handleMouseDown, false);
      canvas.addEventListener('mousemove', handleMouseMove, false);
      canvas.addEventListener('mouseup', handleMouseUp, false);
      canvas.addEventListener('mouseout', handleMouseUp, false);
      return () => {
        canvas.removeEventListener('mousedown', handleMouseDown, false);
        canvas.removeEventListener('mousemove', handleMouseMove, false);
        canvas.removeEventListener('mouseup', handleMouseUp, false);
        canvas.removeEventListener('mouseout', handleMouseUp, false);
      };
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [canvasRef.current, handleMouseDown, handleMouseMove, handleMouseUp]
  );

  const submit = useCallback(
    () => {
      const canvas = canvasRef.current;

      if (!canvas) return;

      onChangeSpinner(true);
      const format = safari ? 'png' : 'webp';
      // todo canvas to file => webWorker
      const dataUrl = canvas.toDataURL(`image/${format}`, QUALITY);
      canvas.toBlob(
        (blob) => {
          onChangeSpinner(false);
          const file = new File([blob as BlobPart], `fileName.${format}`, { type: `image/${format}` });
          onSubmit(dataUrl, file, null);
        },
        `image/${format}`,
        QUALITY
      );
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [canvasRef.current]
  );

  const onClickRemoveBackground = useCallback(
    () => {
      const canvas = canvasRef.current;

      if (!canvas) return;

      canvas.toBlob((blob) => {
        const file = new File([blob as BlobPart], 'fileName.png', { type: 'image/png' });
        if (file.size > maxSize) {
          message.error(`File size is higher than ${maxSize / 1024}KB`);
          return;
        }
        removeBackground(file);
      }, 'image/png');
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [canvasRef.current, removeBackground, maxSize]
  );

  const backgroundRemovalButton = useMemo(
    () => (
      <WhiteButton
        className={styles.removeBgBtn}
        disabled={!isAdmin && !user.account.tierSettings.imageBgRemovalAllowed}
        onClick={onClickRemoveBackground}
      >
        Remove background
      </WhiteButton>
    ),
    [isAdmin, onClickRemoveBackground, user.account.tierSettings.imageBgRemovalAllowed]
  );

  const backgroundRemovalHint = useMemo(
    () => (
      <Fragment>
        <Typo className={styles.popoverhint} type="p">
          This option is not available for you now.{' '}
        </Typo>
        <Typo className={styles.popoverhint} type="p">
          Don't hesitate to contact our support to enable this option for your account if you need that.
        </Typo>
        <Typo className={styles.popoverhint} type="p">
          support@adact.me
        </Typo>
      </Fragment>
    ),
    []
  );
  return (
    <>
      <div className={styles.sidesWrapper}>
        <div className={styles.leftSide}>
          {!removedBgReady || spinner ? (
            <Spinner />
          ) : image ? (
            <div>
              <canvas
                key={image.src}
                className={styles.editorCanvas}
                style={{
                  width: '100%',
                  height: '100%'
                }}
                width={image.naturalWidth}
                height={image.naturalHeight}
                draggable={false}
                ref={canvasRef}
              />
            </div>
          ) : null}
        </div>
        <div className={styles.rightSide}>
          <div className={styles.removeBgActions}>
            <div className={styles.actionsWrapper}>
              <WhiteButton
                className={cx(styles.action, { [styles.active]: action === 'erase' })}
                onClick={() => onChangeAction('erase')}
              >
                Erase
              </WhiteButton>
              <WhiteButton
                className={cx(styles.action, { [styles.active]: action === 'restore' })}
                onClick={() => onChangeAction('restore')}
              >
                Restore
              </WhiteButton>
            </div>
            {!isAdmin && !user.account.tierSettings.imageBgRemovalAllowed ? (
              <Popover placement="top" content={backgroundRemovalHint}>
                <div>{backgroundRemovalButton}</div>
              </Popover>
            ) : (
              backgroundRemovalButton
            )}
          </div>
          <div className={styles.btnsWrapper}>
            <WhiteButton type="ghost" onClick={renderCanvas}>
              <>
                <FontAwesomeIcon className={styles.arrow} icon={faArrowLeft} />
                Original
              </>
            </WhiteButton>
            {!isMultiAndEditing && <WhiteButton onClick={submit}>Apply</WhiteButton>}
          </div>
        </div>
      </div>
      <div className={styles.footerWrapper}>
        <div className={styles.footer}>
          <WhiteButton size="lg" className={styles.footerBtn} type="ghost" onClick={onCloseModal}>
            {isMultiAndEditing ? 'Back' : 'Cancel'}
          </WhiteButton>
          <WhiteButton size="lg" className={styles.footerBtn} onClick={submit}>
            Apply
          </WhiteButton>
        </div>
      </div>
    </>
  );
};

export default BackgroundEditor;
