import { useCallback, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { isEqual, omit } from 'lodash';
import { message, Modal, notification } from 'antd';
import axios, { AxiosError, AxiosResponse, Canceler } from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import useApi from './useApi';
import {
  addCampaignToHistory,
  clearCampaign,
  clearCampaignHistory,
  removeLastFromHistory,
  setCampaign,
  setCampaignInputList,
  setCampaignWithS3,
  setEditingTemplate,
  setField
} from '../store/campaign/actions';
import {
  campaignBackgroundSelector,
  campaignContentSelector,
  campaignGameAreaSelector,
  campaignGameConfigSelector,
  campaignHistorySelector,
  campaignInputListSelector,
  campaignNotificationsConfigSelector,
  campaignSelector,
  editingTemplateSelector,
  embedOptionsConfigsSelector,
  errorsConfigSelector,
  integrationsConfigsSelector,
  leaderBoardConfigsSelector,
  privacyPolicyConfigsSelector,
  sideBarConfigsSelector,
  tacConfigsSelector,
  updatedNowBySelector
} from '../store/campaign/selectors';
import { userSelector } from '../store/auth/selectors';
import { setBrandHasCampaignsTrue } from '../store/auth/actions';
import { decreaseSpinner, increaseSpinner, setRestrictionModalText, showRestrictionModal } from '../store/ui/actions';
import errorParser, { Errors } from '../utils/errors';
import * as T from './requestTypes';
import { ScreenTypes, UserRoles } from '../utils/enums';
import useAdventCalendarHelper, { NormalizedElements } from '../utils/useAdventCalendarHelper';
import { useCampaignGameType } from '../utils/hooks';
import { Campaign, CampaignIntegrations, CampaignTemplate, UpdatedNowBy } from '../utils/type';
import useBrandCampaignTemplates from './brand/useBrandCampaignTemplates';
import { CampaignStoreEnum } from '../store/campaign/types';

export type Screen = {
  key: 'classic' | 'before_game' | 'after_game' | 'only_game' | 'after_only_game' | 'before_and_after_game' | string;
  label: string;
  description: string;
  disabled: boolean;
  list: Partial<ScreenTypes>[];
};

interface iCampaign {
  create: (data: T.CreateCampaignData) => void;
  getCampaign: (id: number, template?: boolean) => void;
  undo: () => void;
  clearHistory: () => void;
  canUndo: boolean;
  clear: () => void;
  update: (
    id: number | null,
    data: any,
    callback?: (data: Campaign) => void,
    forceUpdate?: boolean,
    silent?: boolean,
    skipEqualCheck?: boolean
  ) => void;
  onDelete: (id: number) => void;
  campaign: Campaign;
  campaignBackground: any;
  campaignGameArea: any;
  updatedNowBy: UpdatedNowBy;
  editingTemplate: boolean;
  setCampaignTemplateAsCampaign: (template: CampaignTemplate) => void;
  campaignContent: any;
  gameSettings: any;
  notificationsSettings: any;
  errors: Errors;
  publishChanges: (id: number) => void;
  tac: any;
  privacy_policy: any;
  custom_errors: any;
  leaderboard: any;
  embed_options_config: Record<string, any>;
  side_bar_config: Record<string, any>;
  integrationsSettings: CampaignIntegrations;
  getCampaignInputList: () => void;
  campaignInputList: string[];
  wizardSetupSubmit: (id: number, data: any) => void;
  busy: boolean;
  lockDraftCampaign: (id: number) => Promise<boolean>;
}

let cancel: Canceler | undefined = undefined;
const useCampaign = (): iCampaign => {
  const history = useHistory();
  const dispatch = useDispatch();
  const campaign = useSelector(campaignSelector);
  const gameSettings = useSelector(campaignGameConfigSelector);
  const integrationsSettings = useSelector(integrationsConfigsSelector);
  const campaignBackground = useSelector(campaignBackgroundSelector);
  const campaignGameArea = useSelector(campaignGameAreaSelector);
  const updatedNowBy = useSelector(updatedNowBySelector);
  const campaignContent = useSelector(campaignContentSelector);
  const notificationsSettings = useSelector(campaignNotificationsConfigSelector);
  const campaignInputList = useSelector(campaignInputListSelector);
  const editingTemplate = useSelector(editingTemplateSelector);
  const tac = useSelector(tacConfigsSelector);
  const embed_options_config = useSelector(embedOptionsConfigsSelector);
  const side_bar_config = useSelector(sideBarConfigsSelector);
  const privacy_policy = useSelector(privacyPolicyConfigsSelector);
  const custom_errors = useSelector(errorsConfigSelector);
  const user = useSelector(userSelector);
  const leaderboard = useSelector(leaderBoardConfigsSelector);
  const api = useApi();
  const historyCampaigns = useSelector(campaignHistorySelector);
  const [busy, onChangeBusy] = useState<boolean>(false);
  const [errors, onChangeErrors] = useState<Errors>({});
  const { landingPageElementsHelper } = useAdventCalendarHelper(campaign);
  const { updateCampaignTemplate } = useBrandCampaignTemplates();

  const isAdmin = useMemo(() => [UserRoles.ADMIN, UserRoles.SUPER_ADMIN].includes(user.role), [user.role]);

  const { isAdventGame } = useCampaignGameType(campaign);

  const create = (data: T.CreateCampaignData) => {
    dispatch(clearCampaign());
    dispatch(increaseSpinner());
    api
      .createCampaign(data)
      .then((response: AxiosResponse) => {
        dispatch(decreaseSpinner());
        dispatch(setBrandHasCampaignsTrue(data.brand));
        history.push(`/campaigns/${response.data?.id}/game`);
      })
      .catch((error: AxiosError) => {
        dispatch(decreaseSpinner());
        if (error.response?.status === 409) {
          dispatch(showRestrictionModal());
          dispatch(setRestrictionModalText(error.response.data?.message || ''));
          return;
        }
        notification.error({
          message: 'Error',
          description: 'Something went wrong'
        });
      });
  };

  const getCampaign = (id: number, template: boolean = false) => {
    const func = isAdmin ? api.getAdminCampaign : api.getCampaign;
    dispatch(increaseSpinner());
    if (!template) {
      dispatch(setEditingTemplate(false));
    }
    func(id)
      .then((response: AxiosResponse) => {
        dispatch(setCampaignWithS3(response.data));
        dispatch(decreaseSpinner());
      })
      .catch((error: AxiosError) => {
        dispatch(decreaseSpinner());
        if (error.response?.status === 404) {
          history.push('/campaigns');
          notification.error({
            message: 'Error',
            description: 'Campaign not found'
          });
        }
      });
  };

  const getCampaignInputList = () => {
    if (campaign.id) {
      api.getCampaignInputList(campaign.id).then((response: AxiosResponse) => {
        dispatch(setCampaignInputList(response.data.inputNames));
      });
    }
  };

  const wizardSetupSubmit = (id: number, data: any) => {
    onChangeBusy(true);
    api
      .updateCampaignFromScreenSetupWizard(id, data)
      .then((response: AxiosResponse) => {
        onChangeBusy(false);
        dispatch(setCampaign(omit(response.data, 'updatedNowBy')));
        message.success('Campaign was updated');
      })
      .catch((error: AxiosError) => {
        onChangeBusy(false);
        if (error.response?.status === 422) {
          const errors = errorParser(error.response?.data);
          notification.error({
            message: 'Error',
            description: 'Validation error'
          });
          onChangeErrors(errors);
        }
        dispatch(removeLastFromHistory());
        if (error.response?.status === 400) {
          notification.error({
            message: 'Error',
            description: error.response?.data?.message
          });
        }
      });
  };

  const update = (
    id: number | null,
    data: Partial<Campaign>,
    callback?: (data: Campaign) => void,
    forceUpdate?: boolean,
    silent?: boolean,
    skipEqualCheck?: boolean
  ) => {
    if (!id) return;

    if (
      !skipEqualCheck &&
      data.landingPageScreenElements &&
      isEqual(data.landingPageScreenElements, campaign.landingPageScreenElements)
    ) {
      return;
    }

    if (cancel) {
      cancel();
    }

    if (data.landingPageScreenElements && isAdventGame) {
      const elements: NormalizedElements = landingPageElementsHelper(data.landingPageScreenElements);
      if (elements.forceUpdate) {
        forceUpdate = true;
      }
      data.landingPageScreenElements = elements.elements;
    }

    let newCampaignData = {
      ...campaign,
      ...data
    };

    if (editingTemplate) {
      dispatch(setCampaign({ ...newCampaignData, updated_at: new Date().getTime() }));
      if (!isAdmin) {
        updateCampaignTemplate(campaign.brand.id as number, id, omit(newCampaignData, 'updated_at'));
      }
      return;
    }

    onChangeBusy(true);

    const currentCampaignHistory: Partial<Campaign> = Object.keys(data).reduce(
      (acc, key) => ({
        ...acc,
        [key]: campaign[key as keyof Campaign]
      }),
      { id: campaign.id }
    );

    dispatch(addCampaignToHistory(currentCampaignHistory));

    if (!forceUpdate) dispatch(setCampaign(newCampaignData));
    onChangeErrors({});
    const func = isAdmin ? api.updateAdminCampaign : api.updateCampaign;
    func(
      id,
      omit(data, 'updated_at'),
      new axios.CancelToken((canceler: Canceler) => {
        cancel = canceler;
      })
    )
      .then((response: AxiosResponse) => {
        onChangeBusy(false);
        if (forceUpdate) {
          dispatch(setCampaign(omit(response.data, 'updatedNowBy')));
        }
        if (callback) {
          callback(response.data);
        }
        if (!silent) {
          message.success('Campaign was updated');
        }
      })
      .catch((error: AxiosError) => {
        onChangeBusy(false);
        if (error.response?.status === 422) {
          Modal.error({
            title:
              'There seems to be an error saving the campaign. Try refreshing the page. If you see this error again then write to “support@adact.me” or in the chatbox on the bottom right and we will fix it right away!'
          });
          onChangeErrors(errors);
        }
        dispatch(removeLastFromHistory());
        if (error.response?.status === 400 || error.response?.status === 409) {
          notification.error({
            message: 'Error',
            description: error.response?.data?.message
          });
        }
      });
  };

  const setCampaignTemplateAsCampaign = (template: CampaignTemplate) => {
    dispatch(setEditingTemplate(true));
    dispatch(setCampaign(template));
  };

  const undo = () => {
    if (historyCampaigns.length > 0) {
      const [last] = historyCampaigns.reverse();
      const correctLast = {
        ...last,
        ...(last.status ? { status: last.status.id } : {})
      };
      const func = isAdmin ? api.updateAdminCampaign : api.updateCampaign;
      //@ts-ignore
      func(last.id, omit(correctLast, 'updated_at')).then((response: AxiosResponse) => {
        dispatch(setCampaign(response.data));
        dispatch(removeLastFromHistory());
        message.success('Campaign was reverted');
      });
    }
  };

  const clear = () => {
    dispatch(clearCampaign());
  };

  const clearHistory = () => {
    dispatch(clearCampaignHistory());
  };

  const onDelete = (id: number) => {
    const func = isAdmin ? api.deleteAdminCampaign : api.deleteCampaign;
    func(id)
      .then(() => {
        const route = isAdmin ? '/admin/campaigns' : '/campaigns';
        history.push(route);
        notification.success({
          message: 'Info',
          description: 'Campaign was removed'
        });
      })
      .catch((error: AxiosError) => {
        if (error.response?.status === 400) {
          notification.error({
            message: 'Error',
            description: error.response?.data?.message
          });
        }
      });
  };

  const publishChanges = (id: number) => {
    api.publishCampaignChanges(id).then(() => {
      message.success('Changes were successfully published');
      dispatch(setField(campaign, CampaignStoreEnum.CACHED_CAMPAIGN));
    });
  };

  const lockDraftCampaign = useCallback(
    async (id: number): Promise<boolean> => {
      onChangeBusy(true);
      let success: boolean = true;
      try {
        await api.lockDraftCampaign(id);
        onChangeBusy(false);
      } catch (error) {
        success = false;
        notification.error({
          message: 'Error',
          description: 'Something went wrong'
        });
      }
      onChangeBusy(false);
      return success;
    },
    [api]
  );

  return {
    create,
    update,
    clear,
    clearHistory,
    gameSettings,
    campaignBackground,
    campaignGameArea,
    campaignContent,
    publishChanges,
    onDelete,
    updatedNowBy,
    undo,
    tac,
    privacy_policy,
    custom_errors,
    embed_options_config,
    errors,
    leaderboard,
    canUndo: historyCampaigns.length > 0,
    campaign,
    getCampaign,
    notificationsSettings,
    getCampaignInputList,
    campaignInputList,
    editingTemplate,
    setCampaignTemplateAsCampaign,
    wizardSetupSubmit,
    busy,
    integrationsSettings,
    lockDraftCampaign,
    side_bar_config
  };
};

export default useCampaign;
