type Message = {
  [key: string]: string[];
};

type ErrorResponse = {
  error: string;
  statusCode: number;
  message: Message[];
};

export type Errors = {
  [key: string]: string[];
};

const errorParser = (error: ErrorResponse): Errors => {
  const errors = error.message;
  return errors.reduce((result: Errors, error: Message) => {
    return {
      ...result,
      ...error
    };
  }, {});
};

export const validateRequiredFields = <T>(data: T, requiredFields: (keyof T)[]): Partial<Record<keyof T, any>> => {
  const newErrors: Partial<Record<keyof T, any>> = {};

  for (const field of requiredFields) {
    if (!data[field]) {
      newErrors[field] = ['Field is required'];
    }
  }

  return newErrors;
};

export default errorParser;
