import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment-timezone';
import {
  accountSelector,
  activeBrandIdSelector,
  authSelector,
  brandsForFilterSelector,
  userSelector
} from '../../../store/auth/selectors';
import { Account, BrandForFilter, User } from '../../type';
import { PaymentTypeEnum, UserRoles } from '../../enums';
import { hasFullAccess } from '../../helpers/hasFullAccess';
import { AccountPayment } from '../../type/payments/accountPayment.type';
import { AccountType } from '../../type/account/accountType.enum';

type UseUser = {
  user: User;
  isAdmin: boolean;
  isSuperAdmin: boolean;
  withoutBrands: boolean;
  hasAccess: boolean;
  activeBrandId: number | undefined;
  showLeadsMessage: boolean;
  account: Account;
  noAccessToCampaigns: boolean;
  activeSubscription: AccountPayment | undefined;
  hasActiveLicense: boolean;
  hasToPay: boolean;
  paidAccount: boolean;
};

const useCurrentUser = (): UseUser => {
  const auth = useSelector(authSelector);
  const user = useSelector(userSelector);
  const activeBrandId = useSelector(activeBrandIdSelector);
  const brandsForFilter = useSelector(brandsForFilterSelector);
  const account = useSelector(accountSelector);
  const isAdmin = useMemo(() => [UserRoles.ADMIN, UserRoles.SUPER_ADMIN].includes(user.role), [user.role]);
  const isSuperAdmin = useMemo(() => user.role === UserRoles.SUPER_ADMIN, [user.role]);

  const withoutBrands = useMemo(() => {
    if (auth.authorized && user.id && user.role === UserRoles.TEAM_MEMBER) return false;
    return Boolean(auth.authorized && user.id && user.account.brands.length < 1 && hasFullAccess(user));
  }, [auth.authorized, user]);

  const hasToPay = useMemo(() => {
    if (!user.account || ![AccountType.CUSTOM, AccountType.AGENCY].includes(user.account.type)) {
      return false;
    }

    return Boolean(
      auth.authorized &&
        user.id &&
        (!moment(user.account.payment_expires_at).isAfter(moment()) || !user.account.payment_expires_at)
    );
  }, [user.account, user.id, auth.authorized]);

  const showLeadsMessage = false;

  const hasAccess = useMemo(() => {
    const teamMemberActiveBrand = brandsForFilter.find((brand: BrandForFilter) => brand.id === activeBrandId);
    return user.role === UserRoles.TEAM_MEMBER ? Boolean(teamMemberActiveBrand?.fullAccess) : true;
  }, [user, brandsForFilter, activeBrandId]);

  const noAccessToCampaigns = useMemo(() => {
    return user.role === UserRoles.TEAM_MEMBER && brandsForFilter.length < 1;
  }, [user, brandsForFilter]);

  const activeSubscription: AccountPayment | undefined = useMemo(() => account.payments[0], [account.payments]);
  const hasActiveLicense = useMemo(
    () =>
      user.account.type !== AccountType.STANDARD ||
      [PaymentTypeEnum.Full, PaymentTypeEnum.Enterprise].includes(activeSubscription?.payment_type),
    [activeSubscription?.payment_type, user.account.type]
  );

  const paidAccount = account.paid || account.type !== AccountType.STANDARD;

  return {
    hasAccess,
    noAccessToCampaigns,
    withoutBrands,
    account,
    showLeadsMessage,
    activeBrandId,
    user,
    isAdmin,
    isSuperAdmin,
    activeSubscription,
    hasActiveLicense,
    hasToPay,
    paidAccount
  };
};

export default useCurrentUser;
