import { createContext } from 'react';

export type AdventCalendarContextType = {
  activeDays: number[];
  originDay?: number;
};

const AdventCalendarContext = createContext<AdventCalendarContextType>({
  activeDays: []
});

export default AdventCalendarContext;
