import { createAction } from '../../utils/actions';
import { tokensStoreAdapter } from './adapters';
import { ActionType } from '../../utils/type';
import { Store } from '../types';
import { createSelector } from 'reselect';
import { TierPlansStoreEnum } from './types';

export const mainSelector = (store: Store) => store.tierPlans;
export const tierPlansSelector = <T>(type: TierPlansStoreEnum) =>
  createSelector(mainSelector, (store): T => store[type] as unknown as T);

export const setTierPlansField = createAction('tierplans/set_field');

const reducer = (state = tokensStoreAdapter(), action: ActionType) => {
  switch (action.type) {
    case setTierPlansField.type: {
      return state.set(action.path, action.payload);
    }

    default:
      return state;
  }
};

export default reducer;
