import { useCallback, useMemo, useState } from 'react';
import { message, notification } from 'antd';
import { AxiosError, AxiosResponse } from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import useApi from '../useApi';
import {
  hubspotConnectionsSelector,
} from '../../store/integration/selectors';
import {
  removeHubSpotConnection,
  setHubSpotConnections,
} from '../../store/integration/actions';
import { HubSpotConnection } from '../../store/integration/types';
import { Errors } from '../../utils/errors';
import * as T from '../requestTypes';
import { userSelector } from '../../store/auth/selectors';
import { useHistory } from 'react-router-dom';

type UseHubspot = {
  errors: Errors;
  createBrandHubSpotConnection: (brandId: number, data: T.CreateHubSpotConnectionData, cb: () => void) => void;
  getBrandHubSpotClients: (brandId: number, id: number, cb: (value: any) => void) => void;
  getAccountBrandsHubSpotConnections: (accountId?: number) => void;
  deleteBrandHubSpotConnection: (brandId: number, id: number) => void;
  confirmBrandHubSpotConnection: () => void;
  hubspotConnections: HubSpotConnection[];
  onSetHubSpotCode: (code: string) => void;
  testHubSpotSubmitContact: (id: number, brandId: number, data: T.TestHubSpotContactCreationData) => void;
};

const useHubspot = (): UseHubspot => {
  const dispatch = useDispatch();
  const [errors, onChangeErrors] = useState({});
  const hubspotConnections = useSelector(hubspotConnectionsSelector);

  const user = useSelector(userSelector);
  const isAdmin = useMemo(() => user.role === 'admin' || user.role === 'super_admin', [user.role]);
  const [hubSpotCode, onChangeHubSpotCode] = useState<string | null>(null);
  const api = useApi();
  const history = useHistory();


  const getAccountBrandsHubSpotConnections = useCallback(
    (accountId?: number) => {
      if (!isAdmin) {
        api.getAccountBrandsHubSpotConnections().then((response: AxiosResponse) => {
          dispatch(setHubSpotConnections(response.data));
        });
        return;
      }

      if (accountId && isAdmin) {
        api.adminGetAccountBrandsHubSpotConnections(accountId).then((response: AxiosResponse) => {
          dispatch(setHubSpotConnections(response.data));
        });
      }
    },
    [isAdmin]
  );

  const createBrandHubSpotConnection = useCallback(
    (brandId: number, data: T.CreateHubSpotConnectionData, cb: () => void) => {
      api.setBrandHubSpotConnection(brandId, data).then(cb);
    },
    []
  );

  const confirmBrandHubSpotConnection = useCallback(() => {
    if (!hubSpotCode) return;
    api
      .confirmBrandHubSpotConnection({ hubspot_token: hubSpotCode })
      .then(() => {
        message.success('HubSpot connection successfully created');
        history.push('/settings/crm-integrations?integration=hubspot&section=settings');
      })
      .catch(() => {
        history.push('/settings/crm-integrations?integration=hubspot&section=settings');
      });
  }, [hubSpotCode]);

  const getBrandHubSpotClients = useCallback((brandId: number, id: number, cb: (value: any) => void) => {
    const func = isAdmin ? api.adminGetBrandHubSpotClientsFields : api.getBrandHubSpotClientsFields;
    func(id, brandId).then((response: AxiosResponse) => {
      cb(response.data);
    });
  }, []);

  const deleteBrandHubSpotConnection = useCallback((brandId: number, id: number) => {
    api.deleteBrandHubSpotConnection(brandId, id).then(() => {
      message.success('HubSpot connection successfully deleted');
      dispatch(removeHubSpotConnection(id));
    });
  }, []);

  const testHubSpotSubmitContact = useCallback(
    (id: number, brandId: number, data: T.TestHubSpotContactCreationData) => {
      onChangeErrors({});
      api
        .testHubSpotSubmitContact(id, brandId, data)
        .then(() => {
          message.success('Test contact has been successfully sent');
        })
        .catch((error: AxiosError) => {
          if (error.response?.data.statusCode === 422) {
            onChangeErrors({ error: [error.response?.data?.message] });
          }
          if (error.response?.data.statusCode === 400) {
            notification.error({
              message: 'Error',
              description: error.response?.data?.message
            });
          }
        });
    },
    []
  );

  const onSetHubSpotCode = useCallback((code: string) => {
    onChangeHubSpotCode(code);
  }, []);

  return {
    errors,
    createBrandHubSpotConnection,
    getBrandHubSpotClients,
    getAccountBrandsHubSpotConnections,
    deleteBrandHubSpotConnection,
    hubspotConnections,
    confirmBrandHubSpotConnection,
    testHubSpotSubmitContact,
    onSetHubSpotCode,
  };
};

export default useHubspot;
