import React, { ChangeEvent, FunctionComponent, ReactText, useCallback, useEffect, useState } from 'react';
import cx from 'classnames';
import WhiteField from '../field/white';
import styles from './input.module.css';
import Icon, { IconType } from '../../icon';
import InputComponentType from '../../../utils/type/input.type';
import { debounce } from 'lodash';

type InputType = 'text' | 'email' | 'number' | 'password';

type Props = InputComponentType & {
  name: string;
  value?: string | number | ReactText;
  type?: InputType;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  onBlurCallback?: () => void;
  onClick?: () => void;
  label?: string;
  refProp?: any;
  placeholder?: string;
  className?: string;
  errors?: string[];
  icon?: IconType;
  required?: boolean;
  readOnly?: boolean;
  disabled?: boolean;
  max?: number;
  min?: number;
  step?: number;
  maxLength?: number;
  inputClassName?: string;
  labelWidth?: number;
  inputWidth?: number;
  tiny?: boolean;
  inline?: boolean;
  focus?: boolean;
  isDateInput?: boolean;
};

const WhiteInput: FunctionComponent<Props> = (props) => {
  const {
    name,
    value,
    type = 'text',
    onChange,
    label,
    required,
    disabled,
    className,
    inputClassName,
    errors,
    readOnly,
    maxLength,
    labelWidth,
    placeholder,
    icon,
    tiny,
    max,
    min,
    onClick,
    inline,
    onBlurCallback,
    step,
    refProp,
    inputWidth,
    focus,
    isDateInput,
    changeConfigField,
    ...other
  } = props;

  const [localType, onChangeLocalType] = useState<InputType>(type);
  const [inputValue, setInputValue] = useState<number | string | undefined>(value);
  const [dirty, setDirty] = useState<boolean>(false);

  useEffect(() => {
    if (value !== inputValue) setInputValue(value);
  }, [value]);

  const handleChangeLocalType = useCallback(() => {
    onChangeLocalType(localType === 'password' ? 'text' : 'password');
  }, [localType]);

  const changeValue = (event: any) => {
    if (onChange) onChange(event);
    setDirty(true);
    setInputValue(event.target.value);
  };

  const debounceUpdate = debounce(() => {
    if (changeConfigField && dirty) {
      changeConfigField({ [name]: inputValue });
    }
  }, 500);

  useEffect(
    () => {
      debounceUpdate();
      return () => {
        debounceUpdate.cancel();
      };
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [inputValue]
  );

  const input = (
    <input
      readOnly={readOnly}
      placeholder={placeholder}
      type={localType}
      max={max}
      ref={refProp}
      min={min}
      maxLength={maxLength}
      cypress-id={`${name}-component`}
      value={inputValue}
      onClick={onClick}
      className={cx(
        styles.input,
        { [styles.hasError]: errors, [styles.readOnly]: readOnly, [styles.tiny]: inline || tiny },
        inputClassName
      )}
      required={required}
      disabled={disabled}
      onChange={changeValue}
      step={step}
      onBlur={onBlurCallback}
      name={name}
      style={inputWidth ? { width: inputWidth } : {}}
      autoFocus={focus}
      {...(isDateInput ? other : {})}
    />
  );

  return (
    <WhiteField width={labelWidth} {...props}>
      {icon ? (
        <span className={cx(styles.inputWrapper, { [styles.password]: type === 'password' })}>
          <Icon icon={icon} className={styles.icon} />
          {input}
          {type === 'password' ? (
            <Icon
              icon="eye"
              active={localType === 'text'}
              onClick={handleChangeLocalType}
              className={styles.postIcon}
            />
          ) : null}
        </span>
      ) : (
        input
      )}
    </WhiteField>
  );
};

export default WhiteInput;
