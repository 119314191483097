import { useMemo } from 'react';
import { useLocation } from 'react-router';
import { useSelector } from 'react-redux';
import { campaignSelector } from '../../../store/campaign/selectors';

const useMediaFilesCategories = (categories: string[]) => {
  const location = useLocation();
  const campaign = useSelector(campaignSelector);

  return useMemo(() => {
    let currentCategories = categories || [];
    const isGameEditor = location.pathname.endsWith('/game');

    if (isGameEditor && campaign.game.settings_type) {
      currentCategories = ['game', campaign.game.settings_type];
    }

    return currentCategories;
  }, [campaign.game.settings_type, location.pathname, categories]);
};

export default useMediaFilesCategories;
