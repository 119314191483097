import { ImmutableObject } from 'seamless-immutable';

export enum UIStoreEnum {
  CAMPAIGN_LIST_IMAGE = 'campaignListImage',
  DEBUG_ADVENT_DAY = 'debugAdventDay',
  LOADING_OVERLAY = 'loadingOverlay',
  SHOW_ACTIVE_BRAND_EDIT_MODAL = 'showEditBrandModal',
  SHOW_DEFAULT_BRAND_POPUP = 'showDefaultBrandPopup',
  CLEAR_SIDEBAR = 'clearSidebar',
  TINY_SIDE_BAR = 'tinySidebar',
  SHOW_BILLING_INFO_FORM = 'showAccountBillingInfoForm'
}

export type Action = {
  type: string;
  payload: any;
};

export type Font = {
  label: string;
  value: string;
};

export type BrandFont = {
  title: string;
  id: number;
  url: string;
};

export type DebugState = 'start' | 'create' | 'join' | 'game' | 'wait' | 'end';

export type Tour = {
  type?: 'menu' | 'game' | 'screens' | 'prizes';
  index: number;
  currentSelector?: string;
  tourEndMenu: boolean;
};

export type SidebarType = 'full' | 'minimize';

export type UIStateType = {
  fonts: Font[];
  disableSidebar: boolean;
  fullPreview: boolean;
  restrictionModalText: string;
  restrictionModalShow: boolean;
  sidebar: boolean;
  sidebarType: SidebarType;
  brandFonts: BrandFont[];
  debugState: DebugState;
  spinner: number;
  adactTour: Tour;
  disableBodyScroll: boolean;
  [UIStoreEnum.CAMPAIGN_LIST_IMAGE]: number;
  [UIStoreEnum.DEBUG_ADVENT_DAY]: number;
  [UIStoreEnum.LOADING_OVERLAY]: boolean;
  [UIStoreEnum.SHOW_ACTIVE_BRAND_EDIT_MODAL]: boolean;
  [UIStoreEnum.TINY_SIDE_BAR]: boolean;
  [UIStoreEnum.CLEAR_SIDEBAR]: boolean;
  [UIStoreEnum.SHOW_DEFAULT_BRAND_POPUP]: boolean;
  [UIStoreEnum.SHOW_BILLING_INFO_FORM]: boolean;
};

export type UiState = ImmutableObject<UIStateType>;
