import { GameSettingTypesEnum } from '../../enums';
import { GameTypes } from '../../enums/games/game-types.enum';
import { Campaign, ListCampaign } from '../../type';

export const useCampaignGameType = (campaign: Campaign | ListCampaign) => {
  const gameType: GameSettingTypesEnum = campaign.game?.settings_type;

  const campaignWithOutPrize = [GameSettingTypesEnum.MULTIPLAYER_TRIVIA].includes(gameType);
  const isVideoGame = [
    GameSettingTypesEnum.HOTSPOT,
    GameSettingTypesEnum.VIDEO_TRIVIA,
    GameSettingTypesEnum.MULTIPLE_VIDEO_TRIVIA
  ].includes(gameType);
  const isVideoRequired = [GameSettingTypesEnum.HOTSPOT, GameSettingTypesEnum.VIDEO_TRIVIA].includes(gameType);
  const isAdventGame = [GameSettingTypesEnum.ADVENT_CALENDAR].includes(gameType);
  const isCommunityContest = [GameSettingTypesEnum.COMMUNITY_CONTEST].includes(gameType);
  const isResultsGame = [GameSettingTypesEnum.PERSONALITY_TEST, GameSettingTypesEnum.MULTI_PATH_QUIZ].includes(
    gameType
  );
  const isRngGame = campaign.game.gameType.type === GameTypes.RNG;
  const isPollGame = campaign.game.gameType.type === GameTypes.POLL;
  const canShareLeaderboard = [GameTypes.NON_RNG, GameTypes.TRIVIA].includes(campaign.game.gameType.type);
  const isTriviaBuilder = GameSettingTypesEnum.TRIVIA_BUILDER === gameType;

  const hasNoLeaderboard = isAdventGame || isCommunityContest || isRngGame;
  const hasClassicLeaderboard = !hasNoLeaderboard && !isPollGame;

  return {
    campaignWithOutPrize,
    isVideoGame,
    isVideoRequired,
    isAdventGame,
    isCommunityContest,
    isResultsGame,
    isRngGame,
    isPollGame,
    hasNoLeaderboard,
    gameType,
    canShareLeaderboard,
    isTriviaBuilder,
    hasClassicLeaderboard
  };
};
