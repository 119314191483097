import React, { Fragment, FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import Icon from '../../icon';
import cx from 'classnames';
import styles from './upload.module.css';
import InputType from '../../../utils/type/input.type';
import ModalBody from './modalBody';
import OldUploadModal from './oldUploadModal';
import useMediaFilesCategories from '../../../utils/hooks/useMediaFilesCategories';
import Typo from '../../typo';

export type Props = InputType & {
  className?: string;
  value?: string;
  type: 'gameBackground' | 'gameSettings' | 'favicon' | 'newsPost' | 'gamePreview';
  refProp?: any;
  big?: any;
  disabled?: boolean;
  accept?: string;
  maxWidth?: number;
  maxHeight?: number;
  removable?: boolean;
  disableRemove?: boolean;
  onRemoveKeepValue?: boolean;
  onRemove?: () => void;
  onChange?: (value: any) => void;
  onChangeUrl?: (value: any) => void;
  tourId?: string;
  mockUpload?: boolean;
  phaser?: boolean;
  categories?: string[];
  multiUpload?: boolean;
  old?: boolean;
};

const WhiteUpload: FunctionComponent<Props> = (props) => {
  const {
    name,
    big,
    onRemove,
    disabled,
    removable,
    onRemoveKeepValue,
    disableRemove,
    value,
    onChange,
    className,
    tourId,
    changeConfigField,
    old
  } = props;
  const [showModal, setShowModal] = useState<boolean>(false);
  const [hover, setHover] = useState<boolean>(false);
  const currentCategories = useMediaFilesCategories(props.categories || []);

  const onRemoveImage = useCallback(
    (event: any) => {
      event.preventDefault();
      event.stopPropagation();

      if (disableRemove) return;

      if (!onRemoveKeepValue) {
        if (onChange) onChange('');
        if (changeConfigField) changeConfigField({ [name]: '' });
      }
      if (onRemove) onRemove();
    },
    [disableRemove, onRemoveKeepValue, onRemove, onChange, changeConfigField, name]
  );

  useEffect(() => {
    setHover(false);
  }, [showModal]);

  const removableImage = useMemo(
    () => (
      <div
        className={cx(styles.uploader, styles.removableWrapper, className, {
          [styles.disabled]: disabled,
          [styles.big]: big,
          [styles.hover]: hover
        })}
        onPointerOver={() => setHover(true)}
        onPointerOut={() => setHover(false)}
        onClick={() => setShowModal(true)}
        cypress-id={`${name}-component-wrapper`}
      >
        {value ? (
          <>
            <img className={styles.img} src={value} alt={name} draggable={false} />
            <div className={styles.overlay} />
            <div className={cx(styles.buttonsImageWrapper, { [styles.small]: !big })}>
              <button
                className={cx(styles.editableBtn, { [styles.small]: !big })}
                cypress-id={`${name}-component-update`}
                name={`${name}-component-update`}
              >
                <Typo type="p" className={styles.text}>
                  Change
                </Typo>
              </button>
            </div>
            {removable && !disableRemove && (
              <button
                className={cx(styles.trashBtn, { [styles.small]: !big })}
                cypress-id={`${name}-component-remove`}
                onClick={onRemoveImage}
                name={`${name}-component-remove`}
              >
                <Icon icon="trash" className={styles.trashIcon} />
              </button>
            )}
          </>
        ) : (
          <Icon icon="blue_plus" />
        )}
      </div>
    ),
    [className, disabled, big, hover, name, value, removable, disableRemove, onRemoveImage]
  );

  const plainImage = (
    <label
      className={cx(styles.uploader, className, {
        [styles.empty]: !value,
        [styles.disabled]: disabled,
        [styles.big]: big
      })}
      cypress-id={`${name}-component-wrapper`}
      onClick={() => setShowModal(true)}
    >
      {value ? (
        <img className={styles.img} src={value} alt={name} draggable={false} />
      ) : (
        <div className={styles.uploaderButtonBody}>
          <Icon icon="blue_plus" />
          <div>Upload</div>
        </div>
      )}
    </label>
  );

  return (
    <>
      {!tourId ? (value ? removableImage : plainImage) : null}
      {old ? (
        <OldUploadModal {...props} show={showModal} setShowModal={setShowModal} />
      ) : (
        <ModalBody {...props} categories={currentCategories} show={showModal} setShowModal={setShowModal} />
      )}
    </>
  );
};

export default WhiteUpload;
