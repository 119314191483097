import { GameSettingTypesEnum } from '../../enums';
import { GamePrizeFieldsType } from '../../type';

export const GAME_PRIZE_FIELDS_MAPPING: Partial<Record<GameSettingTypesEnum, GamePrizeFieldsType>> = {
  [GameSettingTypesEnum.WHEEL_OF_FORTUNE]: {
    sectionsField: 'wheelSections',
    prizeIdField: 'wheelSections[].prizeId',
    useImageField: 'useImage',
    imageField: 'prizeImageUrl',
    textField: 'sectionText'
  },
  [GameSettingTypesEnum.PLINKO]: {
    sectionsField: 'prizeSections',
    prizeIdField: 'prizeSections[].prizeId',
    useImageField: 'useImage',
    imageField: 'prizeImageUrl',
    textField: 'sectionText'
  },
  [GameSettingTypesEnum.SCRATCHCARD]: {
    sectionsField: 'prizeIcons',
    prizeIdField: 'prizeIcons[].prizeId',
    imageField: 'url'
  },
  [GameSettingTypesEnum.OPEN_THE_BOX]: {
    sectionsField: 'prizeIcons',
    prizeIdField: 'prizeIcons[].prizeId',
    imageField: 'url'
  }
};
