import { ImmutableObject } from 'seamless-immutable';
import { AccountLicense } from './account-licenses/types';
import { SalesForceConnection } from './salesforce/types';
import {
  BrandTitles,
  CustomDomain,
  EmailRecipient,
  FolderType,
  Game,
  GameTag,
  LandingPageScreenElement,
  MediaFile,
  PlayerWithQuestionItemType,
  ScreenTemplate,
  SystemNotification,
  TicketShowType,
  TriviaGeneralStatisticType,
  TriviaQuestionInfoItemType,
  UserTag
} from '../../utils/type';
import { Pagination } from '../types';

export enum CommonStoreEnum {
  DOMAIN = 'domain',
  BRAND_TITLES = 'brandTitles',
  SCREEN_TEMPLATES = 'screenTemplates',
  COPY_ELEMENT = 'copyElement',
  EMAIL_RECIPIENTS = 'emailRecipients',
  FOLDERS = 'folders',
  ACTIVE_FOLDER = 'folder',
  LICENSES = 'licenses',
  SHOW_TICKET = 'show_ticket',
  GAME_ANALYTICS = 'gameAnalytics',
  GAME_QUESTIONS_ANALYTICS = 'gameQuestionsAnalytics',
  PLAYERS_WITH_QUESTIONS_ANALYTICS = 'playersWithQuestionsAnalytics',
  GAMES = 'games',
  ACTIVE_ADVENT_DAYS = 'active_advent_days',
  ORIGIN_ADVENT_DAY = 'origin_advent_day',
  SALESFORCE_CONNECTIONS = 'set_sales_force_connections',
  TRIVIA_DEBUG_QUESTION = 'trivia_debug_question',
  TRIVIA_STARTING_QUESTION = 'trivia_starting_question',
  TRIVIA_DEBUG_RESULT = 'trivia_debug_result',
  DEBUG_TIC_TAC_TOE_RESULT = 'tic_tac_toe_result',
  SYSTEM_NOTIFICATIONS = 'system_notifications',
  SYSTEM_NOTIFICATION_PREVIEW = 'system_notification_preview',
  MEDIA_FILES = 'media_files',
  RECENT_MEDIA_FILES = 'recent_media_files',
  NEW_MEDIA_FILES = 'new_media_files',
  USER_TAGS = 'user_tags',
  GAME_TAGS = 'game_tags'
}

export type CommonState = ImmutableObject<{
  [CommonStoreEnum.DOMAIN]: CustomDomain;
  [CommonStoreEnum.BRAND_TITLES]: BrandTitles[];
  [CommonStoreEnum.SCREEN_TEMPLATES]: ScreenTemplate[];
  [CommonStoreEnum.COPY_ELEMENT]: null | LandingPageScreenElement;
  [CommonStoreEnum.EMAIL_RECIPIENTS]: EmailRecipient[];
  [CommonStoreEnum.FOLDERS]: FolderType[];
  [CommonStoreEnum.ACTIVE_FOLDER]: number;
  [CommonStoreEnum.LICENSES]: AccountLicense[];
  [CommonStoreEnum.SHOW_TICKET]: TicketShowType | null;
  [CommonStoreEnum.GAME_ANALYTICS]: TriviaGeneralStatisticType | null;
  [CommonStoreEnum.GAME_QUESTIONS_ANALYTICS]: TriviaQuestionInfoItemType[];
  [CommonStoreEnum.PLAYERS_WITH_QUESTIONS_ANALYTICS]: PlayerWithQuestionItemType[];
  [CommonStoreEnum.GAMES]: Game[];
  [CommonStoreEnum.ACTIVE_ADVENT_DAYS]: number[];
  [CommonStoreEnum.ORIGIN_ADVENT_DAY]: null | number;
  [CommonStoreEnum.SALESFORCE_CONNECTIONS]: SalesForceConnection[];
  [CommonStoreEnum.TRIVIA_DEBUG_QUESTION]: number | string | undefined;
  [CommonStoreEnum.TRIVIA_DEBUG_RESULT]: number | string | undefined;
  [CommonStoreEnum.TRIVIA_STARTING_QUESTION]: number | string | undefined;
  [CommonStoreEnum.DEBUG_TIC_TAC_TOE_RESULT]: boolean;
  [CommonStoreEnum.SYSTEM_NOTIFICATIONS]: SystemNotification[];
  [CommonStoreEnum.SYSTEM_NOTIFICATION_PREVIEW]: SystemNotification | null;
  [CommonStoreEnum.MEDIA_FILES]: Pagination<MediaFile>;
  [CommonStoreEnum.RECENT_MEDIA_FILES]: Pagination<MediaFile>;
  [CommonStoreEnum.NEW_MEDIA_FILES]: MediaFile[];
  [CommonStoreEnum.USER_TAGS]: UserTag[];
  [CommonStoreEnum.GAME_TAGS]: GameTag[];
}>;
