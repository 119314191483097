import React, { FunctionComponent, useMemo, useState } from 'react';
import styles from './paginationPages.module.css';
import Icon from '../icon';
import cx from 'classnames';
import Typo from '../typo';
import { take, times } from 'lodash';
import { PaginationMeta } from '@kalevkarpuk/all-new-adact-shareable-components/dist/games/communitycontest/types';
import { WhiteSelect } from '../form';

type Props = {
  meta: PaginationMeta;
  getPage: (page: number, limit?: number) => void;
  hasDropDown?: boolean;
  white?: boolean;
  defaultLimit?: number;
  limits?: number[];
};

const PaginationPages: FunctionComponent<Props> = ({ meta, getPage, hasDropDown, white, limits, defaultLimit }) => {
  const [limit, setLimit] = useState<number | undefined>(defaultLimit);

  const pageButtons = useMemo(() => {
    const currentPage = meta.currentPage;
    let pages: number[] = times(meta.totalPages, (i) => i + 1);

    if (currentPage === 1) {
      return take(pages, 3);
    } else if (currentPage === meta.totalPages) {
      return pages.slice(Math.max(pages.length - 3, 0));
    }

    return [currentPage - 1, currentPage, currentPage + 1];
  }, [meta.currentPage, meta.totalPages]);

  const getNewPage = (page: number) => {
    if (meta.currentPage === page || !page || page > meta.totalPages) {
      return;
    }

    getPage(page, defaultLimit ? limit : undefined);
  };

  const getNewLimit = (limit: number) => {
    getPage(meta.currentPage, limit);
    setLimit(limit);
  };

  if (!meta.totalPages || meta.totalPages === 1) {
    return null;
  }

  const pagesButtons = (
    <>
      <div className={cx(styles.pagesWrapper, { [styles.white]: white })}>
        <div className={styles.pageButton} onClick={() => getNewPage(meta.currentPage - 1)}>
          <Icon icon="chevron_l" />
        </div>
        {pageButtons.map((pageNum: number) => (
          <div
            key={pageNum}
            className={cx(styles.pageButton, { [styles.active]: pageNum === meta.currentPage })}
            onClick={() => getNewPage(pageNum)}
          >
            <Typo type="p">{pageNum}</Typo>
          </div>
        ))}
        <div className={styles.pageButton} onClick={() => getNewPage(meta.currentPage + 1)}>
          <Icon icon="chevron_r" />
        </div>
      </div>
    </>
  );

  if (hasDropDown) {
    const dropdownOptions = limits?.map((i) => ({ label: `Show ${i} per page`, value: i })) || [];

    return (
      <div className={styles.field}>
        <div className={styles.leftSide}>
          <WhiteSelect
            name="campaigns-page-num"
            inputClassName={cx({ [styles.whiteSelect]: white })}
            options={dropdownOptions}
            value={limit}
            onChange={(limit) => getNewLimit(limit)}
            listTop
          />
        </div>

        <div className={styles.rightSide}>{pagesButtons}</div>
      </div>
    );
  }

  return pagesButtons;
};

export default PaginationPages;
