import React, { FunctionComponent, useEffect, useState } from 'react';
import AntInput, { InputProps } from 'antd/lib/input';
import Field from '../field';
import InputType from '../../../utils/type/input.type';
import { debounce } from 'lodash';

interface Props extends InputProps, InputType {
  className?: string;
  label?: string;
  name: string;
  onBlurCallback?: () => void;
  disabled?: boolean;
  inputClassName?: string;
  refProp?: any;
  errors?: string[];
}

const Input: FunctionComponent<Props> = (props) => {
  const {
    className,
    label,
    refProp,
    errors,
    name,
    onBlurCallback,
    disabled,
    inputClassName,
    onBlur,
    changeConfigField,
    value,
    onChange,
    ...other
  } = props;
  const [inputValue, setInputValue] = useState<any>(value);
  const [dirty, setDirty] = useState<boolean>(false);

  const debounceUpdate = debounce(() => {
    if (changeConfigField && dirty) {
      changeConfigField({ [name]: inputValue });
    }
  }, 500);

  useEffect(
    () => {
      debounceUpdate();
      return () => {
        debounceUpdate.cancel();
      };
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [inputValue]
  );

  const onChangeValue = (event: any) => {
    setInputValue(event.target.value);
    setDirty(true);
    if (onChange) {
      onChange(event);
    }
  };

  return (
    <Field className={className} label={label} errors={errors} disabled={disabled}>
      <AntInput.TextArea
        {...other}
        onChange={onChangeValue}
        value={inputValue}
        name={name}
        ref={refProp}
        disabled={disabled}
        className={inputClassName}
        cypress-id={`${name}-component`}
        //@ts-ignore
        onBlur={onBlurCallback ? onBlurCallback : onBlur}
      />
    </Field>
  );
};

export default Input;
