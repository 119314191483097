import API from './api';
import { useSelector } from 'react-redux';
import { tokenSelector } from '../store/auth/selectors';

const useApi = () => {
  const token = useSelector(tokenSelector);
  return API(token);
};

export default useApi;
