import React, {
  CSSProperties,
  FunctionComponent,
  ReactText,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import cx from 'classnames';
import WhiteField from '../field/white';
import arrow from './arrow.svg';
import styles from './input.module.css';
import InputType from '../../../utils/type/input.type';

type Props = InputType & {
  name: string;
  value?: string | number | undefined;
  onChange?: (value: string | number | any) => void;
  label?: string;
  placeholder?: string;
  className?: string;
  errors?: string[];
  required?: boolean;
  disabled?: boolean;
  onBlurCallback?: () => void;
  options: Option[];
  labelWidth?: number;
  inputWrapperClassName?: string;
  inputClassName?: string;
  listClassName?: string;
  tiny?: boolean;
  inline?: boolean;
  overflow?: boolean;
  listTop?: boolean;
  width?: number;
  large?: boolean;
  scrollRef?: any;
};

export type Option = {
  label: string | number | ReactText;
  value: string | number | undefined | null;
};

const WhiteSelect: FunctionComponent<Props> = (props) => {
  const {
    name,
    value,
    onChange,
    label,
    required,
    disabled,
    options,
    inputClassName,
    labelWidth,
    placeholder,
    errors,
    tiny,
    inline,
    listTop,
    onBlurCallback,
    listClassName,
    overflow,
    width,
    large,
    scrollRef,
    changeConfigField,
    inputWrapperClassName
  } = props;
  const [show, onChangeShow] = useState<boolean>(false);
  const uniqName = useMemo(() => `adact-white-select-${name.replaceAll(/[^a-zA-Z0-9]/g, '')}`, [name]);
  const input = useRef<HTMLDivElement>(null);
  const dropdown = useRef<HTMLDivElement>(null);
  const [overflowStyles, setOverflowStyles] = useState<CSSProperties>();

  useEffect(() => {
    document.addEventListener('click', handleClick, true);
    return () => {
      document.removeEventListener('click', handleClick, true);
    };
  }, [show]);

  const handleClick = useCallback(
    (event) => {
      const inSelect = event.target?.closest(`.${uniqName}`);
      if (show && !inSelect) {
        onChangeShow(false);
        if (onBlurCallback) onBlurCallback();
      }
      return;
    },
    [show, uniqName, value]
  );

  const handleShow = useCallback(() => {
    if (disabled) return;
    onChangeShow(!show);
  }, [show, disabled]);

  useEffect(() => {
    if (dropdown.current && dropdown.current.getBoundingClientRect().bottom > window.innerHeight) {
      dropdown.current?.scrollIntoView(true);
    }
  }, [show]);

  const onClick = useCallback(
    (value: string | number | undefined | null, event: any) => {
      if (onChange) onChange(value);
      if (changeConfigField) changeConfigField({ [name]: value }, event);
      onChangeShow(false);
    },
    [onChange, onChangeShow, changeConfigField]
  );

  const calcOffset = () => {
    const rect = input.current?.getBoundingClientRect();
    if (rect) {
      setOverflowStyles({
        left: rect.x,
        position: 'fixed',
        top: rect.y + rect.height,
        width: rect.width,
        minWidth: rect.width
      });
    }
  };

  useEffect(() => {
    if (overflow) {
      if (scrollRef) {
        scrollRef?.addEventListener('scroll', calcOffset);
        return () => {
          scrollRef?.removeEventListener('scroll', calcOffset);
        };
      }
    }
  }, [overflow, scrollRef]);

  const currentOption = useMemo(() => options.find((item: Option) => item.value === value), [options, value]);
  return (
    <WhiteField {...props} width={labelWidth}>
      <div
        style={{ ...(width ? { width } : {}) }}
        ref={input}
        className={cx(styles.select, uniqName, { [styles.active]: show }, inputWrapperClassName)}
      >
        <input type="hidden" name="name" value={value} required={required} disabled={disabled} />
        <div
          onClick={handleShow}
          cypress-id={`${name}-select-toggler`}
          className={cx(styles.toggler, 'adact-select-toggler', inputClassName, {
            [styles.disabled]: disabled,
            [styles.hasError]: errors,
            [styles.tiny]: inline || tiny || (!label && !large)
          })}
        >
          {currentOption?.label ? (
            <span className={styles.value}>{currentOption?.label}</span>
          ) : (
            <span className={styles.placeholder}>{placeholder}</span>
          )}
          <img src={arrow} alt="arrow" draggable={false} className={styles.arrow} />
        </div>
        {show ? (
          <div
            style={overflowStyles}
            ref={dropdown}
            className={cx(styles.listWrapper, listClassName, { [styles.top]: listTop })}
          >
            <ul>
              {options.map((item: Option, idx: number) => (
                <li
                  role="button"
                  cypress-id={`${name}-select-list-item-${idx}`}
                  key={idx}
                  onClick={(event) => onClick(item.value, event)}
                >
                  {item.label}
                </li>
              ))}
            </ul>
          </div>
        ) : null}
      </div>
    </WhiteField>
  );
};

export default WhiteSelect;
