import I, { ImmutableObject } from 'seamless-immutable';
import {
  Account,
  AccountTierSettings,
  Brand,
  BrandEmail,
  BrandForFilter,
  BrandInList,
  CampaignInList,
  User,
  UserInvitation
} from '../../utils/type';

const defaultVisualSettings = {
  button: {},
  texts: [],
  image: {},
  registration_form: { styles: {}, inputs: [], button: {} }
};

export function brandAdapter(data?: Brand) {
  return I({
    id: data?.id || 0,
    completed_onboarding: Boolean(data?.completed_onboarding),
    skip_campaign_wizard: Boolean(data?.skip_campaign_wizard),
    title: data?.title || '',
    slug: data?.slug || '',
    language: data?.language || '',
    fullAccess: Boolean(data?.fullAccess),
    timezone: data?.timezone || '',
    fonts: data?.fonts || [],
    created_at: data?.created_at || '',
    expiringDraftCampaignsCount: data?.expiringDraftCampaignsCount || 0,
    visual_settings: data?.visual_settings || JSON.stringify(defaultVisualSettings),
    favicon: data?.favicon || null,
    defaultFont: data?.defaultFont || null,
    default_brand: Boolean(data?.default_brand)
  });
}

export const AccountTierSettingsAdapter = (data?: AccountTierSettings): ImmutableObject<AccountTierSettings> => {
  if (data) {
    return I(data);
  }

  //@ts-ignore;
  return I({
    accountId: null,
    _id: '',
    brandsCountAllowed: 0,
    campaignsCountAllowed: 0,
    gameplayCountAllowed: 0,
    gamesAllowed: [] as number[],
    teamMemberCountAllowed: 0,
    unlimitedGameplay: false,
    unlimitedUniqueLeads: false,
    uniqueLeadsCountAllowed: 0,
    imageBgRemovalAllowed: false,
    unlimitedCampaigns: false,
    unlimitedUniqueVisitors: false,
    uniqueVisitorCountAllowed: 0
  });
};

function brandsAdapter(data: Brand[] | undefined) {
  if (!data) return I([]);
  return I(data.map(brandAdapter));
}

export function accountTierPlanPriceAdapter(data: any) {
  return I(data);
}

function accountPaymentAdapter(data: any) {
  return I({
    ...data,
    id: data.id,
    count: data.count,
    payment_type: data.payment_type,
    accountId: data.accountId,
    tierPlanId: data.tierPlanId,
    tierPlanPriceId: data.tierPlanPriceId,
    created_at: data.created_at,
    updated_at: data.updated_at,
    tierPlan: accountTierPlanAdapter(data.tierPlan),
    tierPlanPrice: accountTierPlanPriceAdapter(data.tierPlanPrice)
  });
}
function accountPaymentsAdapter(data?: any[]) {
  if (data) {
    return I(data.map(accountPaymentAdapter));
  }
  return I([]);
}

export function accountAdapter(data?: Account) {
  return I({
    ...data,
    brands: brandsAdapter(data?.brands),
    created_at: data?.created_at || '',
    type: data?.type || 'standard',
    api_key_for_zapier: data?.api_key_for_zapier || '',
    has_trial_period: Boolean(data?.has_trial_period),
    id: data?.id || null,
    disabled_payment: Boolean(data?.disabled_payment),
    use_new_design: Boolean(data?.use_new_design),
    is_trial: Boolean(data?.is_trial),
    company_name: data?.company_name || '',
    phone_number: data?.phone_number || '',
    country_code: data?.country_code || '',
    city: data?.city || '',
    address: data?.address || '',
    zip_code: data?.zip_code || '',
    vat_number: data?.vat_number || '',
    has_vat: Boolean(data?.has_vat),
    payments: accountPaymentsAdapter(data?.payments),
    payment_customer_id: data?.payment_customer_id,
    payment_expires_at: data?.payment_expires_at,
    tierSettings: AccountTierSettingsAdapter(data?.tierSettings),
    last_invoice_id: data?.last_invoice_id || '',
    payment_subscription_id: data?.payment_subscription_id
  });
}

export function accountTierPlanAdapter(data: any) {
  return {
    ...data,
    id: data.id,
    title: data.title,
    description: data.description,
    money_back_description: data.money_back_description,
    payment_type: data.payment_type,
    active: data.active,
    features: data.features
  };
}

export function invitationAdapter(data: UserInvitation) {
  return I({
    ...data,
    available_brands: data.available_brands,
    available_campaigns: data.available_campaigns,
    email: data.email,
    full_name: data.full_name,
    id: data.id,
    createdBy: data.createdBy,
    tags: data.tags || [],
    role: data.role
  });
}

export function brandInListAdapter(data: BrandInList) {
  return I({
    ...data,
    id: data.id,
    title: data.title,
    campaigns: data.campaigns.map((item: CampaignInList) => ({
      id: item.id,
      title: item.title
    }))
  });
}

export function brandForFilterAdapter(data: BrandForFilter) {
  return I({
    fullAccess: data.fullAccess,
    id: data.id,
    hasCampaigns: Boolean(data.hasCampaigns),
    language: data.language,
    timezone: data.timezone,
    title: data.title
  });
}

export function userAdapter(data?: User) {
  return I({
    ...data,
    email: data?.email || '',
    id: data?.id || null,
    accessToken: data?.accessToken || null,
    role: data?.role || '',
    full_name: data?.full_name || '',
    brandsForFilter: data?.brandsForFilter ? data.brandsForFilter.map(brandForFilterAdapter) : [],
    phone_number: data?.phone_number || '',
    account: accountAdapter(data?.account),
    permissions: data?.permissions,
    accountId: data?.accountId,
    userSystemNotifications: data?.userSystemNotifications,
    available_brands: data?.available_brands || [],
    available_campaigns: data?.available_campaigns || [],
    invitedBy: data?.invitedBy,
    invitedById: data?.invitedById,
    invitedUsers: data?.invitedUsers || [],
    tags: data?.tags || [],
    notifications_campaign_ids: data?.notifications_campaign_ids || [],
    hasPendingLicense: Boolean(data?.hasPendingLicense)
  });
}

export function brandEmailAdapter(data?: BrandEmail): BrandEmail {
  return I({
    ...data,
    id: data?.id || null,
    email: data?.email || '',
    active: Boolean(data?.active),
    isDefault: Boolean(data?.isDefault)
  });
}
