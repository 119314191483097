import React, { FunctionComponent, useState } from 'react';
import styles from './categoriesModal.module.css';
import { WhiteAutocomplete, WhiteButton } from '../../index';
import { categoryOptions } from '../list';

type Props = {
  categories: string[] | undefined;
  onCancel: () => void;
  onSubmit: (categories: string[]) => void;
};

const CategoriesModal: FunctionComponent<Props> = (props) => {
  const { categories = [], onCancel, onSubmit } = props;

  const [category, setCategory] = useState<string>(
    categories.find((item) => categoryOptions.map((item) => item.value).includes(item)) || ''
  );

  const changeCategories = (category: string, sub: boolean = false) => {
    setCategory(category);
  };

  return (
    <div className={styles.modalWrapper}>
      <div className={styles.filtersWrapper}>
        <WhiteAutocomplete
          name="category"
          clearable
          overflow
          label="Category"
          focusOnClear={false}
          value={category}
          className={styles.topCategory}
          options={categoryOptions}
          onChange={changeCategories as any}
        />
      </div>
      <div className={styles.buttonsWrapper}>
        <WhiteButton type="ghost" onClick={() => onCancel()}>
          Cancel
        </WhiteButton>
        <WhiteButton onClick={() => onSubmit([category])}>Save</WhiteButton>
      </div>
    </div>
  );
};

export default CategoriesModal;
