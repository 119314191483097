import React, { FocusEvent, FunctionComponent } from 'react';
import AndDatepicker, { DatePickerProps } from 'antd/lib/date-picker';
import Field from '../field';
import styles from './datepicker.module.css';
import { Moment } from 'moment-timezone';

// @ts-ignore
interface Props extends DatePickerProps {
  className?: string;
  label?: string;
  name: string;
  onBlurCallback?: (event: FocusEvent<HTMLInputElement>) => void;
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  onOk?: () => void;
  value?: Moment | null;
  onChange?: (value: Moment | null) => void;
  showTime?: boolean | { [key: string]: boolean };
  format?: string;
  refProp?: any;
  errors?: string[];
}

const Input: FunctionComponent<Props> = (props) => {
  const { className, label, refProp, errors, name, onBlurCallback, format, disabled, onBlur, showTime, ...other } =
    props;
  return (
    <Field className={className} label={label} errors={errors} disabled={disabled}>
      <AndDatepicker
        {...other}
        className={styles.input}
        format={format}
        name={name}
        data-cypress-id={`${name}-component`}
        ref={refProp}
        disabled={disabled}
        showTime={showTime}
        onBlur={onBlurCallback ? onBlurCallback : onBlur}
      />
    </Field>
  );
};

export default Input;
