import { ParsedQuery } from 'query-string';
import useQuery, { queryToString } from '../../query';
import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';
import { useCallback } from 'react';

export const usePageQuery = () => {
  const query: ParsedQuery = useQuery();
  const location = useLocation();
  const history = useHistory();

  const setNewQuery = useCallback(
    (newQuery: Record<string, unknown>, clearOldQuery?: boolean, arrayFormat?: string) => {
      const newLocation =
        location.pathname + '?' + queryToString(clearOldQuery ? newQuery : Object.assign(query, newQuery), arrayFormat);
      history.push(newLocation);
    },
    [history, location, query]
  );

  const clearQuery = useCallback(() => {
    history.push(location.pathname);
  }, [history, location.pathname]);

  return {
    query,
    setNewQuery,
    history,
    location,
    clearQuery
  };
};
