import I, { ImmutableArray } from 'seamless-immutable';
import { Action, HubSpotConnection, MailChimpConnection, WebhookEvent } from './types';
import {
  addSegment,
  addWebhookEvents,
  clearIntegrationAction,
  removeHubSpotConnection,
  removeMailChimpConnection,
  removeSegment,
  setHubSpotConnections,
  setIntegration,
  setMailChimpConnections,
  setSegments,
  setWebhookEvents,
  updateSegment
} from './actions';
import {
  hubSpotConnectionAdapter,
  integrationAdapter,
  integrationStoreAdapter,
  mailChimpConnectionAdapter,
  metaAdapter,
  webhookEventsAdapter,
  webhookEventsObjectAdapter
} from './adapters';
import { IntegrationSegmentType } from '../../utils/type/integrationSegments/integrationSegment.type';

const initialState = integrationStoreAdapter();

const reducer = (state = initialState, action: Action) => {
  switch (action.type) {
    case setIntegration.type: {
      return state.set('integration', integrationAdapter(action.payload));
    }

    case setSegments.type: {
      return state.set('segments', action.payload);
    }

    case setWebhookEvents.type: {
      return state.set('events', webhookEventsObjectAdapter(action.payload));
    }

    case addWebhookEvents.type: {
      return state
        .setIn(['events', 'meta'], metaAdapter(action.payload.meta))
        .updateIn(['events', 'items'], (items: ImmutableArray<WebhookEvent[]>) =>
          items.concat(webhookEventsAdapter(action.payload.items))
        );
    }

    case addSegment.type: {
      return state.update('segments', (segments: ImmutableArray<IntegrationSegmentType[]>) =>
        segments.concat(I([action.payload]))
      );
    }

    case updateSegment.type: {
      return state.update('segments', (segments: ImmutableArray<IntegrationSegmentType[]>) =>
        //@ts-ignore
        segments.map((item: IntegrationSegmentType) => {
          if (item._id === action.payload._id) return action.payload;
          return item;
        })
      );
    }

    case removeSegment.type: {
      return state.update('segments', (segments: ImmutableArray<IntegrationSegmentType[]>) =>
        //@ts-ignore
        segments.filter((item: IntegrationSegmentType) => item._id !== action.payload)
      );
    }

    case clearIntegrationAction.type: {
      return integrationStoreAdapter();
    }

    case setHubSpotConnections.type: {
      return state.set('hubspotConnections', action.payload.map(hubSpotConnectionAdapter));
    }

    case removeHubSpotConnection.type: {
      return state.update('hubspotConnections', (items) =>
        //@ts-ignore
        items.filter((item: HubSpotConnection) => item.id !== action.payload)
      );
    }

    case setMailChimpConnections.type: {
      return state.set('mailchimpConnections', action.payload.map(mailChimpConnectionAdapter));
    }

    case removeMailChimpConnection.type: {
      return state.update('mailchimpConnections', (items) =>
        //@ts-ignore
        items.filter((item: MailChimpConnection) => item.id !== action.payload)
      );
    }

    default:
      return state;
  }
};

export default reducer;
