import React, { Fragment, FunctionComponent, useState } from 'react';
import cx from 'classnames';
import styles from './rte.module.css';
import { Popover } from 'antd';
import Typo from '../../typo';
import { WhiteButton } from '../index';

type Props = {
  shortCodes: string[];
  editorRef: any;
};

const shortCodeHints: Record<string, string> = {
  time: 'Replaced by the number of seconds the user played the game.',
  score: 'Replaced by the score the user achieved in the game.',
  prize_name: 'Replaced by the won prize title.',
  prize_description: 'Replaced by the won prize description.',
  prize_image: 'Replaced by the won prize image.',
  prize_code: 'Replaced by the won prize code.'
};

const RTEShortCodes: FunctionComponent<Props> = ({ shortCodes, editorRef }) => {
  const [activeShortCode, setActiveShortCode] = useState<string | undefined>(undefined);
  return (
    <Popover
      trigger="hover"
      overlayClassName={styles.hintPopover}
      content={
        <Fragment>
          <Typo type="p" className={styles.title}>
            Short codes
          </Typo>
          <div className={styles.buttonsWrapper}>
            {shortCodes.map((item) => (
              <WhiteButton
                onClick={() => editorRef.current?.insertContent(` #${item}# `)}
                className={cx(styles.shortCodeButton, { [styles.active]: item === activeShortCode })}
                onHover={() => setActiveShortCode(item)}
              >
                #{item}#
              </WhiteButton>
            ))}
          </div>
          {activeShortCode && (
            <Typo type="p" className={styles.codeHint}>
              {shortCodeHints[activeShortCode] || 'Replaced by user registration form data.'}
            </Typo>
          )}
        </Fragment>
      }
    >
      <div className={styles.shortCodesButtonWrapper}>
        <WhiteButton className={styles.button}>Short codes</WhiteButton>
      </div>
    </Popover>
  );
};

export default RTEShortCodes;
