import { createSelector } from 'reselect';
import { UiState, UIStoreEnum } from './types';
import { Store } from '../types';

const uiStoreSelector = (store: Store) => store.ui;
export const fontsSelector = createSelector(uiStoreSelector, (ui: UiState) => ui.fonts.asMutable({ deep: true }));
export const spinnerSelector = createSelector(uiStoreSelector, (ui: UiState) => ui.spinner);
export const brandFontsSelector = createSelector(uiStoreSelector, (ui: UiState) =>
  ui.brandFonts.asMutable({ deep: true })
);
export const adactTourSelector = createSelector(uiStoreSelector, (ui: UiState) =>
  ui.adactTour.asMutable({ deep: true })
);
export const disableBodyScrollSelector = createSelector(uiStoreSelector, (ui: UiState) => ui.disableBodyScroll);
export const restrictionModalShowSelector = createSelector(uiStoreSelector, (ui: UiState) => ui.restrictionModalShow);
export const restrictionModalTextSelector = createSelector(uiStoreSelector, (ui: UiState) => ui.restrictionModalText);
export const debugStateSelector = createSelector(uiStoreSelector, (ui: UiState) => ui.debugState);
export const sidebarSelector = createSelector(uiStoreSelector, (ui: UiState) => ui.sidebar);
export const disableSidebarSelector = createSelector(uiStoreSelector, (ui: UiState) => ui.disableSidebar);
export const sidebarTypeSelector = createSelector(uiStoreSelector, (ui: UiState) => ui.sidebarType);
export const fullPreviewSelector = createSelector(uiStoreSelector, (ui: UiState) => ui.fullPreview);
export const uiSelector = <T>(type: UIStoreEnum) =>
  createSelector(uiStoreSelector, (store): T => store[type] as unknown as T);
