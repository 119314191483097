import { createAction } from '../../utils/actions';
import { tokensStoreAdapter } from './adapters';
import { ActionType } from '../../utils/type';
import { Store } from '../types';
import { TokensStoreEnum } from './types';
import { createSelector } from 'reselect';

export const mainSelector = (store: Store) => store.tokens;
export const tokensSelector = <T>(type: TokensStoreEnum) =>
  createSelector(mainSelector, (store): T => store[type] as unknown as T);

export const setTokensField = createAction('tokens/set_field');

const reducer = (state = tokensStoreAdapter(), action: ActionType) => {
  switch (action.type) {
    case setTokensField.type: {
      return state.set(action.path, action.payload);
    }

    default:
      return state;
  }
};

export default reducer;
