import React, { FormEvent, FunctionComponent, useCallback, useState } from 'react';
import { WhiteAutocomplete, WhiteButton, WhiteField, WhiteInput } from '../../../components/form';
import Typo from '../../../components/typo';
import useBrand from '../../../domain/useBrand';
import useOptions from '../../../domain/useOptions';
import styles from './brandCreation.module.css';
import { TIMEZONES_OPTIONS } from '../../../utils/const';
import { Brand } from '../../../utils/type';
import useCurrentUser from '../../../utils/hooks/useCurrentUser';
import Hint from '../../../components/hint/white';

type FormData = {
  title: string;
  language: string;
  id?: number | null;
  timezone: string;
  slug: string;
  default_brand?: boolean;
};

type Props = {
  brand?: Brand;
  onClose?: () => void;
};

const BrandForm: FunctionComponent<Props> = ({ onClose, brand }) => {
  const { create, updateInList, busy, errors, makeSlug } = useBrand();
  const { langsOptions } = useOptions();
  const { paidAccount } = useCurrentUser();

  const [value, onChangeBrand] = useState<FormData>({
    title: brand?.title ?? '',
    language: brand?.language ?? '',
    timezone: brand?.timezone ?? '',
    slug: brand?.slug ?? ''
  });

  const handleChangeBrand = useCallback(
    (field: Partial<FormData>) => {
      if (field.title) {
        field.slug = makeSlug(field.title);
      }

      onChangeBrand({
        ...value,
        ...field
      });
    },
    [makeSlug, value]
  );

  const closeForm = () => {
    if (onClose) onClose();
  };

  const onSubmit = useCallback(
    (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      if (brand && brand.id) {
        const payload: Partial<FormData> = {
          language: value.language,
          timezone: value.timezone
        };

        if (paidAccount) {
          payload.slug = value.slug;
          payload.title = value.title;
          payload.default_brand = false;
        }

        updateInList(brand.id, payload, () => closeForm());
        return;
      }

      create(value, () => closeForm());
    },
    [brand, create, value, paidAccount, updateInList]
  );

  return (
    <form onSubmit={onSubmit}>
      {!paidAccount && brand && (
        <Hint className={styles.hint}>
          You can customize your brand name after making first purchase of a token or a subscription.
        </Hint>
      )}
      <WhiteInput
        errors={errors.title}
        placeholder="Brand name"
        disabled={Boolean(!paidAccount && brand)}
        label="Brand name"
        name="title"
        value={value.title}
        onChange={(event) => handleChangeBrand({ title: event.target.value })}
      />
      <WhiteField name="slug" errors={errors.slug} label="Share your campaigns via:">
        <Typo type="p" white>
          <b>{makeSlug(value.title)}</b>
          <i>.adact.me/yourCampaignName</i>
        </Typo>
      </WhiteField>
      <WhiteAutocomplete
        overflow
        errors={errors.timezone}
        label="Brand timezone"
        placeholder="Choose timezone"
        name="timezone"
        options={TIMEZONES_OPTIONS}
        value={value.timezone}
        onChange={(timezone: string | number) => handleChangeBrand({ timezone: String(timezone) })}
      />
      <WhiteAutocomplete
        overflow
        errors={errors.language}
        label="Brand language"
        placeholder="Choose language"
        name="language"
        options={langsOptions}
        value={value.language}
        onChange={(lang: string | number) => handleChangeBrand({ language: String(lang) })}
      />
      <div className={styles.footer}>
        {onClose && (
          <WhiteButton size="lg" type="ghost" onClick={onClose}>
            Cancel
          </WhiteButton>
        )}
        <WhiteButton
          className={!onClose ? styles.wideButton : undefined}
          size="lg"
          loading={busy}
          htmlType="submit"
          disabled={!value.title || !value.language || !value.timezone}
        >
          {brand ? 'Update' : 'Create brand'}
        </WhiteButton>
      </div>
    </form>
  );
};

export default BrandForm;
