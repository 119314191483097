import React, { FunctionComponent } from 'react';
import AntSlider, { SliderBaseProps } from 'antd/lib/slider';
import cx from 'classnames';
import Field from '../field';
import styles from './input.module.css';

interface Props extends SliderBaseProps {
  className?: string;
  label?: string;
  name: string;
  onBlurCallback?: () => void;
  onChange?: (value: number) => void;
  onBlur?: () => void;
  value?: number;
  postfix?: string;
  disabled?: boolean;
  refProp?: any;
  withoutNumber?: boolean;
  errors?: string[];
}

const Slider: FunctionComponent<Props> = (props) => {
  const {
    className,
    label,
    refProp,
    errors,
    onBlurCallback,
    onChange,
    withoutNumber,
    value,
    step,
    disabled,
    postfix,
    onBlur,
    name,
    ...other
  } = props;
  const multiple = step && step < 1 ? 100 : 1;
  const valueLabel = value && Math.ceil(multiple * value);

  const onMouseRelease = () => {
    if (onBlurCallback) onBlurCallback();
  };

  return (
    <Field className={className} label={label} errors={errors} disabled={disabled}>
      <div className={styles.sliderWrapper} cypress-id={`${name}-component`}>
        <AntSlider
          {...other}
          className={cx(styles.slider, { [styles.disabled]: disabled })}
          tooltipVisible={false}
          step={step}
          disabled={disabled}
          //@ts-ignore
          name={name}
          onBlur={onBlur}
          value={value}
          ref={refProp}
          onAfterChange={onMouseRelease}
          onChange={onChange}
        />
        <div className={cx(styles.sliderValue, { [styles.disabled]: disabled })}>
          {withoutNumber ? '' : valueLabel}
          {postfix}
        </div>
      </div>
    </Field>
  );
};

export default Slider;
