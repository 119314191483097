import { Action } from './types';
import I from 'seamless-immutable';
import {
  activitiesAdapter,
  buttonAndTrafficsAdapter,
  campaignAdapter,
  campaignOneAnalyticAdapter,
  campaignsMetaAdapter,
  campaignsStoreAdapterMapping,
  dailyStatisticsAdapter,
  gameplaysAdapter,
  hoursStatisticAdapter,
  initialStoreAdapter,
  newsPostAdapter,
  playersActivityAdapter,
  topAdapter
} from './adapters';
import {
  addCampaignList,
  addNewsPosts,
  clearCampaigns,
  clearHoursStatistic,
  clearNewsPosts,
  clearOneAnalyticCampaign,
  clearOneDailyStatistic,
  clearOneTopPlayers,
  deleteCampaignFromList,
  removeAnalyticsCampaignGameplays,
  resetOneAnalyticCampaignStatistics,
  setArrayField,
  setButtonsAndTraffics,
  setCampaignList,
  setCampaignsActivities,
  setField,
  setHoursStatistic,
  setLeaderboardData,
  setNewsPosts,
  setOneAnalyticCampaign,
  setOneAnalyticCampaignGameplays,
  setOneAnalyticCampaignPlayersActivity,
  setOneDailyStatistic,
  setOneTopPlayers,
  setResultsData,
  setStatuses,
  updateCampaignTitleInList,
  updateOneAnalyticCampaignGameplays
} from './actions';
import { CampaignWithAnalytics, Gameplay, ListCampaign } from '../../utils/type';

const initialState = initialStoreAdapter();

const reducer = (state = initialState, action: Action) => {
  switch (action.type) {
    case setCampaignList.type: {
      return state
        .set('loaded', true)
        .set('list', action.payload.items.map(campaignAdapter))
        .set('meta', campaignsMetaAdapter(action.payload.meta));
    }

    case addCampaignList.type: {
      return state
        .set('loaded', true)
        .update('list', (list) => list.concat(action.payload.items.map(campaignAdapter)))
        .set('meta', campaignsMetaAdapter(action.payload.meta));
    }

    case setCampaignsActivities.type: {
      return state.set('activities', activitiesAdapter(action.payload));
    }

    case deleteCampaignFromList.type: {
      const relativeToRemovedCampaignList = state.list
        .filter((item: CampaignWithAnalytics) => item.originalCampaignId === action.payload)
        .map((item: CampaignWithAnalytics) => item.id)
        .asMutable({ deep: true });
      return state
        .update('list', (list) =>
          list.filter(
            (item: CampaignWithAnalytics) => ![action.payload, ...relativeToRemovedCampaignList].includes(item.id)
          )
        )
        .updateIn(['meta', 'totalItems'], (total) => total - 1 - relativeToRemovedCampaignList.length);
    }

    case updateCampaignTitleInList.type: {
      return state.update('list', (list) =>
        list.map((item: ListCampaign) => {
          if (item.id === action.payload.id) {
            return campaignAdapter({
              ...item,
              ...(action.payload.title ? { title: action.payload.title } : {}),
              ...(action.payload.folderId ? { folderId: action.payload.folderId } : {}),
              ...(action.payload.favoriteUsers ? { favoriteUsers: action.payload.favoriteUsers } : {})
            });
          }
          return item;
        })
      );
    }

    case setOneAnalyticCampaign.type: {
      return state.set('one', campaignOneAnalyticAdapter(action.payload));
    }

    case resetOneAnalyticCampaignStatistics.type: {
      return state.set('one', action.payload);
    }

    case setOneAnalyticCampaignPlayersActivity.type: {
      return state.set('playersActivity', playersActivityAdapter(action.payload));
    }

    case setOneAnalyticCampaignGameplays.type: {
      return state
        .setIn(['gameplays', 'items'], gameplaysAdapter(action.payload.items))
        .setIn(['gameplays', 'meta'], campaignsMetaAdapter(action.payload.meta));
    }

    case updateOneAnalyticCampaignGameplays.type: {
      return state //@ts-ignore
        .updateIn(['gameplays', 'items'], (items) => items.concat(gameplaysAdapter(action.payload.items)))
        .setIn(['gameplays', 'meta'], campaignsMetaAdapter(action.payload.meta));
    }

    case removeAnalyticsCampaignGameplays.type: {
      return state
        .updateIn(['gameplays', 'items'], (items) =>
          items.filter((item: Gameplay) => !action.payload.includes(item._id))
        )
        .updateIn(['gameplays', 'meta', 'totalItems'], (totalItems) => totalItems - action.payload.length);
    }

    case clearOneAnalyticCampaign.type: {
      return state
        .set('one', I({}))
        .set('gameplays', I({ items: [], meta: {} }))
        .set('buttonsAndTraffics', buttonAndTrafficsAdapter())
        .set('playersActivity', I([]));
    }

    case setButtonsAndTraffics.type: {
      return state.set('buttonsAndTraffics', buttonAndTrafficsAdapter(action.payload));
    }

    case clearCampaigns.type: {
      return state.set('list', I([])).set('loaded', false).set('meta', campaignsMetaAdapter());
    }

    case setOneTopPlayers.type: {
      return state.set('oneTop', topAdapter(action.payload));
    }

    case clearOneTopPlayers.type: {
      return state.set('oneTop', topAdapter());
    }

    case clearOneDailyStatistic.type: {
      return state.set('oneDailyStatistics', []);
    }

    case setOneDailyStatistic.type: {
      return state.set('oneDailyStatistics', dailyStatisticsAdapter(action.payload));
    }

    case clearHoursStatistic.type: {
      return state.set('oneDayStatistics', []);
    }

    case setHoursStatistic.type: {
      return state.set('oneDayStatistics', hoursStatisticAdapter(action.payload));
    }

    case setNewsPosts.type:
      return state
        .setIn(['news', 'items'], action.payload.items.map(newsPostAdapter))
        .setIn(['news', 'meta'], campaignsMetaAdapter(action.payload.meta));

    case addNewsPosts.type:
      return state
        .updateIn(['news', 'items'], (items) => items.concat(action.payload.items.map(newsPostAdapter)))
        .setIn(['news', 'meta'], campaignsMetaAdapter(action.payload.meta));

    case setLeaderboardData.type:
      if (action.payload.meta.currentPage === 1) {
        return state
          .setIn(['leaderboardData', 'items'], action.payload.items)
          .setIn(['leaderboardData', 'meta'], campaignsMetaAdapter(action.payload.meta));
      }
      return state
        .updateIn(['leaderboardData', 'items'], (items) => items.concat(action.payload.items))
        .setIn(['leaderboardData', 'meta'], campaignsMetaAdapter(action.payload.meta));

    case setResultsData.type:
      return state.set('resultsData', action.payload);

    case clearNewsPosts.type:
      return state.set('news', I({ items: [], meta: {} }));

    case setStatuses.type: {
      return state.set('statuses', action.payload);
    }

    case setField.type: {
      if (!action.path) return state;
      const adapter = campaignsStoreAdapterMapping[action.path];

      if (adapter) {
        return state.set(action.path, adapter(action.payload));
      }

      return state.set(action.path, action.payload);
    }

    case setArrayField.type: {
      if (!action.path) return state;
      return state.set(action.path, action.payload.map(campaignsStoreAdapterMapping[action.path]));
    }

    default:
      return state;
  }
};

export default reducer;
