import React, { Fragment, FunctionComponent, ReactElement, useMemo } from 'react';
import cx from 'classnames';
import styles from './white.module.css';

type Props = {
  name: string;
  label?: string;
  typo?: boolean;
  width?: number;
  className?: string;
  errors?: string[];
  inline?: boolean;
  children: ReactElement | ReactElement[];
  bodyClassname?: string;
  errorPosition?: 'bottom';
  alignLabelRight?: boolean;
};

const WhiteField: FunctionComponent<Props> = (props) => {
  const {
    name,
    width,
    typo,
    errors,
    children,
    label,
    className,
    inline,
    bodyClassname,
    errorPosition,
    alignLabelRight
  } = props;

  const body = useMemo(
    () => (
      <Fragment>
        {label || errors ? (
          <div className={styles.errorsLabelWrapper}>
            {label ? (
              <label
                style={{ width: width }}
                className={cx(styles.label, { [styles.alignLabelRight]: alignLabelRight })}
                htmlFor={name}
              >
                {label}
              </label>
            ) : null}
            {errors && errorPosition !== 'bottom' ? <div className={styles.errors}>{errors.join('; ')}</div> : null}
          </div>
        ) : null}
        {typo ? <div className={styles.typo}>{children}</div> : children}
        {errors && errorPosition === 'bottom' ? <div className={styles.errors}>{errors.join('; ')}</div> : null}
      </Fragment>
    ),
    [errors, label, children, typo]
  );

  const inlineBody = useMemo(
    () => (
      <div className={cx(styles.inlineBody, bodyClassname)}>
        {label ? (
          <div className={styles.errorsLabelWrapper}>
            <label
              style={{ width: width }}
              className={cx(styles.label, { [styles.alignLabelRight]: alignLabelRight })}
              htmlFor={name}
            >
              {label}
            </label>
          </div>
        ) : null}
        {typo ? <div className={styles.typo}>{children}</div> : children}
      </div>
    ),
    [label, children, typo, bodyClassname]
  );

  return (
    <div className={cx(styles.wrapper, className, { [styles.withError]: errors, [styles.inline]: inline })}>
      {inline ? (
        <div className={styles.inlineBodyWrapper}>
          {errors ? <div className={styles.errors}>{errors.join('; ')}</div> : null}
          {inlineBody}
        </div>
      ) : (
        body
      )}
    </div>
  );
};
export default WhiteField;
