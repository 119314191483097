import { ImmutableObject } from 'seamless-immutable';
import { Pagination, ResourceMeta } from '../types';
import {
  ButtonsAndTraffics,
  CampaignActivity,
  CampaignStatus,
  CampaignWithAnalytics,
  DailyHourStatistic,
  DailyStatistic,
  EmailDailyStatistic,
  EmailStatistic,
  Gameplay,
  LeaderboardDataType,
  ListCampaign,
  NewsPost,
  PlayerActivity,
  ResultsDataType
} from '../../utils/type';
import { TopPlayers } from '../../utils/type/campaigns/statistics/topPlayers.type';

export enum CampaignsStoreEnum {
  DAILY_EMAIL_STATISTICS = 'dailyEmailStatistics',
  EMAIL_STATISTICS = 'emailStatistics',
  LEADERBOARD_DATA = 'leaderboardData',
  RESULTS_DATA = 'resultsData'
}

export type Action = {
  type: string;
  payload: any;
  path?: CampaignsStoreEnum;
  compareField?: string;
};

export type CampaignsState = ImmutableObject<{
  list: ListCampaign[];
  one: CampaignWithAnalytics;
  oneTop: TopPlayers;
  oneDailyStatistics: DailyStatistic[];
  oneDayStatistics: DailyHourStatistic[];
  news: Pagination<NewsPost>;
  activities: Pagination<CampaignActivity>;
  buttonsAndTraffics: ButtonsAndTraffics;
  playersActivity: PlayerActivity[];
  gameplays: Pagination<Gameplay>;
  meta: ResourceMeta;
  statuses: CampaignStatus[];
  loaded: boolean;
  [CampaignsStoreEnum.DAILY_EMAIL_STATISTICS]: EmailDailyStatistic[];
  [CampaignsStoreEnum.EMAIL_STATISTICS]: EmailStatistic;
  [CampaignsStoreEnum.LEADERBOARD_DATA]: Pagination<LeaderboardDataType>;
  [CampaignsStoreEnum.RESULTS_DATA]: ResultsDataType;
}>;
