import React, { ChangeEvent, FunctionComponent, ReactText, useCallback } from 'react';
import cx from 'classnames';
import styles from './checkbox.module.css';

type Props = {
  name: string;
  checked: boolean;
  onChange?: (checked: boolean) => void;
  label?: string | ReactText;
  className?: string;
  errors?: string[];
  required?: boolean;
  disabled?: boolean;
  inputClassName?: string;
};

const WhiteCheckbox: FunctionComponent<Props> = (props) => {
  const { name, checked, onChange, label, required, disabled, className, inputClassName } = props;

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (onChange) onChange(event.target.checked);
    },
    [onChange]
  );

  return (
    <label className={cx(styles.wrapper, className)}>
      <div className={cx(styles.togglerWrapper, { [styles.active]: checked }, inputClassName)}>
        <input
          type="checkbox"
          cypress-id={`${name}-component`}
          required={required}
          className={styles.checkbox}
          onChange={handleChange}
          disabled={disabled}
          checked={checked}
          name={name}
        />
        <span className={styles.spotWrapper} />
      </div>
      {label ? (
        <div className={styles.errorsLabelWrapper}>
          {label ? (
            <label className={styles.label} htmlFor={name}>
              {label}
            </label>
          ) : null}
        </div>
      ) : null}
    </label>
  );
};

export default WhiteCheckbox;
