import { useCallback, useState } from 'react';
import { message, notification } from 'antd';
import { AxiosError, AxiosResponse } from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import useApi from '../useApi';
import {
  integrationSelector,
} from '../../store/integration/selectors';
import {
  clearIntegrationAction,
  setIntegration,
} from '../../store/integration/actions';
import { IntegrationType } from '../../store/integration/types';
import errorParser, { Errors } from '../../utils/errors';
import * as T from '../requestTypes';

type iUseIntegration = {
  getIntegration: () => void;
  clearIntegration: () => void;
  errors: Errors;
  clearErrors: () => void;
  integration: IntegrationType;
  loaded: boolean;
  busy: boolean;
  createIntegration: (data: T.CreateIntegrationData) => void;
  updateIntegration: (data: T.CreateIntegrationData, cb?: () => void) => void;
};

export type EventDetails = {
  accountId: number;
  body: string;
  createdAt: string;
  errorMessage?: string;
  status: string;
  type: string;
  updatedAt: string;
  campaignId: number;
  _id: string;
};

const useIntegration = (): iUseIntegration => {
  const dispatch = useDispatch();
  const [loaded, onChangeLoaded] = useState<boolean>(false);
  const [busy, onChangeBusy] = useState<boolean>(false);
  const integration = useSelector(integrationSelector);
  const [errors, onChangeErrors] = useState({});

  const api = useApi();

  const getIntegration = useCallback(() => {
    api
      .getAccountIntegration()
      .then((response: AxiosResponse) => {
        onChangeLoaded(true);
        dispatch(setIntegration(response.data));
      })
      .catch((error: AxiosError) => {
        if (error.response?.status === 404) {
          onChangeLoaded(true);
        }
      });
  }, []);

  const createIntegration = useCallback((data: T.CreateIntegrationData) => {
    onChangeErrors({});
    onChangeBusy(true);
    api
      .createAccountIntegration(data)
      .then((response: AxiosResponse) => {
        message.success(Object.keys(data).length === 0 ? 'API key generated' : 'Webhooks updated');
        dispatch(setIntegration(response.data));
        onChangeBusy(false);
      })
      .catch(() => {
        onChangeBusy(false);
      });
  }, []);

  const updateIntegration = useCallback((data: T.CreateIntegrationData, cb?: () => void) => {
    onChangeErrors({});
    api
      .updateAccountIntegration(data)
      .then((response: AxiosResponse) => {
        message.success('Webhooks updated');
        dispatch(setIntegration(response.data));
        if (cb) cb();
      })
      .catch((error: AxiosError) => {
        onChangeBusy(false);
        if (error.response?.data.statusCode === 422) {
          const errors = errorParser(error.response?.data);
          onChangeErrors(errors);
        }
        if (error.response?.data.statusCode === 400) {
          notification.error({
            message: 'Error',
            description: error.response?.data?.message
          });
        }
      });
  }, []);

  const clearIntegration = useCallback(() => {
    dispatch(clearIntegrationAction());
  }, []);

  const clearErrors = useCallback(() => {
    onChangeErrors({});
  }, []);

  return {
    getIntegration,
    clearErrors,
    clearIntegration,
    busy,
    loaded,
    integration,
    createIntegration,
    errors,
    updateIntegration,
  };
};

export default useIntegration;
