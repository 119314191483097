import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import AntSlider, { SliderBaseProps } from 'antd/lib/slider';
import cx from 'classnames';
import styles from './input.module.css';
import { WhiteField } from '../index';
import InputType from '../../../utils/type/input.type';
import { debounce } from 'lodash';

interface Props extends SliderBaseProps, InputType {
  className?: string;
  label?: string;
  name: string;
  onBlurCallback?: () => void;
  onChange?: (value: number) => void;
  onBlur?: () => void;
  value?: number;
  postfix?: string;
  disabled?: boolean;
  labelWidth?: number;
  refProp?: any;
  withoutNumber?: boolean;
  errors?: string[];
  inline?: boolean;
}

const WhiteSlider: FunctionComponent<Props> = (props) => {
  const {
    className,
    label,
    refProp,
    errors,
    labelWidth,
    onBlurCallback,
    onChange,
    withoutNumber,
    value,
    step,
    disabled,
    postfix,
    onBlur,
    name,
    inline,
    changeConfigField,
    ...other
  } = props;
  const [inputValue, setInputValue] = useState<number | undefined>(value);
  const [dirty, setDirty] = useState<boolean>(false);
  const multiple = useMemo(() => (step && step < 1 ? 100 : 1), [step]);
  const valueLabel = useMemo(() => inputValue && Math.ceil(multiple * inputValue), [inputValue, multiple]);

  useEffect(() => {
    if (value !== inputValue) setInputValue(value);
  }, [value]);

  const onMouseRelease = useCallback(() => {
    if (onBlurCallback) onBlurCallback();
  }, [onBlurCallback]);

  const changeValue = (value: any) => {
    setInputValue(value);
    if (onChange) onChange(value);
    setDirty(true);
  };

  const debounceUpdate = debounce(() => {
    if (changeConfigField && dirty) {
      changeConfigField({ [name]: inputValue });
    }
  }, 500);

  useEffect(
    () => {
      debounceUpdate();
      return () => {
        debounceUpdate.cancel();
      };
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [inputValue]
  );

  return (
    <WhiteField width={labelWidth} {...props}>
      <div className={styles.sliderWrapper} cypress-id={`${name}-component`}>
        <AntSlider
          {...other}
          className={cx(styles.slider, { [styles.disabled]: disabled })}
          tooltipVisible={false}
          step={step}
          disabled={disabled}
          //@ts-ignore
          name={name}
          onBlur={onBlur}
          value={inputValue}
          ref={refProp}
          onAfterChange={onMouseRelease}
          onChange={changeValue}
        />
        {!withoutNumber && (
          <div className={cx(styles.sliderValue, { [styles.disabled]: disabled })}>
            {valueLabel}
            {postfix}
          </div>
        )}
      </div>
    </WhiteField>
  );
};

export default WhiteSlider;
