import { useLocation } from 'react-router-dom';
import queryString, { ParsedQuery } from 'query-string';

export default function useQuery(): ParsedQuery {
  return queryString.parse(useLocation().search);
}

export function queryToString(query: Record<string, any>, arrayFormat: any = 'bracket') {
  return queryString.stringify(query, { arrayFormat, skipEmptyString: true });
}
