export enum GameSettingTypesEnum {
  DROP_GAME = 'dropGame',
  WHEEL_OF_FORTUNE = 'wheelOfFortune',
  TRIVIA = 'trivia',
  SMASH_GAME = 'smashGame',
  VERTICAL_SCROLLER = 'verticalScroller',
  MATCH3 = 'match3',
  TOWER_STACKING = 'towerStacking',
  ENDLESS_RUNNER = 'endlessRunner',
  SCRATCHCARD = 'scratchcard',
  PERSONALITY_TEST = 'personalityTest',
  MEMORY = 'memory',
  HOOPS = 'hoops',
  WHACK_A_MOLE = 'whackAMole',
  PUZZLE = 'puzzle',
  THROWING = 'throwing',
  SEQUENCE = 'sequence',
  JEOPARDY = 'jeopardy',
  TAPPING = 'tapping',
  HOTSPOT = 'hotspot',
  VIDEO_TRIVIA = 'videoTrivia',
  MULTIPLE_VIDEO_TRIVIA = 'multipleVideoTrivia',
  PLINKO = 'plinko',
  FLAPPY_BIRD = 'flappyBird',
  PICTURE_REVEAL = 'pictureReveal',
  STACKING = 'stacking',
  ADVENT_CALENDAR = 'adventCalendar',
  HIDDEN_OBJECT = 'hiddenObject',
  OPEN_THE_BOX = 'openTheBox',
  SWIPE_POLL = 'swipePoll',
  BATTLE = 'battle',
  SURVEY = 'survey',
  MULTIPLAYER_TRIVIA = 'multiplayerTrivia',
  COMMUNITY_CONTEST = 'communityContest',
  WORDLE = 'wordle',
  MULTI_PATH_QUIZ = 'multiPathQuiz',
  BUBBLE_SHOOTER = 'bubbleShooter',
  STICK_NINJA = 'stickNinja',
  WORD_SEARCH = 'wordSearch',
  PREDICTION = 'prediction',
  TRIVIA_BUILDER = 'triviaBuilder',
  TIC_TAC_TOE = 'ticTacToe',
  SLIDING_PUZZLE = 'slidingPuzzle',
  SNAKE = 'snake'
}
