import { useCallback, useEffect, useMemo } from 'react';
import { isUndefined } from 'lodash';
import useCampaign from '../../../domain/useCampaign';
import { usePageQuery } from '../usePageQuery';

const useWordSearch = (
  setGameConfigs: (config: Record<string, any>) => void,
  gameConfigs: Record<string, any>,
  gameSettings: Record<string, any>
) => {
  const { setNewQuery, query } = usePageQuery();
  const { update, campaign } = useCampaign();

  const onWordSearchWordDrag = useCallback(
    (words: Record<string, any>) => {
      setGameConfigs({ ...gameConfigs, questions: [{ ...gameConfigs.questions[0], words }] });
    },
    [gameConfigs, setGameConfigs]
  );

  const onWordSearchWordDragEnd = useCallback(() => {
    update(campaign.id, { game_config: JSON.stringify(gameConfigs) });
    setNewQuery({ wordSearchIdx: undefined });
  }, [campaign.id, gameConfigs, setNewQuery, update]);

  useEffect(() => {
    setGameConfigs(gameSettings);
  }, [gameSettings, setGameConfigs]);

  const activeWordIdx = useMemo(
    () => (!isUndefined(query?.wordSearchIdx) ? Number(query?.wordSearchIdx) : undefined),
    [query.wordSearchIdx]
  );

  const setActiveWordIdx = useCallback(
    (newIdx: number) => {
      setNewQuery({ wordSearchIdx: newIdx });
    },
    [setNewQuery]
  );

  const debugWords = useMemo(() => query.tab !== 'visuals', [query.tab]);

  return debugWords
    ? {
        setActiveWordIdx,
        onWordSearchWordDragEnd,
        onWordSearchWordDrag,
        debugWords,
        activeWordIdx
      }
    : {};
};

export default useWordSearch;
