import React, { Fragment, FunctionComponent, useState } from 'react';
import Icon from '../../../icon';
import Typo from '../../../typo';
import styles from './editModal.module.css';
import UNREZIZABLE_MIME_TYPES from '../../../../utils/const/unresizable-mime-types';
import { isUndefined } from 'lodash';
import WhiteButton from '../../whiteButton';
import { MediaFile } from '../../../../utils/type';

type Props = {
  cancelUpload: () => void;
  fileUrls: any[];
  files: File[];
  progress: number;
  deleteImage: (idx: number) => void;
  upload: (idx: number) => Promise<MediaFile | null>;
  setActiveFile: (idx: number) => void;
  closeForm: () => void;
};

const MultiFileUpload: FunctionComponent<Props> = (props) => {
  const { fileUrls, files, progress, cancelUpload, deleteImage, setActiveFile, upload, closeForm } = props;
  const [currentImageUploaded, setCurrentImageUploaded] = useState<number | undefined>(undefined);
  const [uploadedFilesMapping, setUploadedFilesMapping] = useState<Record<number, boolean>>({});
  const [uploading, setUploading] = useState<boolean>(false);

  const reUpload = async (idx: number) => {
    delete uploadedFilesMapping[idx];
    setUploadedFilesMapping(uploadedFilesMapping);

    setUploading(true);

    setCurrentImageUploaded(idx);
    const success = await upload(idx);
    setUploadedFilesMapping({
      ...uploadedFilesMapping,
      [idx]: Boolean(success)
    });

    setCurrentImageUploaded(undefined);
    setUploading(false);
    if (success) {
      closeForm();
    }
  };

  const onUpload = async () => {
    if (files.length) {
      let filesMapping: Record<number, boolean> = {};
      setUploading(true);
      for (let i = 0; i < files.length; i++) {
        setCurrentImageUploaded(i);
        const success = await upload(i);
        filesMapping = { ...filesMapping, [i]: Boolean(success) };
        setUploadedFilesMapping(filesMapping);
      }

      setCurrentImageUploaded(undefined);
      setUploading(false);
      closeForm();
    }
  };

  const getFileSize = (size: number) => {
    const sizeInKb = size / 1000;
    return sizeInKb > 1000 ? `${Math.round(sizeInKb) / 1000}MB` : `${Math.round(sizeInKb)}KB`;
  };

  const onCancel = () => {
    setCurrentImageUploaded(undefined);
    cancelUpload();
  };

  return (
    <Fragment>
      {fileUrls.map((fileUrl: any, idx: number) => (
        <div className={styles.rowWrapper} key={idx}>
          <div className={styles.titleWrapper}>
            <img className={styles.rowImage} src={fileUrl as string} alt={`file-${idx}`} />
            <div className={styles.imageInfo}>
              <Typo type="p" className={styles.title}>
                {files[idx].name}
              </Typo>
              {!isUndefined(uploadedFilesMapping[idx]) && !uploadedFilesMapping[idx] ? (
                <Typo type="p" className={styles.failed}>
                  Upload failed
                </Typo>
              ) : (
                <Typo type="p" className={styles.size}>
                  {getFileSize(files[idx].size)}
                </Typo>
              )}
              {(currentImageUploaded === idx || uploadedFilesMapping[idx]) && (
                <div className={styles.progressWrapper}>
                  <div
                    className={styles.barStyles}
                    style={{ width: `${uploadedFilesMapping[idx] ? 100 : progress}%` }}
                  />
                  <div className={styles.barLabel}>{uploadedFilesMapping[idx] ? 100 : progress}%</div>
                </div>
              )}
            </div>
          </div>
          <div className={styles.buttonsWrapper}>
            {isUndefined(uploadedFilesMapping[idx]) ? (
              currentImageUploaded !== idx ? (
                <>
                  <WhiteButton
                    icon="edit_white"
                    className={styles.editButton}
                    size="xs"
                    onClick={() => setActiveFile(idx)}
                    disabled={UNREZIZABLE_MIME_TYPES.includes(files?.[idx]?.type) || uploading}
                  >
                    Edit
                  </WhiteButton>
                  <WhiteButton
                    className={styles.deleteButton}
                    size="xs"
                    disabled={uploading}
                    icon="trash"
                    type="ghost"
                    onClick={() => deleteImage(idx)}
                  >
                    Delete
                  </WhiteButton>
                </>
              ) : (
                <Icon icon="close_square_black" className={styles.icon} onClick={onCancel} />
              )
            ) : uploadedFilesMapping[idx] ? (
              <Icon icon="check_blue" className={styles.icon} />
            ) : (
              <WhiteButton
                icon="reload"
                type="ghost"
                className={styles.wideButton}
                disabled={uploading}
                onClick={() => reUpload(idx)}
              >
                Try again
              </WhiteButton>
            )}
          </div>
        </div>
      ))}

      <div className={styles.footerWrapper}>
        <div className={styles.footer}>
          <WhiteButton className={styles.footerBtn} type="ghost" onClick={closeForm}>
            Cancel
          </WhiteButton>
          <WhiteButton className={styles.footerBtn} onClick={onUpload} disabled={!files.length}>
            Upload
          </WhiteButton>
        </div>
      </div>
    </Fragment>
  );
};

export default MultiFileUpload;
