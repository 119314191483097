import styles from './inlineborderradius.module.css';
import React, { FunctionComponent } from 'react';
import useOptions from '../../../domain/useOptions';
import { WhiteRadioGroup, WhiteSelect } from '../index';
import InputType from '../../../utils/type/input.type';

type Props = InputType & {
  value: number | undefined;
  onChange?: (value: number) => void;
  disabled?: boolean;
};

const InlineBorderWidth: FunctionComponent<Props> = (props) => {
  const { borderWidthOptions, newBorderWidthOptions } = useOptions();

  return (
    <div className={styles.shortField}>
      <div className={styles.elementWrapper}>
        <WhiteRadioGroup options={newBorderWidthOptions} className={styles.input} {...props} />
      </div>
      <div className={styles.elementWrapper}>
        <WhiteSelect options={borderWidthOptions} inputClassName={styles.select} className={styles.input} {...props} />
      </div>
    </div>
  );
};

export default InlineBorderWidth;
