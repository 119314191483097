import { useCallback, useEffect, useState } from 'react';
import axios, { AxiosError, AxiosResponse, CancelTokenSource } from 'axios';
import { message, notification } from 'antd';
import useApi from './useApi';

type FileType = 'gameBackground' | 'gameSettings' | 'favicon' | 'newsPost' | 'audio' | 'gamePreview';
type EmbedType = 'common' | 'leaderboard';

type iUseUpload = {
  upload: (file: File, type: FileType, width?: number, height?: number) => Promise<string | null>;
  resize: (url: string, width: number, height: number) => void;
  uploadFont: (file: File) => void;
  uploadEmbed: (file: File, type: EmbedType) => void;
  getVideoAvailability: (id: number) => void;
  videoReady: boolean;
  cancelUpload: () => void;
  clearUrl: () => void;
  uploadVideo: (id: number | undefined, file: File) => void;
  removeBackground: (file: File) => void;
  removedBgImage: string;
  removedBgReady: boolean;
  url: string;
  progress: number;
  isUploading: boolean;
  onChangeVideoReady: (ready: boolean) => void;
  uploadFilesHubSpot: (files: File[], cb: (fileData: Record<string, any>[]) => void) => void;
};

const useUpload = (): iUseUpload => {
  const api = useApi();
  const [removedBgImage, onChangeRemovedBgImage] = useState<string>('');
  const [removedBgReady, onChangeRemovedBgReady] = useState<boolean>(true);
  const [value, onChange] = useState<string>('');
  const [progress, onChangeProgress] = useState<number>(0);
  const [checkVideoId, onChangeCheckVideoId] = useState<number>();
  const [source, onChangeCancelTokenSource] = useState<CancelTokenSource | undefined>(undefined);
  const [videoReady, onChangeVideoReady] = useState<boolean>(true);

  const onUploadProgress = useCallback(
    (progressEvent: any) => {
      const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      onChangeProgress(percentCompleted);
    },
    [onChangeProgress]
  );

  const upload = useCallback(
    async (file: File, type: FileType, width?: number, height?: number): Promise<null | string> => {
      onChangeProgress(0);
      const formData = new FormData();
      formData.append('file', file);
      formData.append('file_type', type);
      if (width) formData.append('width', `${width}`);
      if (height) formData.append('height', `${height}`);
      const source = axios.CancelToken.source();
      onChangeCancelTokenSource(source);

      let fileUrl: string | null = null;

      try {
        const response = await api.upload(formData, { onUploadProgress, cancelToken: source.token });
        onChangeProgress(0);
        fileUrl = response.data.url;
        onChange(response.data.url);
      } catch (error) {
        if (axios.isCancel(error)) {
          message.info('Upload was cancelled');
          return null;
        }
        message.error(error.response?.data?.message);
      }

      onChangeProgress(0);

      return fileUrl;
    },
    [onChangeProgress, onChange, onChangeCancelTokenSource, onUploadProgress]
  );

  const resize = (url: string, width: number, height: number) => {
    // api.resize({
    //   url,
    //   width: Number(width),
    //   height: Number(height)
    // })
    //   .then((response: AxiosResponse) => {
    //     onChange(response.data.url);
    //   })
    //   .catch((error: AxiosError) => {
    //     message.error(error.response?.data?.message);
    //   });
  };

  const uploadFont = useCallback(
    (file: File) => {
      onChangeProgress(0);
      const formData = new FormData();
      formData.append('file', file);
      const source = axios.CancelToken.source();
      onChangeCancelTokenSource(source);
      api
        .uploadFont(formData, { onUploadProgress, cancelToken: source.token })
        .then((response: AxiosResponse) => {
          onChange(response.data.url);
          onChangeProgress(0);
        })
        .catch((error: AxiosError) => {
          onChangeProgress(0);
          if (axios.isCancel(error)) {
            message.info('Upload was cancelled');
            return;
          }
          message.error(error.response?.data?.message);
        });
    },
    [onChangeProgress, onChangeCancelTokenSource, onChange, onUploadProgress]
  );

  const uploadEmbed = useCallback(
    (file: File, type: EmbedType) => {
      onChangeProgress(0);
      const formData = new FormData();
      formData.append('file', file);
      formData.append('scriptType', type);
      const source = axios.CancelToken.source();
      onChangeCancelTokenSource(source);
      api
        .uploadEmbedScript(formData, { onUploadProgress, cancelToken: source.token })
        .then(() => {
          const filename = type === 'common' ? 'embedded-script.js' : 'leaderboard-embedded-script.js';
          onChange(filename);
          onChangeProgress(0);
          const text =
            type === 'common'
              ? 'New embed script was successfully uploaded'
              : 'New leaderboard embed script was successfully uploaded';
          message.success(text);
        })
        .catch((error: AxiosError) => {
          onChangeProgress(0);
          if (axios.isCancel(error)) {
            message.info('Upload was cancelled');
            return;
          }
          message.error(error.response?.data?.message);
        });
    },
    [onChangeProgress, onChangeCancelTokenSource, onChange, onUploadProgress]
  );

  const uploadVideo = useCallback(
    (id: number | undefined, file: File) => {
      onChangeProgress(0);
      const source = axios.CancelToken.source();
      onChangeCancelTokenSource(source);
      onChangeVideoReady(false);
      api.getVideoPreSignUrl().then((response: AxiosResponse) => {
        api
          .uploadVideoToS3(response.data.url, file, { onUploadProgress, timeout: 1800000, cancelToken: source.token })
          .then(() => {
            api
              .uploadVideo(
                { campaignId: id, s3Key: response.data.key },
                { onUploadProgress, timeout: 1800000, cancelToken: source.token }
              )
              .then((response: AxiosResponse) => {
                onChange(response.data.videoId);
                onChangeProgress(0);
              })
              .catch((error: AxiosError) => {
                onChangeProgress(0);
                if (axios.isCancel(error)) {
                  message.info('Upload was cancelled');
                  return;
                }
                message.error(error.response?.data?.message);
              });
          });
      });
    },
    [onChangeProgress, onChange, onChangeCancelTokenSource, onUploadProgress]
  );

  const checkVideoAvailabilityInterval = useCallback((id: number) => {
    api
      .checkVideoAvailability(id)
      .then(() => {
        onChangeVideoReady(true);
        onChangeCheckVideoId(undefined);
      })
      .catch(() => {
        onChangeVideoReady(false);
      });
  }, []);

  useEffect(() => {
    let timerId: NodeJS.Timeout;
    if (checkVideoId) timerId = setInterval(() => checkVideoAvailabilityInterval(checkVideoId), 5000);
    return () => {
      if (timerId) {
        clearInterval(timerId);
      }
    };
  }, [checkVideoId]);

  const getVideoAvailability = useCallback(
    (id: number) => {
      api
        .checkVideoAvailability(id)
        .then(() => {
          onChangeVideoReady(true);
        })
        .catch(() => {
          onChangeVideoReady(false);
          onChangeCheckVideoId(id);
        });
    },
    [onChangeVideoReady, onChangeCheckVideoId]
  );

  const cancelUpload = useCallback(() => {
    if (source) source.cancel();
  }, [source]);

  const clearUrl = useCallback(() => {
    onChange('');
  }, [onChange]);

  const removeBackground = useCallback((file: File) => {
    const formData = new FormData();
    onChangeRemovedBgReady(false);
    formData.append('file', file);
    api
      .removeFileBackground(formData)
      .then((response: AxiosResponse) => {
        onChangeRemovedBgReady(true);
        onChangeRemovedBgImage(`data:image/png;base64,${response.data}`);
      })
      .catch((error) => {
        onChangeRemovedBgReady(true);
        if (error.response.data?.message) {
          notification.error({
            message: 'Error',
            description: error.response.data?.message
          });
        } else {
          message.error('Removing image background failed');
        }
      });
  }, []);

  const uploadFilesHubSpot = useCallback(
    (files: File[], cb: (fileIds: Record<string, any>[]) => void) => {
      onChangeProgress(0);
      const source = axios.CancelToken.source();
      onChangeCancelTokenSource(source);
      const formData = new FormData();
      files.forEach((file: File) => {
        formData.append('files[]', file);
      });

      api
        .uploadFilesHubSpot(formData, { onUploadProgress, cancelToken: source.token })
        .then((response: AxiosResponse) => {
          onChangeProgress(0);
          cb(response.data);
        })
        .catch((error: AxiosError) => {
          onChangeProgress(0);
          if (axios.isCancel(error)) {
            message.info('Upload was cancelled');
            return;
          }
          message.error(error.response?.data?.message);
        });
    },
    [api, onUploadProgress]
  );

  return {
    upload,
    getVideoAvailability,
    uploadVideo,
    videoReady,
    uploadEmbed,
    clearUrl,
    progress,
    isUploading: progress > 0,
    cancelUpload,
    uploadFont,
    resize,
    url: value,
    removedBgImage,
    removeBackground,
    removedBgReady,
    onChangeVideoReady,
    uploadFilesHubSpot
  };
};

export default useUpload;
