import React, { FunctionComponent, ReactElement, useCallback, useMemo, useState } from 'react';
import ReactDOM from 'react-dom';
import cx from 'classnames';
import { debounce } from 'lodash';
import { WhiteButton } from '../form';
import Typo from '../typo';
import addy from './addy.svg';
import ground from './ground.png';
import close from './close.svg';
import mail from './mail.svg';
import styles from './confirm.module.css';

type Icons = 'mail';

type Props = {
  title: string;
  content: string | ReactElement;
  onOk?: () => void;
  className?: string;
  onCancel?: () => void;
  onClose?: () => void;
  icon?: Icons;
  okText?: string;
  onFirstOk?: string | ReactElement;
  cancelText?: string;
};

interface AddyConfirmModalProps extends Props {
  onDelete: () => void;
}

const AddyConfirmModal: FunctionComponent<AddyConfirmModalProps> = (props) => {
  const [closing, onChangeClosing] = useState<boolean>(false);
  const [hasSubmitted, setHasSubmitted] = useState<boolean>(false);

  const onCancel = useCallback(() => {
    if (props.onCancel) props.onCancel();
    props.onDelete();
    onChangeClosing(true);
  }, []);

  const onClose = useCallback(() => {
    if (props.onClose) props.onClose();
    props.onDelete();
    onChangeClosing(true);
  }, []);

  const handleCloseWithOk = useCallback(() => {
    if (props.onFirstOk && !hasSubmitted) {
      setHasSubmitted(true);
      return;
    }
    if (props.onOk) props.onOk();
    props.onDelete();
    onChangeClosing(true);
  }, [hasSubmitted, props]);

  const icons: Record<Icons, any> = useMemo(
    () => ({
      mail
    }),
    []
  );

  return (
    <div className={cx(styles.wrapper, props.className, { [styles.closing]: closing })}>
      <div className={styles.modal}>
        <button className={styles.close} onClick={onClose} type="button">
          <img src={close} alt="close" draggable={false} />
        </button>
        <img src={ground} className={styles.ground} alt="ground" draggable={false} />
        <img src={props.icon ? icons[props.icon] : addy} className={styles.addy} alt="addy" draggable={false} />
        <div className={styles.body}>
          <Typo type="h1" bold inter className={styles.title}>
            {props.title}
          </Typo>
          <Typo type="h3" inter className={styles.description}>
            {props.content}
          </Typo>
          {hasSubmitted && props.onFirstOk}
          <div className={styles.btnsWrapper}>
            <WhiteButton type="yellow" size="lg" onClick={handleCloseWithOk}>
              {props.okText || 'Ok'}
            </WhiteButton>
            {props.cancelText ? (
              <WhiteButton type="link" className={styles.cancelBtn} onClick={onCancel}>
                {props.cancelText}
              </WhiteButton>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

const AddyConfirm = (props: Props) => {
  const elem = document.getElementById('adact-confirm');

  const deleteFromDOM = debounce(() => {
    if (elem) ReactDOM.unmountComponentAtNode(elem);
  }, 200);

  if (!elem) return null;
  if (elem) return ReactDOM.render(<AddyConfirmModal {...props} onDelete={deleteFromDOM} />, elem);
};

export default AddyConfirm;
