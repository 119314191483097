import React, { forwardRef, FunctionComponent, Ref, useCallback, useMemo, useState } from 'react';
import DatePicker from 'react-datepicker';
import cx from 'classnames';
import WhiteField from '../field/white';
import WhiteInput from '../whiteInput';
import styles from './input.module.css';

type Props = {
  name: string;
  value: string | undefined;
  onChange: (value: Date) => void;
  label?: string;
  placeholder?: string;
  className?: string;
  onBlur?: (value: Date) => void;
  hours?: boolean;
  format?: string;
  minDate?: Date | null | undefined;
  maxDate?: Date | null | undefined;
  errors?: string[];
  filterTime?: (time: Date) => boolean;
  required?: boolean;
  disabled?: boolean;
  inline?: boolean;
  labelWidth?: number;
  tiny?: boolean;
  inputClassName?: string;
  timeIntervals?: number;
  errorPosition?: 'bottom';
};

function isSameDay(date1: Date, date2: Date) {
  return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate()
  );
}

const WhiteDatepicker: FunctionComponent<Props> = (props) => {
  const {
    name,
    value,
    onChange,
    label,
    required,
    disabled,
    format = 'dd-MM-yyyy',
    onBlur,
    hours,
    inline,
    tiny,
    className,
    labelWidth,
    inputClassName,
    placeholder,
    errors,
    minDate,
    filterTime,
    timeIntervals,
    errorPosition,
    maxDate
  } = props;

  const selected = useMemo(() => (value ? new Date(value) : undefined), [value]);
  const [stateHasFocus, setStateHasFocus] = useState(false);

  const CustomInput = forwardRef<Ref<any>, any>((props, ref) => {
    const { autoFocus, ...other } = props;
    return (
      <WhiteInput
        focus={stateHasFocus}
        {...other}
        name={`${name}-datepicker`}
        tiny={inline || tiny}
        className={cx(styles.datepickerinput, inputClassName, { [styles.error]: Boolean(errors) })}
        placeholder={placeholder}
        readOnly={format !== 'dd-MM-yyyy'}
        icon="calendar"
        ref={ref}
        autoComplete="off"
        isDateInput
      />
    );
  });

  const handleKeyPress = useCallback(
    (event: any) => {
      const key = event.keyCode || event.which;
      if (key === 13) {
        const selectedDate = value ? new Date(value) : new Date();
        const [day, month, year] = event.target.value.split('-');
        if (isNaN(Number(day)) || isNaN(Number(month)) || isNaN(Number(year))) return;
        selectedDate.setFullYear(Number(year), Number(month) - 1, Number(day));

        onChange(selectedDate);
        if (onBlur && hours) onBlur(selectedDate);
        setStateHasFocus(false);
      }
    },
    [value, onChange, onBlur, hours]
  );

  const handleOnChange = useCallback(
    (value: Date, event: any) => {
      // IF on change from time picker or date calendar then update
      if (!event?.target?.name || (event?.target?.name && event.target.name !== `${name}-datepicker`)) {
        onChange(value);
        if (onBlur && hours) onBlur(value);
        setStateHasFocus(false);
      }
    },
    [onChange, onBlur, hours, name]
  );

  const handleOnSelect = useCallback(
    (value: Date) => {
      if (onBlur && !hours) onBlur(value);
    },
    [hours, onBlur]
  );

  const startOfDay = new Date();
  startOfDay.setHours(0, 0, 0, 0);

  const endOfDay = new Date();
  endOfDay.setHours(23, 59, 59, 999);

  const minTime = minDate && selected && isSameDay(new Date(selected), minDate) ? minDate : startOfDay;
  const maxTime = maxDate && selected && isSameDay(new Date(selected), maxDate) ? maxDate : endOfDay;

  return (
    <WhiteField
      width={labelWidth}
      inline={inline}
      className={className}
      name={name}
      errors={errors}
      errorPosition={errorPosition}
      label={label}
    >
      <DatePicker
        minDate={minDate}
        minTime={minTime}
        maxDate={maxDate}
        maxTime={maxTime}
        filterTime={filterTime}
        timeFormat="HH:mm"
        customInput={<CustomInput />}
        showTimeSelect={hours}
        onSelect={handleOnSelect}
        selected={selected}
        dateFormat={format}
        onKeyDown={handleKeyPress}
        calendarClassName={styles.datepicker}
        required={required}
        timeIntervals={timeIntervals}
        disabled={disabled}
        placeholderText={placeholder}
        className={cx(styles.input, inputClassName)}
        onChange={handleOnChange}
        onFocus={() => setStateHasFocus(true)}
        onBlur={() => setStateHasFocus(false)}
      />
    </WhiteField>
  );
};

export default WhiteDatepicker;
