export enum PaymentAddonOptionTypeEnum {
  Campaigns = 'campaigns',
  Brands = 'brands',
  Members = 'members',
  Gameplay = 'gameplay',
  Leads = 'leads',
  ImageBgRemoval = 'image_bg_removal',
  UnlimitedGameplay = 'unlimited_gameplay',
  UnlimitedLeads = 'unlimited_leads',
  UnlimitedCampaigns = 'unlimited_campaigns',
  AdditionalDays = 'additional_days'
}
