import { createAction } from '../../utils/actions';

export const setAdminGames = createAction('admin/set_games');
export const setAdminGame = createAction('admin/set_game');
export const clearAdminGames = createAction('admin/clear_games');
export const clearAdminGame = createAction('admin/clear_game');

export const setAdminCampaigns = createAction('admin/set_campaigns');
export const updateAdminCampaigns = createAction('admin/update_campaigns');
export const clearAdminCampaigns = createAction('admin/clear_campaigns');
export const updateOneCampaignAdminCampaigns = createAction('admin/update_one_in_campaigns');
export const setAdminBrands = createAction('admin/set_brands');
export const updateAdminBrands = createAction('admin/update_brands');
export const updateOneBrandAdminBrands = createAction('admin/update_one_in_brands');
export const clearAdminBrands = createAction('admin/clear_brands');
export const removeAdminBrand = createAction('admin/remove_brand');
export const setAdminAccounts = createAction('admin/set_accounts');
export const updateAdminAccounts = createAction('admin/update_accounts');
export const clearAdminAccounts = createAction('admin/clear_accounts');

export const setAdminAdminUsers = createAction('admin/set_admin_users');
export const setAdminAdminUser = createAction('admin/set_admin_user');
export const updateAdminAdminUsers = createAction('admin/update_admin_users');
export const updateAdminAdminUser = createAction('admin/update_admin_user');
export const clearAdminAdminUsers = createAction('admin/clear_admin_users');
export const clearAdminAdminUser = createAction('admin/clear_admin_user');
export const removeAdminAdminUser = createAction('admin/remove_admin_user');
export const setAdminInvitations = createAction('admin/set_admin_invitations');
export const clearAdminInvitations = createAction('admin/clear_admin_invitations');
export const removeAdminInvitation = createAction('admin/remove_admin_invitations');

export const setAdminBrand = createAction('admin/set_brand');
export const clearAdminBrand = createAction('admin/clear_brand');

export const clearAdminPlayers = createAction('admin/clear_players');
export const setAdminPlayers = createAction('admin/set_players');
export const updateAdminPlayers = createAction('admin/update_players');
export const updateAdminPlayer = createAction('admin/update_player');

export const setAdminNewsPost = createAction('admin/set_news_post');
export const setAdminNewsPosts = createAction('admin/set_news_posts');
export const removeAdminNewsPost = createAction('admin/remove_news_post');
export const updateAdminNewsPost = createAction('admin/update_news_post');
export const updateAdminNewsPosts = createAction('admin/update_news_posts');
export const clearAdminNewsPost = createAction('admin/clear_news_post');
export const clearAdminNewsPosts = createAction('admin/clear_news_posts');
export const setAdminAccount = createAction('admin/set_account');
export const removeAdminAccount = createAction('admin/delete_account');
export const setAdminAccountPayments = createAction('admin/set_account_payments');
export const setAdminAccountDesign = createAction('admin/set_account_design');
export const setAdminAccountTierSettings = createAction('admin/set_account_tier_settings');
export const addAdminAccountBrand = createAction('admin/add_account_brand');
export const updateAdminAccountBrandAction = createAction('admin/update_account_brand');
export const clearAdminAccount = createAction('admin/clear_account');

export const setAdminCampaignsTemplates = createAction('admin/set_campaign_templates');
export const clearAdminCampaignsTemplates = createAction('admin/clear_campaign_templates');
export const setAdminCampaignsTemplate = createAction('admin/set_campaign_template');
export const clearAdminCampaignsTemplate = createAction('admin/clear_campaign_template');
export const removeAdminCampaignsTemplate = createAction('admin/remove_campaign_template');
export const updateAdminCampaignsTemplates = createAction('admin/update_campaign_templates');
export const updateAdminCampaignsTemplate = createAction('admin/update_campaign_template');
export const addAdminCampaignsTemplate = createAction('admin/add_campaign_template');
export const setAdminCouponList = createAction('admin/set_admin_coupon_list');
export const setAdminCoupon = createAction('admin/set_admin_coupon');
export const clearAdminCoupon = createAction('admin/clear_admin_coupon');
export const deleteCouponAction = createAction('admin/delete_admin_coupon');
export const setAdminAccountInvoiceStatus = createAction('admin/set_admin_account_invoice_status');

export const setAdminAccountsCountries = createAction('admin/set_accounts_countries');
export const setAdminAccountsJoined = createAction('admin/set_accounts_joined');
export const setAdminAccountStatistics = createAction('admin/set_account_statistics');
export const clearCustomerSuccessLists = createAction('admin/clear_customer_success');
export const setAdminCampaignsStatistics = createAction('admin/set_campaigns_statistics');
export const updateAdminCampaignsStatistics = createAction('admin/update_campaigns_statistics');
