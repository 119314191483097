import React, { ChangeEvent, FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import styles from './ticketModal.module.css';
import useAuth from '../../domain/useAuth';
import Modal from '../../components/modal';
import { WhiteAutocomplete, WhiteButton, WhiteInput, WhiteSelect, WhiteTextarea } from '../../components/form';
import Hint from '../../components/hint/white';
import { Option } from '../../components/form/whiteAutocomplete';
import FileUploader from './hubspotFileUploader';
import WhiteField from '../../components/form/field/white';
import { cloneDeep } from 'lodash';
import useCampaignsList, { SearchCampaign } from '../../domain/campaigns/useCampaignsList';
import { TicketShowType } from '../../utils/type';
import useCurrentUser from '../../utils/hooks/useCurrentUser';

type Props = {
  show: TicketShowType | null;
  onHide: () => void;
};

type FormData = {
  title: string;
  topic: string;
  details: string;
  campaignId?: number;
  fileIds?: number[];
};

const TicketModal: FunctionComponent<Props> = ({ show, onHide }) => {
  const { createHubSpotTicket, busy } = useAuth();
  const { activeBrandId } = useCurrentUser();
  const { searchCampaigns, suggestedCampaigns } = useCampaignsList({ brandId: activeBrandId || 0 });
  const defaultData = useMemo(() => ({ title: '', details: '', topic: 'General', fileIds: [] }), []);
  const [data, setData] = useState<FormData>(defaultData);
  const [uploadBusy, setUploadBusy] = useState<boolean>(false);

  useEffect(
    () => {
      if (show) searchCampaigns('');
      setData({ title: '', details: '', topic: show?.type || 'General', fileIds: [] });
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [show]
  );

  const searchOptions: Option[] = useMemo(
    () => [
      ...suggestedCampaigns.map((item: SearchCampaign) => ({
        value: item.id,
        label: item.title
      }))
    ],
    [suggestedCampaigns]
  );

  const onChangeField = useCallback(
    (field: Partial<FormData>) => {
      setData({
        ...data,
        ...field
      });
    },
    [data]
  );

  const onClose = useCallback(() => {
    setData(defaultData);
    onHide();
  }, [defaultData, onHide]);

  const onFormSubmit = useCallback(
    (event: ChangeEvent<HTMLFormElement>) => {
      event.preventDefault();
      const dataToSend = cloneDeep(data);
      if (dataToSend.fileIds && !dataToSend.fileIds.length) {
        delete dataToSend.fileIds;
      }

      if (!dataToSend.campaignId) {
        delete dataToSend.campaignId;
      }

      createHubSpotTicket(dataToSend, onClose);
    },
    [createHubSpotTicket, data, onClose]
  );

  const issueOptions = useMemo(
    () => [
      { value: 'General', label: 'General' },
      { value: 'Technical Issue', label: 'Technical issue' },
      { value: 'Campaign Creation', label: 'Campaign creation' },
      { value: 'Integration Issue', label: 'Integrations issue' },
      { value: 'Account Settings', label: 'Account settings' },
      { value: 'Proposition', label: 'Proposition' },
      { value: 'Payment', label: 'Payment' }
    ],
    []
  );

  return (
    <Modal
      shouldCloseOnOverlayClick={false}
      className={styles.modal}
      overlayClassName={styles.modalOverlay}
      visible={Boolean(show?.show)}
      onCancel={onClose}
      title="Feel free to request any help connected to the system"
    >
      <form onSubmit={onFormSubmit}>
        <Hint className={styles.hint}>We will get your request and respond you within 24 hours</Hint>
        <WhiteInput
          labelWidth={180}
          className={styles.input}
          name="title"
          inline
          required
          maxLength={255}
          value={data.title}
          label="Title"
          onChange={(event) => onChangeField({ title: event.target.value })}
        />
        <WhiteSelect
          labelWidth={180}
          className={styles.input}
          name="topic"
          inline
          options={issueOptions}
          required
          value={data.topic || undefined}
          label="Topic"
          onChange={(topic) => onChangeField({ topic })}
        />
        <WhiteAutocomplete
          labelWidth={180}
          clearable
          className={styles.input}
          onLoad={searchCampaigns}
          options={searchOptions}
          name="campaignId"
          inline
          required
          value={data.campaignId || undefined}
          label="Is the issue connected to a specific campaign"
          onChange={(campaignId: any) => onChangeField({ campaignId })}
        />
        <WhiteTextarea
          maxLength={2048}
          className={styles.textarea}
          name="details"
          label="Request details"
          value={data.details}
          onChange={(event) => onChangeField({ details: event.target.value })}
        />
        <WhiteField name="ticket-files" label="Please add  screenshots that illustrate the details of the request">
          <FileUploader setBusy={setUploadBusy} onChange={(fileIds) => onChangeField({ fileIds })} />
        </WhiteField>
        <div className={styles.buttonsWrapper}>
          <WhiteButton type="ghost" className={styles.btn} onClick={onClose}>
            Cancel
          </WhiteButton>
          <WhiteButton
            type="yellow"
            className={styles.btn}
            htmlType="submit"
            loading={busy}
            disabled={!data.title || !data.details || uploadBusy}
          >
            Submit
          </WhiteButton>
        </div>
      </form>
    </Modal>
  );
};

export default TicketModal;
