import React, { Fragment, FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudUploadAlt, faFileAlt } from '@fortawesome/free-solid-svg-icons';
import { NativeTypes } from 'react-dnd-html5-backend';
import { DropTargetMonitor, useDrop } from 'react-dnd';
import { message, Modal, Progress } from 'antd';
import cx from 'classnames';
import { InputProps } from 'antd/lib/input';
import Typo from '../../typo';
import Button from '../button';
import useUpload from '../../../domain/useUpload';
import styles from './upload.module.css';
import ModalConfirm from '../modalConfirm';

interface Props extends InputProps {
  className?: string;
  name: string;
  value?: string;
  placeholder?: string;
  refProp?: any;
  campaignId?: number | null;
  disabled?: boolean;
  accept: string;
  onChange: (value: any) => void;
  btnType?: 'primary' | 'ghost';
  clearable?: boolean;
  size?: 'small' | 'large';
  type: 'font' | 'embed' | 'video' | 'leaderboard' | 'pdf';
  onSelectCallBack?: (file: File) => Promise<boolean>;
}

const Uploader: FunctionComponent<Props> = ({
  name,
  campaignId,
  clearable,
  type,
  size,
  btnType = 'primary',
  value,
  accept,
  disabled,
  refProp,
  onChange,
  placeholder,
  className,
  onSelectCallBack
}) => {
  const { uploadFont, url, uploadEmbed, clearUrl, uploadVideo, cancelUpload, progress, upload } = useUpload();
  const [showModal, onChangeShowModal] = useState<boolean>(false);
  const [file, setFile] = useState<File | null>(null);
  const isUploading = useMemo(() => progress > 0, [progress]);

  const onSelectFile = useCallback(
    async (file: File) => {
      if (onSelectCallBack) {
        const acceptableFile = await onSelectCallBack(file);
        if (!acceptableFile) {
          message.error('Incorrect env');
          return;
        }
      }
      setFile(file);
    },
    [setFile]
  );

  const onChangeInput = useCallback(
    (event: any) => {
      const file = event.target.files[0];
      onSelectFile(file);
    },
    [onSelectFile]
  );

  const handleFileDrop = useCallback(
    (monitor: DropTargetMonitor) => {
      if (monitor) {
        const file = monitor.getItem().files[0];
        const [tail] = file.name.split('.').reverse();
        if (accept.includes(tail.toLowerCase())) {
          onSelectFile(file);
        } else {
          message.error('File is unsupported');
        }
      }
    },
    [onSelectFile]
  );

  const onCloseModal = useCallback(() => {
    if (isUploading) {
      ModalConfirm({
        title: 'Upload in progress. Do you want cancel this operation?',
        onOk: () => {
          cancelUpload();
          onChangeShowModal(false);
          setFile(null);
        }
      });
      return;
    }
    onChangeShowModal(false);
    setFile(null);
  }, [setFile, onChangeShowModal, cancelUpload, isUploading]);

  const [{ canDrop, isOver }, drop] = useDrop({
    accept: [NativeTypes.FILE],
    drop(item, monitor) {
      handleFileDrop(monitor);
    },
    canDrop(item, monitor) {
      const files = monitor.getItem().files;
      return files?.length < 2;
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop()
    })
  });

  useEffect(() => {
    if (url) {
      onChange(url);
      if (clearable) clearUrl();
      onChangeShowModal(false);
      setFile(null);
    }
  }, [url]);

  const onUpload = useCallback(() => {
    if (file) {
      if (type === 'font') uploadFont(file);
      if (type === 'embed') uploadEmbed(file, 'common');
      if (type === 'leaderboard') uploadEmbed(file, 'leaderboard');
      if (type === 'video' && campaignId) uploadVideo(campaignId, file);
      if (type === 'pdf') upload(file, 'gamePreview');
    }
  }, [file, uploadEmbed, uploadFont, uploadVideo, campaignId]);

  const footer = useMemo(
    () => [
      <Button type="ghost" onClick={onCloseModal} name={`${name}-component-cancel`}>
        Cancel
      </Button>,
      <Button type="primary" onClick={onUpload} disabled={!file || isUploading} name={`${name}-component-upload`}>
        Upload
      </Button>
    ],
    [file, onUpload, isUploading, onCloseModal]
  );

  const btnPlaceholder = useMemo(() => placeholder || 'Select file', [placeholder]);
  const [valueName] = useMemo(() => (value ? value.split('/').reverse() : []), [value]);

  const body = useMemo(
    () =>
      file ? (
        <div className={cx(styles.dndArea, styles.filePlaceholder)}>
          <div className={styles.iconFileWrapper}>
            <FontAwesomeIcon icon={faFileAlt} />
          </div>
          <div className={styles.fileName}>{file?.name}</div>
        </div>
      ) : (
        <div className={cx(styles.dndArea, { [styles.canDrop]: canDrop || isOver })} ref={drop}>
          <div className={styles.iconWrapper}>
            <FontAwesomeIcon icon={faCloudUploadAlt} />
          </div>
          <Typo type="p">Drag file to upload</Typo>
          <Typo type="p">or</Typo>
          <Button type="primary" className={styles.inputFileBtn} name={`${name}-component-browsefiles`}>
            <span>Browse files</span>
            <input className={styles.inputFile} type="file" accept={accept} onChange={onChangeInput} />
          </Button>
        </div>
      ),
    [file, canDrop, isOver, drop, accept]
  );

  return (
    <Fragment>
      <Button
        className={cx(styles.uploaderButton, className)}
        size={size}
        disabled={disabled}
        name={`${name}-component-show`}
        type={btnType}
        onClick={() => onChangeShowModal(true)}
      >
        <div className={styles.valueText}>{valueName || btnPlaceholder}</div>
      </Button>
      <Modal visible={showModal} title="File Upload" footer={footer} onCancel={onCloseModal}>
        <div className={styles.modalWrapper}>
          {isUploading ? <Progress strokeColor="#3261EC" percent={progress} status="active" /> : body}
        </div>
      </Modal>
    </Fragment>
  );
};

export default Uploader;
