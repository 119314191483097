import React, { Fragment, FunctionComponent, useEffect, useMemo, useState } from 'react';
import Typo from '../../typo';
import styles from './upload.module.css';
import useMediaFiles from '../../../domain/mediaFiles/useMediaFiles';
import { WhiteButton, WhiteRadio } from '../index';
import { MediaFile } from '../../../utils/type';
import Table from '../../table';
import { isUndefined, orderBy } from 'lodash';
import useFileSelect from './useFileSelect';
import MultiMediaFileForm, { MultiMediaFormData } from './multiMediaFileForm';
import { MediaFileTypesEnum } from '../../../utils/enums';
import cx from 'classnames';
import cloud from '../whiteUpload/cloud.svg';
import Icon from '../../icon';
import { useSelector } from 'react-redux';
import { userSelector } from '../../../store/auth/selectors';
import { userIsAdmin } from '../../../utils/helpers/isAdmin';
import PaginationPages from '../../paginationPages';
import Empty from '../../empty';
import Modal from '../../modal';
import CategoriesModal from './categoriesModal';

type Props = {
  type: string;
  categories: string[];
  selectFile: (fileUrl: string, fileName: string) => void;
  accept: string;
  file: File | null;
  onCancel: () => void;
};

export const categoryOptions = [
  { label: 'Background', value: 'background' },
  { label: 'Trivia', value: 'trivia' },
  { label: 'Points scored', value: 'goodObject' },
  { label: 'Points/Lives lost', value: 'badObject' },
  { label: 'Countdown', value: 'gameEnding' },
  { label: 'Spin tick', value: 'spinTick' }
];

const MediaFileList: FunctionComponent<Props> = ({ type, categories, selectFile, accept, onCancel }) => {
  const [editableFile, setEditableFile] = useState<MediaFile | null>(null);
  const [addedFile, setAddedFile] = useState<File | null>(null);
  const [selectedMediaFile, setSelectedMediaFile] = useState<MediaFile | null>(null);
  const [listCategories, setListCategories] = useState<string[]>(categories);
  const [categoryEditMediaFile, setCategoryEditMediaFile] = useState<MediaFile | null>(null);
  const user = useSelector(userSelector);

  const { getMediaFilesList, clearMediaFiles, mediaFiles, deleteMediaFile, createMediaFile, busy, updateMediaFiles } =
    useMediaFiles();
  const { onChangeInput, canDrop, drop } = useFileSelect({ type, setFile: setAddedFile, accept });

  useEffect(() => {
    getMediaFilesList(1, { categories: listCategories, file_type: MediaFileTypesEnum.AUDIO, adminCreated: true }, 6);
  }, [listCategories]);

  useEffect(
    () => () => {
      clearMediaFiles();
    },
    []
  );

  const onDeleteFile = async (event: any, id: number) => {
    event.preventDefault();
    event.stopPropagation();

    const success = await deleteMediaFile([id]);

    if (success && selectedMediaFile?.id === id) {
      setSelectedMediaFile(null);
    }
  };

  const onSubmitForm = async (data: MultiMediaFormData) => {
    if (addedFile) {
      const newMediaFile: MediaFile | null = await createMediaFile(
        {
          file_name: data.file_name,
          file: addedFile,
          file_type: 'audio',
          categories
        },
        true
      );

      if (newMediaFile) {
        setAddedFile(null);
        setEditableFile(null);
      }
    }
  };

  const onCloseForm = () => {
    setEditableFile(null);
    setAddedFile(null);
  };

  const onSelectRow = (row: number | undefined) => {
    const selectedMediaFile = isUndefined(row) ? null : mediaFiles.items[row];
    setSelectedMediaFile(selectedMediaFile);
  };

  const onSelectFile = () => {
    if (!selectedMediaFile) {
      return;
    }
    selectFile(selectedMediaFile.file_key, selectedMediaFile.file_name);
  };

  const selectCategory = (value: string) => {
    if (listCategories.includes(value)) {
      setListCategories((prev) => prev.filter((item) => item !== value));
    } else {
      // setListCategories((prev) => [ ...prev, value ]);
      setListCategories([value]);
    }
  };

  const editCategory = (event: any, mediaFile: MediaFile) => {
    event.preventDefault();
    event.stopPropagation();

    setCategoryEditMediaFile(mediaFile);
  };

  const submitCategoriesEdit = async (categories: string[]) => {
    if (!categoryEditMediaFile) return;
    const success = await updateMediaFiles({ categories, mediaFileIds: [categoryEditMediaFile.id] });
    if (success) {
      setCategoryEditMediaFile(null);
    }
  };

  const data = useMemo(
    () =>
      orderBy(mediaFiles.items, 'accountId').map((mediaFile: MediaFile, idx: number) => {
        const showActions = Boolean(mediaFile.brandId || (!mediaFile.brandId && userIsAdmin(user.role)));
        const selected = selectedMediaFile?.id === mediaFile.id;
        return [
          <WhiteRadio
            className={styles.radioButton}
            name="selected-row"
            label={mediaFile.file_name}
            checked={selected}
          />,
          <div className={styles.audioWrapper}>
            <audio src={mediaFile.file_key} controls className={styles.audioPlayer} />
          </div>,
          <div className={styles.buttonsWrapper}>
            {showActions && <Icon icon="pencil" onClick={(event) => editCategory(event, mediaFile)} />}
            {showActions && <Icon icon="trash" onClick={(event) => onDeleteFile(event, mediaFile.id)} />}
            {selectedMediaFile?.id === mediaFile.id && <Icon icon="check_blue" className={styles.checkmark} />}
          </div>
        ];
      }),
    [mediaFiles.items, selectedMediaFile?.id, user.role]
  );

  if (editableFile || addedFile) {
    return (
      <MultiMediaFileForm
        mediaFile={editableFile || { file_name: addedFile?.name || '' }}
        onSubmit={onSubmitForm}
        onCancel={onCloseForm}
        busy={busy.create}
      />
    );
  }

  return (
    <Fragment>
      <div className={cx(styles.dndArea, { [styles.canDrop]: canDrop })} ref={drop}>
        <div className={styles.iconWrapper}>
          <img src={cloud} alt="cloud" draggable={false} />
        </div>
        <Typo white type="p" className={styles.uploadHint}>
          Drag file to upload
        </Typo>
        <Typo white type="p" className={styles.uploadHint}>
          or
        </Typo>
        <WhiteButton className={styles.inputFileBtn} name="browse-files">
          <span>Browse files</span>
          <input className={styles.inputFile} type="file" accept={accept} onChange={onChangeInput} />
        </WhiteButton>
      </div>
      <div className={styles.categoriesWrapper}>
        {categoryOptions.map((item) => (
          <WhiteButton
            className={styles.categoryBtn}
            type={listCategories.includes(item.value) ? 'blue' : 'ghost'}
            onClick={() => selectCategory(item.value)}
          >
            {item.label}
          </WhiteButton>
        ))}
      </div>
      <Table
        headers={[]}
        data={data}
        className={styles.table}
        rowClassName={styles.tableRow}
        onClickRow={onSelectRow}
        activeRowClassName={styles.activeRow}
      />
      {!Boolean(mediaFiles.items.length) && (
        <Empty height={200} className={styles.emptyTable} white title="No uploaded audio files" />
      )}
      <PaginationPages
        getPage={(page: number) =>
          getMediaFilesList(
            page,
            { categories: listCategories, file_type: MediaFileTypesEnum.AUDIO, adminCreated: true },
            6
          )
        }
        meta={mediaFiles.meta}
      />
      <div className={styles.buttonsWrapper}>
        <WhiteButton className={styles.btn} type="ghost" onClick={onCancel}>
          Cancel
        </WhiteButton>
        <WhiteButton className={styles.btn} disabled={!selectedMediaFile} onClick={onSelectFile}>
          Use this sound
        </WhiteButton>
      </div>
      <Modal
        visible={Boolean(categoryEditMediaFile)}
        onCancel={() => setCategoryEditMediaFile(null)}
        className={styles.xsModal}
        title="Edit category"
      >
        {Boolean(categoryEditMediaFile) && (
          <CategoriesModal
            onSubmit={submitCategoriesEdit}
            categories={categoryEditMediaFile?.categories}
            onCancel={() => setCategoryEditMediaFile(null)}
          />
        )}
      </Modal>
    </Fragment>
  );
};

export default MediaFileList;
