import React, { FunctionComponent, useCallback, useMemo, useState } from 'react';
import { debounce } from 'lodash';
import cx from 'classnames';
import close from './close.svg';
import styles from './message.module.css';
import { SystemNotificationMessage } from '../../utils/type';
import WhiteButton from '../../components/form/whiteButton';

type MessageProps = {
  item: SystemNotificationMessage;
  onClose: (id: number) => void;
};

const SystemNotificationPopup: FunctionComponent<MessageProps> = ({ item, onClose }) => {
  const [closing, onChangeClosing] = useState<boolean>(false);
  const debouncingClose = useMemo(() => debounce((id: number) => onClose(id), 1000), [onClose]);

  const handleClose = useCallback(() => {
    onChangeClosing(true);
    debouncingClose(item.id);
  }, [debouncingClose, item.id]);

  const hasButton = item.link && item.buttonText;

  return (
    <div className={cx(styles.messageModal, { [styles.closing]: closing })}>
      <div className={styles.imageWrapper}>
        <img src={item.image} className={styles.messageImage} alt="info" draggable={false} />
      </div>
      <div className={styles.messageText}>
        <div
          className={cx(styles.textWrapper, styles.popup, { [styles.hasButton]: hasButton })}
          dangerouslySetInnerHTML={{ __html: item.text }}
        />
        {hasButton && (
          <div className={styles.buttonWrapper}>
            <a href={item.link} target="_blank" rel="noreferrer" className={styles.link}>
              <WhiteButton className={styles.buttonStyles} type="yellow" onClick={handleClose}>
                Read more
              </WhiteButton>
            </a>
          </div>
        )}
      </div>
      <button onClick={handleClose} className={cx(styles.close, styles.closePopup)}>
        <img src={close} alt="close" draggable={false} />
      </button>
    </div>
  );
};

export default SystemNotificationPopup;
