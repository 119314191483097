import React, { ChangeEvent, FunctionComponent, useCallback, useEffect, useState } from 'react';
import cx from 'classnames';
import Icon from '../../icon';
import styles from './whiteColorPicker.module.css';
import { WhiteField } from '../index';
import InputType from '../../../utils/type/input.type';
import { debounce } from 'lodash';

type Props = InputType & {
  className?: string;
  label?: string;
  value?: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  onBlurCallback?: () => void;
  onBlur?: () => void;
  name: string;
  refProp?: any;
  labelWidth?: number;
  disabled?: boolean;
  tiny?: boolean;
  transparent?: boolean;
  errors?: string[];
  withoutBorder?: boolean;
  inline?: boolean;
};

const colorRegex = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/;

const WhiteColorInput: FunctionComponent<Props> = (props) => {
  const {
    className,
    label,
    refProp,
    labelWidth,
    tiny,
    errors,
    value,
    name,
    transparent,
    disabled,
    withoutBorder,
    onBlurCallback,
    onChange,
    alignLabelRight,
    onBlur,
    inline,
    changeConfigField,
    ...other
  } = props;
  const [color, onChangeColor] = useState(value);
  const [isIncorrectValue, setIsIncorrectValue] = useState<boolean>(false);
  const [dirty, setDirty] = useState<boolean>(false);

  useEffect(() => {
    if (value !== color) onChangeColor(value);
  }, [value]);

  const onChangeEvent = useCallback(
    (event: any) => {
      let value = event.target.value;

      if (value[0] !== '#' && value.length > 1) {
        value = '#' + value;
        event.target.value = value;
      }

      onChangeColor(value);

      const isCorrectColor = value.match(colorRegex);

      if (!isCorrectColor) {
        setIsIncorrectValue(true);
        return;
      }

      setIsIncorrectValue(false);
      setDirty(true);
      if (onChange) onChange(event);
    },
    [onChange]
  );

  const clear = useCallback(() => {
    onChangeColor('transparent');
    setDirty(true);
    if (onChange) onChange({ target: { value: 'transparent' } } as ChangeEvent<HTMLInputElement>);
  }, [onChange]);

  const onColorPickerChange = useCallback(
    (event) => {
      onChangeColor(event.target.value);
      setDirty(true);
      if (onChange) onChange(event);
      setIsIncorrectValue(false);
    },
    [onChange]
  );

  const debounceUpdate = debounce(() => {
    if (changeConfigField && dirty) {
      const isCorrectColor = color?.match(colorRegex);
      if (isCorrectColor || color === 'transparent') {
        changeConfigField({ [name]: color });
      }
    }
  }, 500);

  useEffect(
    () => {
      debounceUpdate();
      return () => {
        debounceUpdate.cancel();
      };
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [color]
  );

  return (
    <WhiteField
      width={labelWidth}
      className={className}
      label={label}
      name={name}
      errors={errors}
      inline={inline}
      alignLabelRight={alignLabelRight}
    >
      <label htmlFor="" className={cx(styles.inputWrapper, { [styles.transparent]: disabled })}>
        <input
          className={cx(styles.valueInput, { [styles.tiny]: inline || tiny, [styles.danger]: isIncorrectValue })}
          onChange={onChangeEvent}
          disabled={disabled}
          type="text"
          maxLength={7}
          value={color === 'transparent' ? '' : color}
          onBlur={!isIncorrectValue ? (onBlurCallback ? onBlurCallback : onBlur) : undefined}
          cypress-id={`${name}-component-input`}
        />
        <label
          className={cx(styles.color, { [styles.transparent]: color === 'transparent' })}
          style={{
            //@ts-ignore
            background: color
          }}
        >
          <input
            type="color"
            className={cx(styles.colorInput)}
            value={value === 'transparent' ? '' : value}
            disabled={disabled}
            {...other}
            name={name}
            onBlur={onBlurCallback ? onBlurCallback : onBlur}
            ref={refProp}
            cypress-id={`${name}-component-colorpicker`}
            onChange={onColorPickerChange}
          />
        </label>
        {transparent && color !== 'transparent' ? (
          <button type="button" onClick={clear} cypress-id={`${name}-component-clear`} className={styles.clearBtn}>
            <Icon icon="close_black" className={styles.clearIcon} />
          </button>
        ) : null}
      </label>
    </WhiteField>
  );
};

export default WhiteColorInput;
