import React, { FunctionComponent, useEffect, useState } from 'react';
import styles from './editModal.module.css';
import { Props } from '../index';
import useImageSize, { ImageSize } from './useImageSize';
import { cloneDeep, isUndefined } from 'lodash';
import EditModal from './editModal';
import MultiFileUpload from './multiFileUpload';
import { MediaFile } from '../../../../utils/type';

type ModalsProps = Pick<Props, 'maxWidth' | 'maxHeight' | 'type' | 'multiUpload'> & {
  show: boolean;
  files: File[];
  onCloseModal: () => void;
  submitFileUpload: (file: File, size: ImageSize, multiUpload?: boolean) => Promise<MediaFile | null>;
  isUploading: boolean;
  cancelUpload: () => void;
  progress: number;
};

const FileEditModal: FunctionComponent<ModalsProps> = (props) => {
  const { maxWidth, maxHeight, files: initFiles, cancelUpload, progress, submitFileUpload, onCloseModal } = props;

  const [files, setFiles] = useState<File[]>(initFiles);
  const [fileUrls, setFileUrls] = useState<(string | ArrayBuffer | null)[]>([]);
  const [activeFile, setActiveFile] = useState<number | undefined>(undefined);

  const { onInitializeImageSizes, sizes, setSize } = useImageSize({ maxHeight, maxWidth, files });

  const loadFiles = async (files: File[]) => {
    const promises: Promise<any>[] = [];
    files.forEach((file: File) => {
      const fileToLoad: Promise<any> = new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = async () => {
          const loadedSizes: Record<string, any> = await new Promise((resolve) => {
            const preloadImage = new Image();
            preloadImage.src = reader.result as any;
            preloadImage.onload = () => {
              resolve(onInitializeImageSizes(preloadImage));
            };
          });

          resolve({
            dataUrl: reader.result as string,
            name: file.name,
            loading: true,
            sizes: loadedSizes
          });
        };

        reader.onerror = reject;
      });
      promises.push(fileToLoad);
    });

    const loadedFiles = await Promise.all(promises);

    if (initFiles.length === 1) {
      setActiveFile(0);
    }

    return {
      fileUrls: loadedFiles.map((item) => item.dataUrl),
      sizes: loadedFiles.map((item) => item.sizes)
    };
  };

  const setFilesData = async () => {
    setFiles(initFiles);
    const fileData = await loadFiles(initFiles);

    setFileUrls(fileData.fileUrls);
    setSize(fileData.sizes);
  };

  useEffect(() => {
    setFilesData();
  }, [initFiles]);

  const deleteImage = (idx: number) => {
    const newSizes = cloneDeep(sizes);
    newSizes.splice(idx, 1);
    setSize(newSizes);

    const newFiles = cloneDeep(files);
    newFiles.splice(idx, 1);
    setFiles(newFiles);

    const newFilesUrls = cloneDeep(fileUrls);
    newFilesUrls.splice(idx, 1);
    setFileUrls(newFilesUrls);
  };

  const onUpload = (idx: number) => {
    return submitFileUpload(files[idx], sizes[idx], true);
  };

  const onSubmitFileEdit = async (file: File, fileSizes: ImageSize) => {
    if (initFiles.length === 1) {
      await submitFileUpload(file, fileSizes);
    } else {
      const newFiles = cloneDeep(files);
      newFiles[activeFile || 0] = file;
      setFiles(newFiles);
      const newFilesData = await loadFiles([file]);

      const newFileUrls = cloneDeep(fileUrls);
      newFileUrls[activeFile || 0] = newFilesData.fileUrls[0];
      setFileUrls(newFileUrls);

      const newSizes = cloneDeep(sizes);
      newSizes[activeFile || 0] = newFilesData.sizes[0];
      setSize(newSizes);

      setActiveFile(undefined);
    }

    return true;
  };

  return (
    <div className={styles.modalWrapper}>
      {isUndefined(activeFile) ? (
        <MultiFileUpload
          deleteImage={deleteImage}
          files={files}
          fileUrls={fileUrls}
          cancelUpload={cancelUpload}
          progress={progress}
          setActiveFile={setActiveFile}
          upload={onUpload}
          closeForm={onCloseModal}
        />
      ) : (
        <EditModal {...props} file={files[activeFile || 0]} submitFileUpload={onSubmitFileEdit} />
      )}
    </div>
  );
};

export default FileEditModal;
