import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import { faChevronDown, faSearch } from '@fortawesome/free-solid-svg-icons';
import Typo from '../../typo';
import Field from '../field';
import styles from './fonts.module.css';
import useFonts from '../../../domain/useFonts';
import { Font } from '../../../store/ui/types';

type Option = {
  value: string;
  label: string;
};

type Props = {
  className?: string;
  label?: string;
  name: string;
  value: string;
  disabled?: boolean;
  errors?: string[];
  onChange: (value: string) => void;
  options: {
    google: Option[];
    brand: Option[];
  };
};

type ListItem = {
  label: string;
  options: Option[];
};

const FontsAutocomplete: FunctionComponent<Props> = (props) => {
  const { className, label, errors, options, onChange, value, disabled, name } = props;
  const { googleFonts } = useFonts();
  const input = useRef<HTMLInputElement>(null);
  const [search, onChangeSearch] = useState<string>('');
  const [hide, onChangeHide] = useState<boolean>(true);
  const brandOptions = options.brand
    .map((option: Option) => ({ value: option.value, label: option.label.replace(/_custom/g, '') }))
    .filter((option: Option) => option.label.toLowerCase().includes(search));

  const googleOptions = options.google.filter((option: Option) => option.label.toLowerCase().includes(search));

  const list = [
    {
      label: 'My Fonts',
      options: brandOptions
    },
    {
      label: 'All Fonts',
      options: googleOptions
    }
  ];

  useEffect(() => {
    document.addEventListener('click', onClickOutside);
    return () => {
      document.removeEventListener('click', onClickOutside);
    };
  });

  const onClickOutside = (event: any) => {
    const inDropdown = event.target.closest(`.${styles.wrapper}`);
    if (!hide && !inDropdown) {
      onChangeSearch('');
      onChangeHide(true);
    }
  };

  const onClickLabel = () => {
    if (disabled) return;
    onChangeHide(false);
    setTimeout(() => {
      if (input.current) input.current.focus();
    }, 100);
  };

  const valueLabel = [...options.brand, ...googleFonts].find((item: Option | Font) => item.value === value);

  const onChangeValue = (value: string) => {
    onChange(value);
    onChangeSearch('');
    onChangeHide(true);
  };

  return (
    <Field className={className} label={label} errors={errors} disabled={disabled}>
      <div className={styles.wrapper}>
        {hide ? (
          <div
            onClick={onClickLabel}
            className={cx(styles.value, { [styles.disabled]: disabled })}
            cypress-id={`${name}-component-select`}
          >
            <span className={styles.valueText}>{valueLabel?.label.replace(/_custom/g, '') || 'Choose font'}</span>
            <FontAwesomeIcon className={styles.chevron} icon={faChevronDown} />
          </div>
        ) : (
          <div className={styles.dropdown}>
            <div className={styles.inputWrapper}>
              <FontAwesomeIcon className={styles.inputIcon} icon={faSearch} />
              <input
                type="text"
                value={search}
                ref={input}
                onChange={(event) => onChangeSearch(event.target.value)}
                cypress-id={`${name}-component-input`}
              />
            </div>
            <div className={styles.listWrapper}>
              {list.map((item: ListItem, index: number) => (
                <div className={styles.section} key={index}>
                  <Typo type="p" bold className={styles.label}>
                    {item.label}
                  </Typo>
                  <ul className={styles.list}>
                    {item.options.map((option: Option, idx: number) => (
                      <li
                        key={idx}
                        role="button"
                        onClick={() => onChangeValue(option.value)}
                        cypress-id={`${name}-component-option-${idx}`}
                      >
                        <Typo type="p">{option.label}</Typo>
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </Field>
  );
};

export default FontsAutocomplete;
