import { createAction } from '../../utils/actions';

export const logout = createAction('auth/logout');
export const setUser = createAction('auth/set_user');
export const updateUser = createAction('auth/update_user');
export const updateUserField = createAction('auth/update_user_field');
export const addBrand = createAction('auth/add_brand');
export const removeBrand = createAction('auth/remove_brand');
export const updateBrand = createAction('auth/update_brand');
export const updateBrandInAccount = createAction('auth/update_brand_in_account');
export const setActiveBrandId = createAction('auth/set_active_brand_id');
export const setActiveSettingsBrandId = createAction('auth/set_active_settings_brand_id');
export const setActiveSettingsBrand = createAction('auth/set_active_settings_brand');
export const setActiveBrand = createAction('auth/set_active_brand');
export const setInvitations = createAction('auth/set_invitations');
export const addInvitation = createAction('auth/add_invitation');
export const setAccount = createAction('auth/set_account');
export const updateAccount = createAction('auth/update_account');

export const setBrands = createAction('auth/set_brands');
export const setBrandEmails = createAction('auth/set_brand_emails');
export const removeOneBrandEmail = createAction('auth/remove_brand_emails');
export const updateOneBrandEmail = createAction('auth/update_one_brand_emails');
export const addBrandEmail = createAction('auth/add_brand_emails');

export const setTeamMembers = createAction('auth/set_team_members');
export const deleteInvitation = createAction('auth/delete_invitation');
export const deleteTeamMember = createAction('auth/delete_team_member');
export const updateTeamMember = createAction('auth/update_team_member');
export const updateAccountPlan = createAction('auth/update_account_plan');
export const setPaymentSubscriptionExpiresAt = createAction('auth/set_payment_subscription_expires_at');
export const updateAccountAction = createAction('auth/update_account');
export const setCampaignTemplates = createAction('auth/set_campaign_templates');
export const removeCampaignTemplate = createAction('auth/remove_campaign_templates');
export const updateCampaignsTemplate = createAction('auth/update_campaign_templates');
export const addCampaignsTemplate = createAction('auth/add_campaign_templates');
export const setBrandHasCampaignsTrue = createAction('auth/set_account_brand_has_campaigns_true');
export const setAuthField = createAction('auth_set_field');
