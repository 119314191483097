const makeSlug = (value: string) => {
  return value
    .toLowerCase()
    .replace(/ä/g, 'a')
    .replace(/ö/g, 'o')
    .replace(/õ/g, 'o')
    .replace(/ü/g, 'o')
    .replace(/[\W_]/g, '');
};

export default makeSlug;
