import I, { ImmutableObject } from 'seamless-immutable';
import { CommonStoreEnum } from './types';
import { DEFAULT_PAGINATION } from '../../utils/const';
import { CustomDomain, Game, GameType, ScreenTemplate } from '../../utils/type';
import { GameTypes } from '../../utils/enums/games/game-types.enum';

export function domainsAdapter(data: CustomDomain): ImmutableObject<CustomDomain> {
  return I({
    ...data,
    not_found_redirect: data.not_found_redirect || undefined,
    defaultCampaignId: data.defaultCampaignId || undefined
  });
}

export function screenTemplateAdapter(data: ScreenTemplate): ImmutableObject<ScreenTemplate> {
  return I({
    ...data,
    landingPageScreenElements: data.landingPageScreenElements || []
  });
}

const gameTypeAdapter = (data?: GameType): GameType => {
  return {
    id: data?.id,
    title: data?.title || '',
    image: data?.image || '',
    type: data?.type || GameTypes.TRIVIA
  };
};

export const gameAdapter = (data: Game): ImmutableObject<Game> => {
  return I({
    ...data,
    gameType: gameTypeAdapter(data.gameType),
    tags: data.tags || [],
    preview_image: data.preview_image || '',
    guide_file: data?.guide_file || ''
  });
};

export function commonStoreAdapter(): any {
  return I({
    [CommonStoreEnum.DOMAIN]: null,
    [CommonStoreEnum.BRAND_TITLES]: [],
    [CommonStoreEnum.SCREEN_TEMPLATES]: [],
    [CommonStoreEnum.COPY_ELEMENT]: null,
    [CommonStoreEnum.EMAIL_RECIPIENTS]: [],
    [CommonStoreEnum.FOLDERS]: [],
    [CommonStoreEnum.ACTIVE_FOLDER]: null,
    [CommonStoreEnum.LICENSES]: [],
    [CommonStoreEnum.SHOW_TICKET]: null,
    [CommonStoreEnum.GAMES]: [],
    [CommonStoreEnum.GAME_ANALYTICS]: null,
    [CommonStoreEnum.GAME_QUESTIONS_ANALYTICS]: [],
    [CommonStoreEnum.ACTIVE_ADVENT_DAYS]: [],
    [CommonStoreEnum.ORIGIN_ADVENT_DAY]: null,
    [CommonStoreEnum.SALESFORCE_CONNECTIONS]: [],
    [CommonStoreEnum.DEBUG_TIC_TAC_TOE_RESULT]: false,
    [CommonStoreEnum.SYSTEM_NOTIFICATIONS]: [],
    [CommonStoreEnum.SYSTEM_NOTIFICATION_PREVIEW]: null,
    [CommonStoreEnum.MEDIA_FILES]: DEFAULT_PAGINATION,
    [CommonStoreEnum.RECENT_MEDIA_FILES]: DEFAULT_PAGINATION,
    [CommonStoreEnum.NEW_MEDIA_FILES]: [],
    [CommonStoreEnum.USER_TAGS]: [],
    [CommonStoreEnum.GAME_TAGS]: []
  });
}
